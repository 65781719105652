import React from "react";
import LoadingSpinner from "../../../../components/loading_spinner/LoadingSpinner";
import ViewingKeyComp from "../../ViewingKeyComp";

const Claimable = (props: any) => {
  return (
    <div className="contract-info-wrapper">
      <h2 className="info-heading">Claimable Amount</h2>

      <div className="info-card card">
        {props.claimAmount === undefined ? (
          <LoadingSpinner style={{ margin: "10px auto" }} />
        ) : (
          <>
            {!props.sscrtViewingKey ? <ViewingKeyComp keyName="sscrt" /> : null}
            {!props.viewingKey ? (
              <ViewingKeyComp keyName="sescrt" />
            ) : !props.claimAmount ? (
              <div style={{ margin: "0 auto" }}>
                No amount available to be claimed.
              </div>
            ) : (
              <div className="claimable-amount-wrapper">
                <div className="claim-amount">
                  Amount : {props.claimAmount} {props.secretUnit}
                </div>
                <div>
                  <button
                    disabled={
                      !props.claimAmount ||
                      props.isCheckingAddress ||
                      props.loading
                    }
                    className={"stake-btn account-btn"}
                    style={{ width: "100%", margin: "0" }}
                    onClick={() => {
                      props.doClaim();
                    }}
                  >
                    {props.isCheckingAddress
                      ? "Validating address..."
                      : props.loading
                      ? "Loading..."
                      : `Claim Amount`}
                  </button>
                </div>
                <div className="secret-toggle-wrapper">
                  <div
                    onClick={() => props.toggleSecretUnit("SCRT")}
                    className={`${
                      props.secretUnit === "SCRT"
                        ? "toggle-option-active"
                        : null
                    } toggle-option option-1`}
                  >
                    SCRT
                  </div>
                  <div
                    onClick={() => props.toggleSecretUnit("sSCRT")}
                    className={`${
                      props.secretUnit === "sSCRT"
                        ? "toggle-option-active"
                        : null
                    } toggle-option option-2`}
                  >
                    sSCRT
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Claimable;
