import './stake.css';
import { useContext, useState  } from 'react';
import StakeMenu from '../../utils/stakeMenu';
import { UserContext } from '../../context/user-context';

function Exchange(){
    const [stake, setStake] = useState<boolean>(true);
    const [unstake, setUnstake] = useState<boolean>(false);
    const [inputAmount, setInputAmount] = useState<string | null>();
    const {secretUnit} = useContext(UserContext);

    const stakeMenuHandler = () => {
        setStake(true);
        setUnstake(false);
    }
    const unStakeMenuHandler = () => {
        setStake(false);
        setUnstake(true);
    }
    return(
        <div className='exchange-wrapper card'>
            <div className='stake-menu-btn-wrapper'>
                <div onClick={stakeMenuHandler} className={stake ? `stake-menu-btn active-stake-btn`:`stake-menu-btn`}>STAKE</div>
                <div onClick={unStakeMenuHandler} className={stake ? `stake-menu-btn`:`stake-menu-btn active-stake-btn`}>UNSTAKE</div>
            </div>
            {
                stake ? (
                    <StakeMenu name={'Stake ' + secretUnit} placeholder={secretUnit} inputAmount={inputAmount} setInputAmount={setInputAmount} />
                ):(
                    <StakeMenu name='Unstake seSCRT' placeholder='seSCRT' inputAmount={inputAmount} setInputAmount={setInputAmount} />
                )
            }
        </div>
    )
}

export default Exchange;