import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { useRecoilValue } from "recoil";
import { walletState } from "../../utils/walletState";
import { stakingToken } from "../../contracts/staking_token";
// import { stakingContract } from "../contracts/staking_contract";
// import { contractAddress } from "../../contracts/contactAddress";

import { SecretNetworkClient } from "secretjs";
import { API_URL, sleep, denomConst } from "../../utils/constants";
import { secretPairToken } from "../../contracts/secretPairToken";
import { sscrtToken } from "../../contracts/sscrt_token";
import { lpTokenAddress } from "../../contracts/lp_token";
import { secSwapLpToken } from "../../contracts/secSwap_lp_token";
const CoinGecko = require('coingecko-api');

export const useSecretSwap = () => {
  const { address, client, balance } = useRecoilValue(walletState);
  const [secretPairInfo, setSecretPairInfo] = useState<any>()
  const [secretAmount0, setSecretAmount0] = useState("");
  const [secretAmount1, setSecretAmount1] = useState("");
  const [secretTvl, setSecretTvl] = useState("");
  const [secretPoolRatio, setSecretPoolRatio] = useState(1);
  const [secretPriceUsd, setSecretPriceUsd] = useState(0);
  const [secretLpTokenViewingKey, setSecretLpTokenViewingKey] = useState('');

  const [seclpTokenSupplyRate, setSeclpTokenSupplyRate] = useState('');
  const [stakedseclpTokenSupplyRate, setstakedSeclpTokenSupplyRate] = useState('');
  const [secretLpBalance, setLpBalance] = useState('');
  const [secretTotalLiquidity, setSecretTotalLiquidity] = useState('');
  const [sesecretPriceUsd, setSesecretPriceUsd] = useState(0);
  const [siennaPoolOpen, setSiennaPoolOpen] = useState(0);
  const [scrtPoolOpen, setScrtPoolOpen] = useState(0);
  const [siennaLendOpen, setSiennaLendOpen] = useState(0);
  const [secretUserLiqRatio, setSecretUserLiqRatio] = useState('');
  const [secretUserSscrtLiq, setSecretUserSscrtLiq] = useState('');

  const [secretUserSescrtLiq, setSecretUserSescrtLiq] = useState('');
  const [secretUserTotalDeposit, setSecretUserTotalDeposit] = useState('');
  //const queryClient = await SecretNetworkClient(API_URL);

  useEffect(() => {
    const fetchInfo = async () => {
      await fetchSecretPrice();
      await fetchSesecretPrice();
      getSecretLiquidityInfo();
      getTotalSupply();
    }
    fetchInfo();

  }, [secretPriceUsd]);

  useEffect(() => {
    const checkLpKey = async () => {
      try {
        const lpKey = await (window as any).keplr?.getSecret20ViewingKey("secret-4", secSwapLpToken.at);
        console.log(lpKey);
        if (lpKey) {
          setSecretLpTokenViewingKey(lpKey);
        }

      } catch (error) {
        console.log(error);

      }
    }
    checkLpKey();
  }, [address])

  useEffect(() => {
    fetchSecretLpBalance();
  }, [secretLpTokenViewingKey])

  useEffect(() => {
    getSecretUserBalances();
  }, [secretLpTokenViewingKey, secretPairInfo, secretLpBalance, secretTotalLiquidity, secretUserTotalDeposit])



  const getSecretUserBalances = async () => {
    console.log("insec");

    if (!secretLpTokenViewingKey) {
      return;
    }
    console.log("in2sec");
    console.log(secretPairInfo, secretLpBalance);

    if (secretPairInfo === undefined || secretLpBalance === '') {
      fetchSecretLpBalance();
      return;
    }
    console.log(secretLpBalance, "total scrt liq" + secretTotalLiquidity);
    console.log(Number(secretLpBalance), parseFloat(secretTotalLiquidity));

    const ratio = Number(secretLpBalance) / Number(secretTotalLiquidity);
    console.log(ratio);

    setSecretUserLiqRatio(ratio.toString());
    console.log((Number(secretPairInfo.asset0_volume) / 1000000));
    console.log((ratio * (Number(secretPairInfo.asset0_volume) / 1000000)).toString());

    setSecretUserSescrtLiq((ratio * (Number(secretPairInfo.asset0_volume) / 1000000)).toString());
    setSecretUserSscrtLiq((ratio * (Number(secretPairInfo.asset1_volume) / 1000000)).toString());
    setSecretUserTotalDeposit(((ratio * (Number(secretPairInfo.asset1_volume) / 1000000)) * (secretPriceUsd) + (ratio * (Number(secretPairInfo.asset0_volume) / 1000000)) * (sesecretPriceUsd)).toFixed(5));
    console.log("Scrt total depo" + secretUserTotalDeposit);
  }


  const createSecretLpViewingKey = async () => {
    try {
      const result = await (window as any).keplr?.suggestToken("secret-4", secSwapLpToken.at);
      if (result) {
        setSecretLpTokenViewingKey(result)
      }
      console.log(result);
    } catch (error) {
      let errorMessage = "Something went wrong!";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      toast.error(`${errorMessage}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    finally {
      // setMessageLoading(false);
    }


  }

  const ViewScrtTotalDeposit = async () => {


    if (!secretLpTokenViewingKey) {
      createSecretLpViewingKey();
    }


  }

  const fetchSecretLpBalance = async () => {
    if (secretLpTokenViewingKey === "") return;
    console.log(secretLpTokenViewingKey);

    // setMessageLoading(true);
    try {
      sleep(1);
      const balanceRes: any = await client?.query.compute.queryContract({
        contract_address: secSwapLpToken.at,
        code_hash: secSwapLpToken.hash,
        query: {
          balance: {
            address: address,
            key: secretLpTokenViewingKey,
          }
        }});
      const amount = (Number(balanceRes.balance.amount) / 1000000).toString();
      console.log(Number(amount));
      setLpBalance(amount);
    } catch (err) {
      console.log(err);
    }
  }

  const getLPSupplyRate = async () => {
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
    let response: any;
    try {
      sleep(1);
      response = await queryClient.query.compute.queryContract({
        contract_address: secSwapLpToken.at, query: { token_info: {} }
      });
      console.log("lp token info");
      console.log(response.token_info.total_supply);
      const amount = (Number(response.token_info.total_supply) / 1000000).toString();
      setSeclpTokenSupplyRate(amount);

    } catch (error) {
      console.log(error);
      //handleErrorMessage(error);
      return;
    }
  }
  const getStakedLPSupplyRate = async () => {
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
    let response: any;
    try {
      sleep(1);
      response = await queryClient.query.compute.queryContract({
        contract_address: secSwapLpToken.at, query: { token_info: {} }
      });
      //response = await queryClient.queryContractSmart(secSwapLpToken.at, {token_info: {} });
      console.log("lp token info");
      console.log(response.token_info.total_supply);
      const amount = (Number(response.token_info.total_supply) / 1000000).toString();
      setstakedSeclpTokenSupplyRate(amount);
    } catch (error) {
      console.log(error);
      //handleErrorMessage(error);
      return;
    }
  }


  const getTotalSupply = async () => {
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
    try {
      const response: any = await queryClient.query.compute.queryContract({
        contract_address: secSwapLpToken.at,
        code_hash: secSwapLpToken.hash,
        query: { token_info: {} }
      });
      if (response) {
        console.log(response);
        setSecretTotalLiquidity((Number(response.token_info.total_supply) / 1000000).toString());
      }
    } catch (error) {
      console.log(error);
    }


  }

  const getSecretSwapAmount0 = async (amount: string) => {
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
    try {
      const returnAmount: any = await queryClient.query.compute.queryContract({
        contract_address: secretPairToken.at,
        code_hash: secretPairToken.hash,
        query: {
          "simulation": {
            "offer_asset": {
              "info": {
                "token": {
                  "contract_addr": sscrtToken.at,
                  "token_code_hash": "91809b72cc6a7b4a62170698630b0b0848334f0403dba1aba7aec94396af7f95",
                  "viewing_key": "" // empty
                }
              },
              "amount": `${(Number(amount) * 1000000).toFixed(0) || 0}`
            }
          }
        }
      });
      console.log(returnAmount);
      const tokenAmount = Number(returnAmount.return_amount) / 1000000;
      console.log(tokenAmount);

      setSecretAmount0(tokenAmount.toLocaleString());
    } catch (error) {
      console.log(error);

    }
  }

  const getSecretSwapAmount1 = async (amount: string) => {
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
    try {
      const returnAmount: any = await queryClient.query.compute.queryContract({
        contract_address: secretPairToken.at,
        code_hash: secretPairToken.hash,
        query: {
          "simulation": {
            "offer_asset": {
              "info": {
                "token": {
                  "contract_addr": stakingToken.at,
                  "token_code_hash": "91809b72cc6a7b4a62170698630b0b0848334f0403dba1aba7aec94396af7f95",
                  "viewing_key": "" // empty
                }
              },
              "amount": `${(Number(amount) * 1000000).toFixed(0) || 0}`
            }
          }
        }
      });
      console.log(returnAmount);
      const tokenAmount = Number(returnAmount.return_amount) / 1000000;
      setSecretAmount1(tokenAmount.toLocaleString() || '');
    } catch (error) {
      console.log(error);

    }
  }


  const getSecretLiquidityInfo = async () => {
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
    try {
      const tokenInfo: any = await queryClient.query.compute.queryContract({
        contract_address: secretPairToken.at,
        code_hash: secretPairToken.hash,
        query: {
          pair: {}
        }
      });
      console.log(tokenInfo);

      if (tokenInfo) {
        setSecretPairInfo(tokenInfo);
        let poolRatio = (Number(tokenInfo.asset0_volume) / Number(tokenInfo.asset1_volume)).toString();
        setSecretPoolRatio(Number(poolRatio));
        console.log(poolRatio);
        console.log(secretPriceUsd);
        let poolTvl = Number(tokenInfo.asset1_volume) / 1000000 * secretPriceUsd * 2;
        setSecretTvl(poolTvl.toLocaleString());
      }

    } catch (err) {
      console.log(err);

    }
  }

  const sectionControler = (name: string) => {
    if (name === "sienna") {
      setSiennaPoolOpen(1);
      setScrtPoolOpen(0);
      setSiennaLendOpen(0);
    } else if (name === "lend") {
      setSiennaPoolOpen(0);
      setScrtPoolOpen(0);
      setSiennaLendOpen(1);
    }
    else {
      setSiennaPoolOpen(0);
      setScrtPoolOpen(1);
      setSiennaLendOpen(0);
    }
  }
  const fetchSecretPrice = async () => {
    const CoinGeckoClient = new CoinGecko();
    let data = await CoinGeckoClient.simple.price({
      ids: ['secret'],
      vs_currencies: ['usd'],
    });
    if (data) {
      const price = data.data["secret"].usd;
      console.log(price);
      setSecretPriceUsd(price);
    }
  }
  const fetchSesecretPrice = async () => {
    const CoinGeckoClient = new CoinGecko();
    let data = await CoinGeckoClient.simple.price({
      ids: ['sescrt'],
      vs_currencies: ['usd'],

    });
    if (data) {
      const price = data.data["sescrt"].usd;
      console.log("sescrt price");
      console.log(price);
      setSesecretPriceUsd(price);
    }
  }

  return {
    getSecretSwapAmount0, getSecretSwapAmount1, secretAmount0,
    secretAmount1, siennaPoolOpen, scrtPoolOpen, siennaLendOpen, sectionControler,
    secretTvl, secretPriceUsd, secretPoolRatio, stakedseclpTokenSupplyRate, seclpTokenSupplyRate,
    createSecretLpViewingKey, secretLpTokenViewingKey, secretLpBalance, ViewScrtTotalDeposit,
    fetchSecretLpBalance, secretUserSscrtLiq, secretUserTotalDeposit, secretUserSescrtLiq,
    getSecretUserBalances
  }
}