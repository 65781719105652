import React,{useEffect, useState,useContext} from 'react'
import Modal from "react-modal"
import Timer from "../../utils/timer/Timer";
import LoadingSpinner from "../../components/loading_spinner/LoadingSpinner";
import { useRecoilValue } from "recoil";
import { walletState } from "../../utils/walletState";
import './MessageModal.css'
import { UserContext } from "../../context/user-context";
const MessageModal = (props:any) => {
    const [progress, setProgress] = useState<any>(0);
    const [timeLeft, setTimeLeft] = useState<Date>(new Date());
  const time = new Date();
  time.setSeconds(time.getSeconds() + 120);
  const { info, rate, activeWindow, isLoading, fetchWindow, isWindowFetching } =
    useContext(UserContext);

  useEffect(() => {
    let sec = activeWindow.time_to_close - Math.floor(Date.now() / 1000);
    console.log(sec);

    if (sec < 0) {
      sec = 0;
    }
    const time = new Date();
    time.setSeconds(time.getSeconds() + sec);
    setTimeLeft(time);
    setProgress(sec);
    console.log(timeLeft, progress);
  }, [activeWindow.time_to_close, progress]);

  const handleTimerExpire = () => {
    const oldWindowID = activeWindow.id;
    console.log("expired");
    setProgress(0);
    let reloadTime = 15000;
    const expTimer = () => {
      if (oldWindowID !== activeWindow.id) {
        fetchWindow();
      }
    };
    setInterval(() => {
      expTimer();
      reloadTime = reloadTime * 1.1;
    }, reloadTime);
  };
    
    const [isOpen, setIsOpen] = useState(false);
    const { client,address } = useRecoilValue(walletState);

    // useEffect(()=>{
    //     const status = localStorage.getItem(`disclaimer_status`);
    //     if(!status){
    //         setIsOpen(true);
    //     }
    // },[])

    // const handleInput = (event:any) =>{
    //     if(isAccepted){
    //         setIsAccepted(false);
    //     }else{
    //         setIsAccepted(true);
    //     }
        
    //     console.log(!isAccepted);   
    // }

    const handleAccept = ()=>{
        props.setMode(false);
        setIsOpen(false);
    }

  return (
    <Modal isOpen={props.isopen}
    contentLabel="My dialog"
    className="loading-modal"
    overlayClassName="myoverlay"
    closeTimeoutMS={300}>
      <div className='disclaimer-modal-wrapper'  >
          <div className='disclaimer-modal-heading'>
              <h2>LIMIT REACHED</h2>
          </div>
      <div className="disclaimer-modal-content-wrapper"  >
            <h3>Please read carefully.</h3>
            <p>
                <ul>
                    <li>The total limit for the current Window has been reached</li>
                    <li>Maximum amount that can be unstaked is {(props.maxAmt).toFixed(3)}</li>
                    
                    <li>
                        Time Remaining:
                         <Timer
                            page="stake"
                            expiryTimestamp={timeLeft}
                            totalTime={259200}
                            wrapperClassName="progress-bar-wrapper"
                            barClassName="progress-bar"
                            onExpire={handleTimerExpire}
                            inlineTag={true}
                />
                        {/* {isLoading ? (
                <LoadingSpinner />
              ) : (
                 <Timer
                            expiryTimestamp={timeLeft}
                            totalTime={259200}
                            wrapperClassName="progress-bar-wrapper"
                            barClassName="progress-bar"
                            onExpire={handleTimerExpire}
                            inlineTag={true}
                />
              )} */}
                       
                    </li>
                </ul>
            </p>
        </div>
        <div className='disclaimer-modal-inputs'>
            <div>
            </div>
            <button onClick={handleAccept}  className='stake-btn'>OK</button>
        </div>
      
    </div>
       
    </Modal>
  )
}

export default MessageModal