import React, {useContext, useEffect, useState} from 'react'
import { Encoding } from "@iov/encoding";
import { API_URL } from "../../../utils/constants";
import Timer from '../../../utils/timer/Timer'
import { ToastContainer, toast } from 'react-toastify';
import { useRecoilValue } from "recoil";
import { walletState } from "../../../utils/walletState";
import { communityFarming } from '../../../contracts/community_farming'
import { stakingToken } from '../../../contracts/staking_token'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';


import sescrt from '../../../assets/img/secrt.png'
import scrt from '../../../assets/img/scrt.png'
import './Lockdrop.css'
import { UserContext } from '../../../context/user-context';
import LoadingModal from '../../../components/LoadingModal/LoadingModal';
import { sleep } from '../../../utils/constants';
import InfoBubble from '../../../utils/information/InfoBubble';
const InputComponent = (props:any) => {
    
  const [openInput, setOpenInput] = useState<boolean>(false);
  const [lockingAmount, setLockingAmount] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [apyFactor, setApyFactor] = useState(14);
  const { address, client, balance } = useRecoilValue(walletState);
  
  const {rate, sescrtBalance, viewingKey, createViewingKey, getSescrtBalance, apy} = useContext(UserContext);

  //const queryClient = new CosmWasmClient(API_URL);

  const handleButtonClick = () =>{
    if(!openInput){
      setOpenInput(true);
    }else{
        console.log(lockingAmount);
        
      props.execute(lockingAmount);
    }
  }

  return (
    <div className= "community-input-wrapper">
        <div className='lock-sescrt-wrapper'>
            {<span className='lock-input-wrapper'>
              <div className='input-wrapper-container'>
                
              <div className="input-text-wrapper">
                    <label className="input-label receive-label" htmlFor="correspondingValueLockdrop" >
                    <img src={props.head==='Lock'?sescrt:scrt} />{props.head}</label>
              <input 
                className='stake-input receive-value' 
                id='correspondingValueLockdrop' 
                type={"number"}
                placeholder={`Enter ${props.head==='Lock'?'seSCRT':'SCRT'} amount`}
                onChange={(e)=>setLockingAmount(e.target.value)}
                value={lockingAmount}        // onChange={(e) => {handleTextInputChange(e)}} 
                />
          </div>
      <div className="input-text-wrapper">
        <label className="input-label stake-label" htmlFor="stake-scrt-input" >
          <img src={props.head==='Lock'?scrt:sescrt} />
            Equivalent Value
          </label>
      <input 
        className='stake-input actual-value' 
        id='stake-scrt-input' 
        placeholder={`${props.head==='Unlock'?'seSCRT':'SCRT'}`}
        disabled
        value={lockingAmount&&(props.head==='Lock'?(rate*Number(lockingAmount)).toLocaleString():((Number(lockingAmount))/rate).toLocaleString())}/>
      </div>
            {/* <input type={"number"} placeholder='Enter seSCRT amount' className='stake-input lock-amount-input' 
            onChange={(e)=>setLockingAmount(e.target.value)} value={lockingAmount}/><br></br>
            <input placeholder='SCRT value' className='stake-input lock-amount-value' value={lockingAmount&&(rate*Number(lockingAmount)).toLocaleString()}/> */}
            <div className='input-log-wrapper' >
              {props.showMax&&<div onClick={()=>setLockingAmount(props.maxValue)} className='sescrt-max-button'>
                Max
              </div>}
            </div>
            <div className='sescrt-balance-wrapper'>
              <div className='sescrt-balance'>
              {viewingKey?'Balance : '+sescrtBalance+' seSCRT':'seSCRT viewing key not found!'}
              </div>
                {!viewingKey&&<button onClick={createViewingKey} className='viewing-key-button'>Create seSCRT viewing key</button>}
            </div>
            </div>
            </span>}
            <div className="btn-container">
<button disabled={(lockingAmount==='' || loading) && openInput} onClick={()=>props.execute(lockingAmount)} className='unlockscrt-btn'>
                {props.buttonName}  </button>
            </div>
            
            {/* { <div className='lockdrop-footer'>
              {<FontAwesomeIcon onClick={()=>setOpenInput(false)} icon={faAngleDown} />}
            </div>} */}
          </div>
          <LoadingModal open={props.loading}>
          {props.head==='Lock'?'Locking...':'Unlocking... '} {lockingAmount} 
          {props.head==='Lock'? ` seSCRT (~${(rate*Number(lockingAmount)).toLocaleString()} SCRT)`:
          ` SCRT (~${((Number(lockingAmount))/rate).toLocaleString()} seSCRT)`}
          
        </LoadingModal>
    </div>
  )
}

export default InputComponent