import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip,
} from "chart.js";
import { useContext, useEffect } from "react";
import { Line } from "react-chartjs-2";

import { useRecoilValue } from "recoil";
import { stakingToken } from "../../contracts/staking_token";
import { walletState } from "../../utils/walletState";

import { BalanceToggleContext } from "../../context/balanceToggleContext";
import { UserContext } from "../../context/user-context";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const Statistics = () => {
	const { client, address } = useRecoilValue(walletState);
	const { viewingKey } = useContext(UserContext);

	const { balanceButtonToggle } = useContext(BalanceToggleContext);
	let toggleClass = "";

	if (
		(window.innerWidth ||
			document.documentElement.clientWidth ||
			document.body.clientWidth <= 768) &&
		balanceButtonToggle
	) {
		toggleClass = " wrapperToggle";
	} else {
		toggleClass = " ";
	}

	useEffect(() => {
		getStats();
	}, []);

	const getStats = async () => {
		try {
			const balanceRes = await client?.query.compute.queryContract({
				contract_address: stakingToken.at, query: {
					transfer_history: {
						address: address,
						key: "co0F46RmZN9eX6gwZ48e167HF5r84vE0bAcOFc9g=",
						page: 1, // page no
						page_size: 20, // number of entries
					},
				}
			});
			console.log(balanceRes);
		} catch (error) {
			console.log(error);
			return;
		}
	};
	return (
		<div className={`wrapper ${toggleClass}`}>
			<div className='contract-info-wrapper'>
				<h2 className='info-heading'>Liquid Analytics</h2>
				<div className='info-card card'>
					<Line
						data={{
							labels: [
								"January",
								"February",
								"March",
								"April",
								"May",
								"June",
								"July",
							],
							datasets: [
								{
									label: "SCRT",
									data: [
										3434.123, 3978.68, 3456.587, 3098.576, 4567.897, 2674.789,
										6789.567,
									],
									backgroundColor: "#f2545b",
								},
								{
									label: "seSCRT",
									data: [
										4434.123, 5978.68, 6456.587, 6098.576, 4567.897, 3674.789,
										4789.567,
									],
									backgroundColor: "white",
									borderColor: "grey",
								},
							],
						}}
						height={100}
						width={200}
						color='red'
					/>
				</div>
			</div>
		</div>
	);
};

export default Statistics;
