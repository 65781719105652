import { useContext } from "react";
import { BalanceToggleContext } from "../../context/balanceToggleContext";
import "./Defi.css";
import Borrow from "./DexElement/borrow/Borrow";
import SecSwapPool from "./DexElement/pools/SecSwapPool";
import SiennaPool from "./DexElement/pools/SiennaPool";

const Defi = () => {
	const { balanceButtonToggle } = useContext(BalanceToggleContext);
	let toggleClass = "";

	if (
		(window.innerWidth ||
			document.documentElement.clientWidth ||
			document.body.clientWidth <= 768) &&
		balanceButtonToggle
	) {
		toggleClass = " wrapperToggle";
	} else {
		toggleClass = " ";
	}

	return (
		<div>
			<div className={`wrapper ${toggleClass}`}>
				<div className='defi-inner-wrapper'>
					<div className='defi-header-wrapper'>
						<div className='defi-header-item defi-header-item__active'>
							Liquidity Pool
						</div>
					</div>
					<div className='defi-body-wrapper'>
						<SiennaPool />
						<SecSwapPool />
					</div>
				</div>
				<div className='defi-inner-wrapper'>
					<div className='defi-header-wrapper'>
						<div className='defi-header-item defi-header-item__active'>
							Lending
						</div>
					</div>
					<div className='defi-body-wrapper'>
						<Borrow />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Defi;
