export function sleep(seconds: number) {
  return new Promise(resolve => setTimeout(resolve, seconds*1000));
}

export const API_URL = 'https://lcd.mainnet.secretsaturn.net/';

// export const API_URL = 'https://grpc.mainnet.secretsaturn.net';

// export const API_URL = window.location.origin.toString()==='http://localhost:3000'?
// 'https://app.stakeeasy.finance/.netlify/functions/cors':
// `${window.location.origin.toString()}/.netlify/functions/cors`;

export const VALIDATOR_MAP = [{
  address:'secretvaloper1jmd0h669t0dg9hz2yesuy80ahq0wm8segghg4y',
  name:'DomeriumLabs'
},{
  address:'secretvaloper14cmhv376dth9tm3shcuextyhksu8cnfs0kq76p',
  name:'SecretAlf'
},
{
  address:'secretvaloper16p9uqwcq2gvz75y5p9zvhn7vek9ra9zfunwyf0',
  name:'Mario'
},
{
  address:'secretvaloper1hscf4cjrhzsea5an5smt4z9aezhh4sf5jjrqka',
  name:'ChainofSecrets.org'
},
{
  address:'secretvaloper1ahawe276d250zpxt0xgpfg63ymmu63a0svuvgw',
  name:'Trivium'
},
{
  address:'secretvaloper1qjk5uduu3zg356chmstcp2eqgdn35jp3ztxh4x',
  name:'SecretChainGirl Capital'
},
{
  address:'secretvaloper1hjd20hjvkx06y8p42xl0uzr3gr3ue3nkvd79jj',
  name:'secretnodes.com'
},
{
  address:'secretvaloper1nqxdqq6qhtaj9m2t2mgvzds0xa6fa2g9sk5ymu',
  name:'secretSauce 🍯'
},
{
  address:'secretvaloper1nc2justrp64paes6vrkk5atqxheqkjnl5sc76q',
  name:'NodeVine🍇'
},
{
  address:'secretvaloper1t5wtcuwjkdct9qkw2h6m48zu2hectpd6ulmekk',
  name:'Lavender.Five Nodes 🐝'
},
{
  address:'secretvaloper1vp05jj9t0u228j3ph8qav642mh84lp2q6r8vhx',
  name:'Jackal DAO'
},
{
  address:'secretvaloper1jjlfeshltdy6ngnf5tg6aeaygsvf0kxg8wck04',
  name:'Artemis.red'
},
{
  address:'secretvaloper1vzkdmu0sa8gaj686jh5all7hpmmsp8x87vyz8z',
  name:'Stakely.io'
}
]

export const denomConst = {
  tokenDenom:'SCRT',
  derTokenDenom:'seSCRT',
  secTokenDenom:'sSCRT',
  tokenDenomTxn:'uscrt',
  derTokenDenomTxn:'sescrt',
  sTokenDenomTxn:'sscrt'
}
