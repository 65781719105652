import React from 'react'
import LoadingSpinner from '../../../../components/loading_spinner/LoadingSpinner';
import Timer from '../../../../utils/timer/Timer';


const Unstaking = (props:any) => {


    const getRequiredTime=(claimTime:any)=>{
        const time = (claimTime - Math.floor(Date.now() / 1000));
        const timeAsReq = new Date();
        timeAsReq.setSeconds(timeAsReq.getSeconds() + time);
        return timeAsReq;
    }
    const getActiveWindowTime = ()=>{
        let sec = props.activeWindow.time_to_close - Math.floor(Date.now() / 1000);
        console.log(sec);
        
        if (sec<0) {
          sec=0;
        }
        return sec;
    }

  return (
    <div className="contract-info-wrapper">
          <h2 className="info-heading">Unstaking amount</h2>
          <div className="info-card card unstaking-card">
          {props.claimAmount===undefined? <LoadingSpinner style={{'margin':'10px auto'}} />:<>
          {props.pendingClaims.length===0&&!props.activeWindowAmount?<div style={{'margin':'0 auto', 'padding':'1rem 0'}}>No amount available to be unstaked.</div>:
          <>
          <div className="info-card-content info-card-content__head ">
              <div>AMOUNT</div>
              <div className="un-ttc-head">TIME LEFT</div>
          </div>
          <ol>
          
              {props.pendingClaims.map((item:any,index:number) =>{
                const timeAsReq = getRequiredTime(item.claim_time);
                // const inc = activeWindowAmount?2:1;
                return <li>
                  <div className='list-numberings'>{`(${index+1})`}</div>
                  <div className="info-card-content unstaking-content">
                  <div className='amount-with-unit'>
                    <span>{Number(item.scrt_amount)/1000000}</span> <span>{props.tokenUnit}</span>
                  </div>
                  <div>
                  <Timer expiryTimestamp={timeAsReq}
              totalTime={86400*32}
               wrapperClassName="progress-bar-wrapper"
               barClassName="progress-bar"
               onExpire={props.onTimerExpire}
               inlineTag={true}
               />
                    {/* {(item.claim_time - Math.floor(Date.now() / 1000))} seconds */}
                    </div>
                  {/* <Timer expiryTimestamp={(item.claim_time - Math.floor(Date.now() / 1000))}/> */}
                </div>
                </li>
              })}
              {props.activeWindowAmount?<li>
            <div className='list-numberings'>{`(${props.pendingClaims.length+1})`}</div>
                  <div className="info-card-content unstaking-content">
                  <div className='amount-with-unit'>
                  <span>{`${(parseFloat(props.activeWindowAmount)*parseFloat(props.rate)).toFixed(4)}`} </span>
                  <span>{props.tokenUnit}</span>
                  </div>
                  <div>
                  <Timer expiryTimestamp={new Date().setSeconds(86400*28 + getActiveWindowTime())}
              totalTime={86400*32}
               wrapperClassName="progress-bar-wrapper"
               barClassName="progress-bar"
               onExpire={props.onExpire}
               inlineTag={true}
               />
                    {/* {(item.claim_time - Math.floor(Date.now() / 1000))} seconds */}
                    </div>
                  {/* <Timer expiryTimestamp={(item.claim_time - Math.floor(Date.now() / 1000))}/> */}
                </div>
                </li>:null}
              </ol>
              </>
            }
            
          </>}
            
          </div>
          </div>
  )
}

export default Unstaking