import "./SwapInterface.css";
import "../liquidity-interface/Liquidity.css";
import { FaTimesCircle } from "react-icons/fa";
import LoadingModal from "../../../components/LoadingModal/LoadingModal";
import LoadingSpinner from "../../../components/loading_spinner/LoadingSpinner";

const SwapInterface = (props: any) => {
  return (
    <div className={`swap-container ${props.isOpen ? "active-tab" : ""}`}>
      <div
        style={{
          flexDirection:
            props.swapToken === props.token1Name ? "column" : "column-reverse",
        }}
        className="swap-input-container"
      >
        {props.children}
      </div>
      {props.fetchingAmount && (
        <div style={{ position: "relative" }}>
          <LoadingSpinner
            style={{
              bottom: "20px",
              right: "50px",
              position: "absolute",
              fontSize: "5px",
            }}
          />
        </div>
      )}
      <div className="swap-rate-info-wrapper">
        <div className="liquidity-rate-value-wrapper">
          {props.inputValue && (
            <div className="liquidity-rate">
              {props.swapToken === props.token1Name &&
                ` 1 ${props.token1Name} ≈ ${(
                  Number(props.token2InputAmount) /
                  Number(props.token1InputAmount)
                ).toLocaleString()} ${props.token2InputAmount} `}
              {props.swapToken !== props.token1Name &&
                ` 1 ${props.token2Name} ≈ ${(
                  Number(props.token1InputAmount) /
                  Number(props.token2InputAmount)
                ).toLocaleString()} ${props.token1Name} `}
            </div>
          )}
        </div>
        <div className="swap-fees-wrapper">
          <div className="swap-slippage">SLIPPAGE: 1%</div>
          <div className="swap-fees">SWAP FEES: (0.3%)</div>
        </div>
      </div>
      <div className="swap-footer">
        <button
          onClick={props.executeSwapToken}
          disabled={
            props.swapLoading ||
            !props.token1InputAmount ||
            !Number(props.token1InputAmount)
          }
          className="stake-btn swap-btn"
        >
          Swap
        </button>
        <span
          onClick={() => props.close()}
          className="close-wrap"
        >
          <FaTimesCircle cursor="pointer" size= '34' className="close-icon"/>
        </span>
      </div>
      <LoadingModal open={props.swapLoading}>{props.modalMessage}</LoadingModal>
    </div>
  );
};

export default SwapInterface;
