import React, { useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { Tooltip } from "@mui/material";
import { walletState } from "../walletState";
import { useDisconnetWallet } from "../../hooks/useConnectwallet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import "./commonButton.css";
import LoadingSpinner from "../../components/loading_spinner/LoadingSpinner";
import { UserContext } from "../../context/user-context";
import WalkthroughBubble from "../../components/walkthrough-comp/WalkthroughBubble";
import { denomConst } from "../constants";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

function CommonButton(props: Props) {
  const { address, client, balance } = useRecoilValue(walletState);

  const { isLoggingIn, currentWTPage } = useContext(UserContext);

  let buttonName = props.disabled ? props.alt_name : props.name;

  if ((window as any).keplr === undefined) {
    buttonName = "Install Keplr";
  }

  const resetUserData = useDisconnetWallet();

  const copyAddress = () => {
    navigator.clipboard.writeText(address || "");
    toast.success(`Address copied to clipboard!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div className={`common-button-wrapper`}>
      <svg
        className="commonButtonSvg"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      >
        <defs>
          <filter id="gooey">
            <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
            <feColorMatrix
              in="blur"
              type="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
              result="highContrastGraphic"
            />
            <feComposite
              in="SourceGraphic"
              in2="highContrastGraphic"
              operator="atop"
            />
          </filter>
        </defs>
      </svg>
      <div className="common-button-inner-wrapper">
        {isLoggingIn && (window as any).keplr !== undefined ? (
          <LoadingSpinner
            style={{ color: "white", left: "calc(50% - 10px)" }}
          />
        ) : (
          <WalkthroughBubble
            isOpen={currentWTPage === 1}
            content="This is your address, which is fetched from your Keplr wallet."
            placement="right"
          >
            <button
              className={`${currentWTPage === 1 ? "remove-fade-out" : ""}`}
              id="gooey-button"
              onClick={
                (window as any).keplr === undefined
                  ? () => window.open("https://wallet.keplr.app")
                  : props.onClickHandler
              }
            >
              {buttonName}
              {props.isBubble && (
                <span className="bubbles">
                  <span className="bubble"></span>
                  <span className="bubble"></span>
                  <span className="bubble"></span>
                  <span className="bubble"></span>
                  <span className="bubble"></span>
                  <span className="bubble"></span>
                  <span className="bubble"></span>
                  <span className="bubble"></span>
                  <span className="bubble"></span>
                  <span className="bubble"></span>
                </span>
              )}
            </button>
          </WalkthroughBubble>
        )}

        {address && (
          <WalkthroughBubble
            isOpen={currentWTPage === 2}
            content='To copy your address, click on this "copy" icon.'
            placement="right"
          >
            <span
              onClick={copyAddress}
              className={`address-copy-wrapper ${
                currentWTPage === 2 ? "remove-fade-out" : ""
              }`}
            >
              <FontAwesomeIcon {...props} icon={faCopy as IconProp} size="1x" />
              <div className="floating-bubble-info copy-address-bubble-info">
                Copy address!
              </div>
            </span>
          </WalkthroughBubble>
        )}

        {address && (
          <WalkthroughBubble
            isOpen={currentWTPage === 3}
            content='For disconnecting your wallet and resetting your data, click on this "Logout" button.'
            placement="right"
          >
            <div
              className={`${currentWTPage === 3 ? "remove-fade-out" : ""}`}
              style={{ position: "relative" }}
            >
              <span
                onClick={resetUserData}
                className="material-symbols-outlined logout-logo"
              >
                logout
              </span>
              <div className="floating-bubble-info logout-bubble-info">
                Logout!
              </div>
            </div>
          </WalkthroughBubble>
        )}
      </div>
      {address && (
        <WalkthroughBubble
          isOpen={currentWTPage === 4}
          content="You can locate your wallet balance here."
          placement="right"
        >
          <div
            className={`button-token-balance ${
              currentWTPage === 4 ? "remove-fade-out" : ""
            }`}
          >
            <FontAwesomeIcon rotate={"20deg"} icon={faWallet as IconProp} size="1x" />
            <div>
              {balance} {denomConst.tokenDenom}
            </div>
          </div>
        </WalkthroughBubble>
      )}
      {/* <WalkthroughBubble/> */}
    </div>
  );
}

type Props = {
  name: string;
  alt_name: string;
  onClickHandler?: any;
  disabled?: any;
  isBubble: boolean;
};

export default CommonButton;
