import "./DexElement.css";
import { withRouter, Link, useLocation } from "react-router-dom";
const DexElement = (props: any) => {
  return (
    <div className="dex-element-outer-wrapper">
      <div className="dex-element-wrapper">
        <div className="dex-pool-information-wrapper">
          <div className="dex-exchange-tokens-wrapper">
            <div className="dex-token-images-wrapper">
              <div className="dex-scrt-logo">
                <img src={props.token1Logo} />
              </div>
              <div>
                <img src={props.token2Logo} />
              </div>
              {/* <img src={seJunoLogo}/> */}
            </div>
            <div className="dex-item dex-item-tokens">
              {props.token1Name}/{props.token2Name}
            </div>
          </div>

          <div className="dex-apy-tvl-wrapper">
            <div className="dex-item dex-item-apy"></div>
            <div className="dex-item dex-item-tvl">TVL: {(!props.poolTvl|| Number(props.poolTvl)===0)?' - ':'$'+props.poolTvl}</div>
          </div>
        </div>
        <div className="dex-element-image-wrapper">
          <a target="_blank" href={props.providerlink}>
                <span className="providerlink">Live on</span>

            </a>
          <div className="dex-element-image">
            
           <a target="_blank" href={props.providerlink}>
                <img src={props.providerLogo} />
            </a>
          </div>
        </div>
        <div className="dex-element-buttons-wrapper">
          <button
            id={`swap-${props.providerName}`}
            disabled={true}
            onClick={props.handleButtonClicks}
            className="stake-btn dex-element-button"
          >
            {"coming soon here"}
          </button>
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default DexElement;