import React, { useContext } from 'react';
import LoadingSpinner from '../../components/loading_spinner/LoadingSpinner';
import { UserContext } from '../../context/user-context';
import InfoBubble from '../../utils/information/InfoBubble';
import './stake.css';


export function Info(){
    return(
        <div className='info-wrapper card'>
            <h2 className='info-heading max-yield'>
                Maximize yield by staking SCRT for seSCRT
            </h2>
        </div>
    )
}

export function Stats(){
    const {info, apy, isLoading} = useContext(UserContext);

    const tvl = parseFloat(info.total_staked) + parseFloat(info.scrt_under_withdraw) + parseFloat(info.not_redeemed);

    return(
        <div className='info-wrapper stats-wrapper card'>
            <div className='stats-child'>
            <h2 className='info-heading banner-heading'>
                TVL:
            </h2>
            <h2 className='info-heading banner-value'>
            {isLoading?<div style={{'position':'relative'}}>
                <LoadingSpinner style={{'right':'-30px'}}/>
            </div>
            :<div>{(tvl/1000000).toLocaleString() ?? `-`} SCRT</div>}
            </h2>
            </div>
            
            <div className='stats-child'>
            <h2 className='info-heading banner-heading'>
                APY:
            </h2>
            <h2 className='info-heading banner-value'>
                {isLoading?<div style={{'position':'relative'}}>
                <LoadingSpinner style={{'right':'-30px'}}/>
            </div>:<div>{apy.toLocaleString() ?? `-`} %</div>}
            </h2>
            <InfoBubble style={{
                'top':'-10px',
                'left':'5px'
            }} content="Staking annual yield in SCRT"/>
            </div>
            
        </div>
    )
}