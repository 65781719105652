import React, { useContext, useState } from "react";
import "../DexElement.css";
import siennaSwapFullLogo from "../../../../assets/img/sienna-logo.svg";
import sscrtLogo from "../../../../assets/img/scrt.png";
import seScrtLogo from "../../../../assets/img/secrt.png";
import SiennaLiquidity from "../../liquidity-interface/liquidities/SiennaLiquidity";
import SiennaSwap from "../../swap-interface/swaps/SiennaSwap";
import { SiennaSwapContext } from "../../../../context/swap-context/sienna-swap-context";
import DexElement from "../DexElement";
import { SecretSwapContext } from "../../../../context/swap-context/secret-swap-context";
const SiennaPool = () => {
  const [isSiennaLiqOpen, setIsSiennaLiqOpen] = useState(false);
  const [isSiennaSwapOpen, setIsSiennaSwapOpen] = useState(false);
  let poolTvl = "0";
  // const {poolTvl} = useContext(UserContext);
  const { siennaTvl,siennaUserTotalDeposit,getSiennaUserBalances,lpTokenSupplyRate,stakedlpTokenSupplyRate,siennaPriceUsd,sesecretPriceUsd,liqRatio,secretPriceUsd,ViewTotalDeposit} = useContext(SiennaSwapContext);
  const { sectionControler,scrtPoolOpen,siennaLendOpen } = useContext(SecretSwapContext);
  
  const handleButtonClicks = (e: any) => {
    
    if (e.target.id === "swap-sienna") {
      setIsSiennaLiqOpen(false);
      setIsSiennaSwapOpen(true);
    } else {
      setIsSiennaLiqOpen(true);
      setIsSiennaSwapOpen(false);
    }
    sectionControler("sienna");
  };

  return (
    <>
      <DexElement
        token1Logo={sscrtLogo}
        token2Logo={seScrtLogo}
        token1Name="sSCRT"
        token2Name="seSCRT"
        poolTvl={siennaTvl}
        poolApy={Number((Number(100*30*365)*(Number(sesecretPriceUsd)))/(Number(liqRatio)))}
        providerLogo={siennaSwapFullLogo}
        handleButtonClicks={handleButtonClicks}
        providerName={"sienna"}
        providerlink={"https://app.sienna.network/swap/pool"}
        isLiqOpen={scrtPoolOpen===1 || siennaLendOpen===1?false:isSiennaLiqOpen}
        isSwapOpen={scrtPoolOpen===1 || siennaLendOpen===1?false:isSiennaSwapOpen}
        buttonName1={"Pool"}
        buttonName2={"Swap"}
        totalDeposit={siennaUserTotalDeposit}
        getBalance={ViewTotalDeposit}
      >
        <SiennaLiquidity isOpen={scrtPoolOpen===1 || siennaLendOpen===1?false:isSiennaLiqOpen} close={() => setIsSiennaLiqOpen(false)} />
        <SiennaSwap isOpen={scrtPoolOpen===1 || siennaLendOpen===1?false:isSiennaSwapOpen} close={() => setIsSiennaSwapOpen(false)} />
      </DexElement>
    </>
  );
};

export default SiennaPool;
