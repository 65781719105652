import React, { useContext, useState } from "react";
import "../DexElement.css";
import siennaSwapFullLogo from "../../../../assets/img/sienna-logo.svg";
import sscrtLogo from "../../../../assets/img/scrt.png";
import seScrtLogo from "../../../../assets/img/secrt.png";
import SiennaLending from "../../lending-interface/lendings/SiennaLending";
import { SiennaSwapContext } from "../../../../context/swap-context/sienna-swap-context";
import DexBorrow from "../DexBorrow";
import { UserContext } from "../../../../context/user-context";
import { SecretSwapContext } from "../../../../context/swap-context/secret-swap-context";


const Borrow = () => {
  const { sescrtBalance, stakedslsescrtbalance,sscrtBalance,totalAmountLended,createAllViewingKeys,stakedslsupplyrate,nonstakedslsupplyrate,getLendingRate,slsescrtBalance,refreshBalances,getSlsescrtBalance,lendRatio,createViewingKey } =
    useContext(UserContext);
  const [isLiqOpen, setIsLiqOpen] = useState(false);
  const [isSwapOpen, setIsSwapOpen] = useState(false);
  let poolTvl = "0";
  // const {poolTvl} = useContext(UserContext);
   const { sectionControler,scrtPoolOpen,siennaPoolOpen } = useContext(SecretSwapContext);
  const { siennaLendingTvl,getLendRate,lendAmount,siennaPriceUsd,siennaRewardsTvl,sesecretPriceUsd } = useContext(SiennaSwapContext);
  const handleButtonClicks = (e: any) => {
     setIsLiqOpen(true);
    sectionControler("lend");
  };

  return (
    <>
      <DexBorrow
        token1Logo={sscrtLogo}
        token2Logo={seScrtLogo}
        token1Name="sSCRT"
        token2Name="seSCRT"
        lendingApy={Number((Number(100*50*365)*(Number(siennaPriceUsd)))/(Number(lendRatio)*Number(sesecretPriceUsd)))}
        lendingTvl={siennaLendingTvl}
        providerLogo={siennaSwapFullLogo}
        handleButtonClicks={handleButtonClicks}
        providerlink={"https://app.sienna.network/lend/deposit"}
        providerName={"sienna"}
        isLiqOpen={scrtPoolOpen===1 || siennaPoolOpen===1?false:isLiqOpen}
        isSwapOpen={isSwapOpen}
        totalDeposit={slsescrtBalance?(Number(lendAmount)*Number(sesecretPriceUsd)*(Number(slsescrtBalance)+Number(stakedslsescrtbalance))).toFixed(4):null}
        buttonName1={"Supply"}
        
        getBalance={createAllViewingKeys}
      >
        <SiennaLending isOpen={scrtPoolOpen===1 || siennaPoolOpen===1?false:isLiqOpen} close={() => setIsLiqOpen(false)} />
       
      </DexBorrow>
    </>
  );
};

export default Borrow;