import { createContext } from "react";

interface PendingClaims {
	claim_time: number;
	scrt_amount: string;
}

export const UserContext = createContext({
	isLoggingIn: <boolean>false,
	isMobileUser: <boolean>false,
	setIsLoggingIn: (status: boolean) => {},
	isMessageLoading: <any>false,
	validatorMin: 0,
	lendTimer: 0,
	sescrtBalance: <any>"",
	slsescrtBalance: <any>"",
	stakedslsescrtbalance: <any>"",
	lendingrate: <any>"",
	sscrtBalance: <any>"",
	pendingRewards: <any>"",
	claimAmount: <any>0,
	lendRatio: 0,

	activeWindowAmount: <any>0,
	pendingClaims: <PendingClaims[]>[],
	totalPending: <any>undefined,
	viewingKey: <any>"",
	sscrtViewingKey: <any>"",
	slsescrtViewingKey: <any>"",
	stakedslsupplyrate: <string>"",
	nonstakedslsupplyrate: <string>"",
	getSescrtBalance: () => {},
	getSlsescrtBalance: () => {},
	getSscrtBalance: () => {},
	getClaimAmount: () => {},
	createAllViewingKeys: () => {},
	getPendingClaims: () => {},
	createViewingKey: () => {},
	createSscrtViewingKey: () => {},
	createSlsescrtViewingKey: () => {},
	info: <any>{},
	apy: <any>"",
	activeWindow: <any>{},
	fetchWindow: () => {},
	isWindowFetching: <any>false,
	isLoading: <any>false,
	rate: <any>"",
	secretUnit: <any>"",
	toggleSecretUnit: (unit: string) => {},
	commFarmPhaseNumber: <Number>0,
	setcommFarmPhaseNumber: (phase: Number) => {},
	fetchFarmingInfo: () => {},
	getMyRewards: () => {},
	getMyDeposits: () => {},
	getLendingRate: () => {},
	totalAmountLended: <any>"",
	myRewards: <any>"",
	myDeposits: <any>"",
	farmingInfo: <any>"",
	phaseEndsIn: <any>"",
	phaseNumber: <any>"",
	isWalkingThrough: <Boolean>false,
	setIsWalkingThrough: (status: Boolean) => {},
	currentWTPage: <number>0,
	setCurrentWTPage: (page: number) => {},
	resetBalances: () => {},
	refreshBalances: () => {},
});
//fetchFarmingInfo, getMyRewards, getMyDeposits,
//myRewards, myDeposits, farmingInfo
