import { Dispatch, SetStateAction, useEffect, useState, useContext } from "react";
import { ToastContainer, toast } from 'react-toastify';

import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScaleBalanced } from '@fortawesome/free-solid-svg-icons';
import { stakingContract } from "../contracts/staking_contract";
import { stakingToken } from "../contracts/staking_token";
import { sscrtToken } from "../contracts/sscrt_token";
import { walletState } from "./walletState";
import {UserContext} from "../context/user-context";
import LoadingModal from "../components/LoadingModal/LoadingModal";
import MessageModal from "../components/MessageModal/MessageModal";
import scrt from "../assets/img/scrt.png";
import secrt from "../assets/img/secrt.png";
import InfoBubble from "./information/InfoBubble";


function StakeMenu(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [limitreached, setlimitreached] = useState(false);

  const {sescrtBalance, getSescrtBalance, sscrtBalance, getSscrtBalance, getClaimAmount,
        rate, secretUnit, toggleSecretUnit,validatorMin,info,activeWindow} = useContext(UserContext)
  
  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [maxunstakable, setMaxunstakable] = useState(0);
  

  const [sliderVisibility, setSliderVisibility] = useState(true);
  const [isCheckingAddress, setIsCheckingAddress] = useState(false);

  const { address, client, balance } = useRecoilValue(walletState);

  useEffect(()=>{
    setPercent(0);
    if ((props.name==='Unstake seSCRT' && (sescrtBalance==='' ||sescrtBalance==='0')) ||
     secretUnit==='sSCRT'  && (sscrtBalance==='' ||sscrtBalance==='0')) {
      setSliderVisibility(false);
    } else{
      setSliderVisibility(true);
    }
    props.setInputAmount('');
  },[props.name,sescrtBalance])
  
  
  const doTransaction = async () => {
    // if(limitreached){
    //   setIsOpen(true);
    //   return;
    // }
    
    if (props.name === 'Stake SCRT' || props.name === 'Stake sSCRT') {
      await makeDeposit();
    } else if (props.name === 'Unstake seSCRT') {
      var inputVal = (document.getElementById("stake-scrt-input") as HTMLInputElement).value;
      const bar=Number(8*validatorMin)/Number(rate);
      const current=Number(parseFloat(info.scrt_under_withdraw) / 1000000 +(parseFloat(activeWindow.sescrt_amount) / 1000000) * rate);

      if(current+Number(inputVal)>=bar){
        setMaxunstakable(bar-current);
        setIsOpen(true);
          return;
      }
                      

      await makeWithdraw();
    }
  }

  const handleErrorMessage = (error:any)=>{
    
    let errorMessage = "Something went wrong!";
      if (error instanceof Error) {
      errorMessage = error.message;
      }
      let displayMessage = errorMessage.search("insufficient");
      if(displayMessage!==-1){
        errorMessage = errorMessage.slice(displayMessage,displayMessage+17).toUpperCase();
      }
      displayMessage = errorMessage.search("whitelisted");
      if (displayMessage!==-1) {
        errorMessage = "Address is not whitelisted.";
      }
      toast.error(`${errorMessage}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
  }

  const makeDeposit = async () => {
    var inputVal = (document.getElementById("stake-scrt-input") as HTMLInputElement).value;

    setLoading(true);
    try {
      if(props.placeholder === "SCRT") {
        console.log("execute SCRT");
        await client?.tx.compute.executeContract({
          sender: address ?? "",
          code_hash: stakingContract.hash,
          contract_address: stakingContract.at,
          msg:  { 
            stake: {} 
          },
          sent_funds: [{amount: (parseFloat(inputVal)*1_000_000).toFixed(0), denom: 'uscrt'}]
        }, { gasLimit: 650_000 });
      } else {  // sSCRT
        console.log("execute sSCRT");
        
        await client?.tx.compute.executeContract({
          sender: address ?? "",
          contract_address: sscrtToken.at,
          code_hash: sscrtToken.hash,
          msg: {
            send: {
              amount: (parseFloat(inputVal)*1_000_000).toFixed(0),
              recipient: stakingContract.at,
            }
          },
        }, { gasLimit: 650_000 });
      }
      setLoading(false);
      toast.success(`${inputVal} amount of ${props.placeholder} successfully staked.`,{
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        })
      getClaimAmount();
      getSescrtBalance();
      
    } catch (error) {
      console.log(error);
      setLoading(false);
      handleErrorMessage(error);
      return;
    }
  }

  const makeWithdraw = async () => {
    var inputVal = (document.getElementById("stake-scrt-input") as HTMLInputElement).value;

    setLoading(true);
    try {
      await client?.tx.compute.executeContract({
        sender: address ?? "",
        contract_address: stakingToken.at,
        code_hash: stakingToken.hash,
        msg: {
          send: {
            amount: (parseFloat(inputVal)*1_000_000).toFixed(0),
            recipient: stakingContract.at,
          }
        },
      }, { gasLimit: 700_000 });
      setLoading(false);
      toast.success(`${inputVal} amount of seSCRT successfully initiated unstaking.`,
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      getClaimAmount();
      getSescrtBalance();
      
    } catch (error) {
      setLoading(false);
      handleErrorMessage(error);
        return;
    }
  }

  const handleTextInputChange=(e:any)=>{
    let curCrtBalance = Number(balance);
    if (props.name==='Unstake seSCRT') {
      curCrtBalance = Number(sescrtBalance);
    }
    else if(secretUnit==='sSCRT'){
      curCrtBalance = Number(sscrtBalance);
    }
    const val = e.target.value;
    props.setInputAmount(val);
    let percentFig = Math.floor((val*100)/curCrtBalance);
    if(percentFig>100) percentFig=100;
    setPercent(percentFig);
  }

  const handleSlideChange=(e:any)=>{
    let curCrtBalance = Number(balance);
    if (props.name==='Unstake seSCRT') {
      curCrtBalance = Number(sescrtBalance);
    }
    else if(secretUnit==='sSCRT'){
      curCrtBalance = Number(sscrtBalance);
    }
    let val = e.target.valueAsNumber * 0.01* Number(curCrtBalance);
        setPercent(e.target.valueAsNumber);
        props.setInputAmount(Number(val.toFixed(6)).toString())
  }

  const handleMaxClick=()=>{
    let curCrtBalance = Number(balance);
    let val = curCrtBalance; 
    if (props.name==='Unstake seSCRT'){
      props.setInputAmount(sescrtBalance);
      curCrtBalance = Number(sescrtBalance);
      val = curCrtBalance; 
    }else if(secretUnit==='SCRT'){
      const stakableAmount = Number(balance)<1?0:Number(balance)-1;
      props.setInputAmount(stakableAmount.toString());
      curCrtBalance = Number(balance);
      val = stakableAmount; 
    }else{
      props.setInputAmount(sscrtBalance);
      curCrtBalance = Number(sscrtBalance);
      val = curCrtBalance;
    }
    let percentFig = Math.floor((val*100)/curCrtBalance);
    setPercent(percentFig);
  }

  const handleNodeClick=(e:any)=>{
    let curCrtBalance = Number(balance);
    if (props.name==='Unstake seSCRT') {
      curCrtBalance = Number(sescrtBalance);
    }
    else if(secretUnit==='sSCRT'){
      curCrtBalance = Number(sscrtBalance);
    }
    const id = Number(e.target.id);
    setPercent(id);
    let val = id * 0.01* Number(curCrtBalance);
    props.setInputAmount(Number(val.toFixed(6)).toString())
  }

  return (<>
    <div className="stake-menu">
      
      <div className="stake-menu-header">
        
        <div className='stake-menu-heading stake-menu__item'>{props.name}</div>
        {/* <div className="stake-toggle-wrapper">
        {props.placeholder !== "seSCRT" && <div className="stake-toggle-switch">
          <div onClick={()=>toggleSecretUnit('SCRT')} className={`${secretUnit==='SCRT'?'toggle-option-active':null} toggle-option option-1`}>SCRT</div>
          <div onClick={()=>toggleSecretUnit('sSCRT')}  className={`${secretUnit==='sSCRT'?'toggle-option-active':null} toggle-option option-2`}>sSCRT</div>
        </div>}
        </div> */}
         {props.placeholder !== "seSCRT" && <div className="stake-toggle-switch">
          <div onClick={()=>toggleSecretUnit('SCRT')} className={`${secretUnit==='SCRT'?'toggle-option-active':null} toggle-option option-1`}>SCRT</div>
          <div onClick={()=>toggleSecretUnit('sSCRT')}  className={`${secretUnit==='sSCRT'?'toggle-option-active':null} toggle-option option-2`}>sSCRT</div>
        </div>}
        <div className='stake-rate stake-menu__item'>
          <div className="available-balance-prompt rate-prompt">
          <span>1</span> <span>{"seSCRT"}</span></div>
           = 
           <div className="available-balance-prompt rate-prompt"><span>{Number(rate).toFixed(6)}</span> <span>{"SCRT"}</span></div>
        <InfoBubble style={{'right':'-20px', 'top':'3px'}} content="Current exchange rate"/> 
        </div>
      </div>

      <div className="input-wrapper-container" >
      <div className="input-text-wrapper">
        <label className="input-label stake-label" htmlFor="stake-scrt-input" >
        <div className="label-logo-action-wrapper">
          <img src={props.placeholder==='seSCRT'?secrt:scrt} />
          {props.placeholder==='seSCRT'?'Unstake':'Stake'}
        </div>
        <div className="available-balance-prompt">
            <p>Balance : </p>
            <span>{props.name==='Unstake seSCRT'?(sescrtBalance||0):
            ((secretUnit==='SCRT'?balance:sscrtBalance) || 0)}</span>
            <span>{props.placeholder==='seSCRT'?'seSCRT':secretUnit}</span>
        </div>
        </label>
        <div className="stake-input-wrapper">
      <input 
        className='stake-input actual-value' 
        id='stake-scrt-input' 
        type='text' 
        placeholder={'0.00'}
        autoComplete="off"
        value={props.inputAmount||''}
        onChange={(e) => {handleTextInputChange(e)}} 
      />
      <div className="stake-input-unit">
        {props.placeholder==='seSCRT'?'seSCRT':props.placeholder}
        </div>
        <div className="input-warning-meassages" >
        {props.name==='Unstake' && Number(props.inputAmount)>Number(sescrtBalance)?'Insufficient Balance':null}
      {
      props.name===`Stake SCRT` ?
       props.inputAmount&&Number(props.inputAmount)<1?'At least 1 SCRT should be staked.':
       Number(props.inputAmount)>Number(balance)?<div>Insufficient Balance</div>:null:
       null}
      </div>
      </div>
       
      {balance&&sliderVisibility&&<button className='max-btn' onClick={handleMaxClick}>Max</button>}
      </div>
      {/* <div className="scale-balance-wrapper">
      <FontAwesomeIcon icon={faScaleBalanced} /> 
      </div> */}
      <div className="input-text-wrapper receive-value-wrapper">
      <label className="input-label receive-label" htmlFor="correspondingValue" >
        <div className="label-logo-action-wrapper">
          <img src={props.placeholder==='seSCRT'?scrt:secrt} />Receive
        </div>
      </label>
      <div className="stake-input-wrapper">
      <input 
        className='stake-input receive-value' 
        id='correspondingValue' 
        type='text' 
        disabled
        placeholder={'0.00'}
        value={props.inputAmount?(props.placeholder==='seSCRT'?(Number(props.inputAmount)*rate).toLocaleString():
        Number(props.inputAmount)/rate).toLocaleString():''}
        // onChange={(e) => {handleTextInputChange(e)}} 
      />
      <div className="stake-input-unit">
      {props.name===`Unstake seSCRT`?'SCRT':"seSCRT"}
      </div>
      </div>
      </div>
      </div>

      {props.name==='Unstake seSCRT'?<div style={{"marginTop":"10px"}}>Note: Unstaking takes 21 to 24 days to complete.</div>:null}

      {balance&&sliderVisibility&&<div className="input-slider-wrapper">
      <div className="slider-wrapper">
        <div id="0" className="slider-node node-0" onClick={(e)=>{handleNodeClick(e)} } style={{'background':`${percent>0?'rgb(198, 208, 221)':'grey'}`}} ></div>
        <div id="25" className="slider-node node-25" onClick={(e)=>{handleNodeClick(e)}} style={{'background':`${percent>25?'rgb(198, 208, 221)':'grey'}`}}></div>
        <div id="50" className="slider-node node-50" onClick={(e)=>{handleNodeClick(e)}} style={{'background':`${percent>50?'rgb(198, 208, 221)':'grey'}`}}></div>
        <div id="75" className="slider-node node-75" onClick={(e)=>{handleNodeClick(e)}} style={{'background':`${percent>75?'rgb(198, 208, 221)':'grey'}`}}></div>
        <div id="100" className="slider-node node-100" onClick={(e)=>{handleNodeClick(e)}} style={{'background':`${percent>100?'rgb(198, 208, 221)':'grey'}`}}></div>
        </div>
      <input name="percent" className='stake-input input-slider' value={percent} 
      type="range" min="0" max="100" step="1"
      onChange={(e) => {
        handleSlideChange(e)}}
      style={{'background':`linear-gradient(90deg,#f2545b ${percent}%, #a69cac ${percent}%)`}}
      />
      <div className="slider-wrapper">
        <output style={{'left':`${percent}%`}} 
        className="slider-bubble" 
        htmlFor="percent">{percent}%</output>
        </div>
        
      </div>}
      <button 
        disabled={ (address && props.inputAmount && !loading && !isCheckingAddress &&
           (props.name!=='Stake SCRT' || Number(props.inputAmount)>=1))? false : true }  
        className='stake-btn' 
        onClick={ () => { doTransaction(); } }
      >
        {isCheckingAddress?'Validating address...':loading ? 'Loading...' : props.name}
      </button>
      
    </div>
    <ToastContainer style={{'textAlign':'left'}}/>
    <MessageModal isopen={isOpen}
    maxAmt={maxunstakable}
    setMode={(m:boolean) => setIsOpen(m)}
    
    
    />
    <LoadingModal open={loading} >
          {props.name.split(" ")[0].slice(0,-1)+"ing"}{" "+props.inputAmount+" "}{props.name.split(" ")[1]}
          <br></br>
          {props.name==="Unstake seSCRT"?`(~ ${(Number(props.inputAmount)*rate).toLocaleString()} SCRT)`:
          `(~ ${(Number(props.inputAmount)/rate).toLocaleString()} seSCRT)`}        
    </LoadingModal>
    </>
  );
}

type Props = {
  name: string; 
  placeholder: string; 
  inputAmount: string | null | undefined; 
  setInputAmount: Dispatch<SetStateAction<string | null | undefined>>;
}

export default StakeMenu;
//{props.name==='Stake SCRT'?'Staking '+props.inputAmount+ ' '+ secretUnit:'Unsatking '+props.inputAmount+ ' seSCRT' }
