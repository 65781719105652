import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";

import scrtLogo from "../../../../assets/img/scrt.png";
import sescrtLogo from "../../../../assets/img/secrt.png";
import { SecretSwapContext } from "../../../../context/swap-context/secret-swap-context";
import { UserContext } from "../../../../context/user-context";
import { secretPairToken } from "../../../../contracts/secretPairToken";
import { secSwapLpToken } from "../../../../contracts/secSwap_lp_token";
import { siennaPairToken } from "../../../../contracts/siennaPairAddress";
import { sscrtToken } from "../../../../contracts/sscrt_token";
import { stakingToken } from "../../../../contracts/staking_token";
import { denomConst } from "../../../../utils/constants";
import { walletState } from "../../../../utils/walletState";
import DefiInput from "../../defi-input/DefiInput";
import LiquidityInterface from "../LiquidityInterface";

const SecSwapLiquidity = (props: any) => {
  const [sscrtInputAmount, setSscrtInputAmount] = useState("");
  const [sescrtInputAmount, setseScrtInputAmount] = useState("");
  const [liqLoading, setLiqLoading] = useState(false);
  const [liqLoadingMessage, setLiqLoadingMessage] = useState("");
  const [liqMode, setLiqMode] = useState("add");
  const { address, balance, client } = useRecoilValue(walletState);
  const { sescrtBalance, sscrtBalance, refreshBalances } =
    useContext(UserContext);
  const {
    secretPoolRatio,
    secretUserSescrtLiq,
    secretUserSscrtLiq,
    getSecretUserBalances,
    // siennaUserSescrtLiq,
    // siennaUserSscrtLiq,
    // getSiennaUserBalances,
    ViewScrtTotalDeposit,
  } = useContext(SecretSwapContext);

  const handleInputChange = (e: any) => {
    console.log(e.target.name);

    const value = e.target.value;
    if (e.target.name === "sscrt") {
      setSscrtInputAmount(value);
      setseScrtInputAmount(
        (Number(value) * secretPoolRatio).toLocaleString().replaceAll(",", "")
      );
    } else {
      setseScrtInputAmount(value);
      setSscrtInputAmount((Number(value) / secretPoolRatio).toString());
    }
  };

  const addLiquidity = async () => {
    setLiqLoadingMessage(
      `Adding ${sscrtInputAmount} ${denomConst.secTokenDenom} of liquidity.`
    );
    setLiqLoading(true);
    try {
      // await client?.execute(
      //   stakingToken.at,
      //   {
      //     increase_allowance: {
      //       spender: secretPairToken.at,
      //       amount: (parseFloat(sescrtInputAmount) * 1000000).toFixed(0),
      //     },
      //   },
      //   "",
      //   [],
      //   { amount: [], gas: "1000000" }
      // );
      // await client?.execute(
      //   sscrtToken.at,
      //   {
      //     increase_allowance: {
      //       spender: secretPairToken.at,
      //       amount: (parseFloat(sscrtInputAmount) * 1000000).toFixed(0),
      //     },
      //   },
      //   "",
      //   [],
      //   { amount: [], gas: "1000000" }
      // );
      // await client?.execute(
      //   secretPairToken.at,
      //   {
      //     provide_liquidity: {
      //       assets: [
      //         {
      //           info: {
      //             token: {
      //               contract_addr: sscrtToken.at,
      //               token_code_hash:
      //                 "af74387e276be8874f07bec3a87023ee49b0e7ebe08178c49d0a49c3c98ed60e",
      //               viewing_key: "",
      //             },
      //           },
      //           amount: (parseFloat(sscrtInputAmount) * 1000000).toFixed(0),
      //         },
      //         {
      //           info: {
      //             token: {
      //               contract_addr: stakingToken.at,
      //               token_code_hash:
      //                 "91809b72cc6a7b4a62170698630b0b0848334f0403dba1aba7aec94396af7f95",
      //               viewing_key: "",
      //             },
      //           },
      //           amount: (parseFloat(sescrtInputAmount) * 1000000).toFixed(0),
      //         },
      //       ],
      //     },
      //   },
      //   "",
      //   [],
      //   { amount: [], gas: "1000000" }
      // );
      toast.success(
        `${sscrtInputAmount} amount of ${denomConst.secTokenDenom} liquidity added.`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      refreshBalances();
    } catch (error) {
      let message = "Something wemt wrong!";
      if (error instanceof Error) {
        message = error.message;
      }
      console.log(error);
      toast.error(message);
    } finally {
      setLiqLoading(false);
    }
  };

  const withdrawLiquidity = async () => {
    setLiqLoadingMessage(
      `Withdrawing ${sscrtInputAmount} ${denomConst.secTokenDenom} of liquidity.`
    );
    setLiqLoading(true);
    try {
      // await client?.execute(
      //   secSwapLpToken.at,
      //   {
      //     send: {
      //       recipient: siennaPairToken.at,
      //       amount: (parseFloat(sscrtInputAmount) * 1000000).toFixed(0),
      //       msg: btoa(JSON.stringify({ withdraw_liquidity: {} })),
      //     },
      //   },
      //   "",
      //   [],
      //   { amount: [], gas: "1000000" }
      // );
      toast.success(
        `${sscrtInputAmount} amount of ${denomConst.secTokenDenom} liquidity added.`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      refreshBalances();
    } catch (error) {
      console.log(error);
      let message = "Something wemt wrong!";
      if (error instanceof Error) {
        message = error.message;
      }
      console.log(error);
      toast.error(message);
    } finally {
      setLiqLoading(false);
    }
  };

  return (
    <>
      <LiquidityInterface
        isOpen={props.isOpen}
        liqLoading={liqLoading}
        tokenInputAmount={sscrtInputAmount}
        secTokenInputAmount={sescrtInputAmount}
        tokenBalance={sscrtBalance}
        secTokenBalance={sescrtBalance}
        addLiquidity={addLiquidity}
        withdrawLiquidity={withdrawLiquidity}
        liqLoadingMessage={liqLoadingMessage}
        close={props.close}
        setMode={(m: string) => setLiqMode(m)}
      >
        <DefiInput
          mode={liqMode}
          showError={Number(sscrtInputAmount) > Number(sscrtBalance)}
          name="sscrt"
          onInputChange={handleInputChange}
          value={sscrtInputAmount}
          tokenName="sSCRT"
          tokenBalance={liqMode === "add" ? sscrtBalance : secretUserSscrtLiq}
          logoSrc={scrtLogo}
          section="upper"
          liqMode={"secswap"}
          getBalance={ViewScrtTotalDeposit}
        />
        {/* {!siennaLpTokenViewingKey && (
          <button onClick={createLpViewingKey} className="viewing-key-button">
            Create LP Key
          </button>
        )}
        {siennaLpTokenViewingKey && (
          <button onClick={fetchLpBalance} className="viewing-key-button">
            View Balance {siennaLpBalance}
          </button>
        )} */}
        <DefiInput
          mode={liqMode}
          showError={Number(sescrtInputAmount) > Number(sescrtBalance)}
          name="sescrt"
          onInputChange={handleInputChange}
          value={sescrtInputAmount}
          tokenName="seSCRT"
          section="upper"
          provider={"secret"}
          tokenBalance={liqMode === "add" ? sescrtBalance : secretUserSescrtLiq}
          logoSrc={sescrtLogo}
           getBalance={ViewScrtTotalDeposit}
        />
        {sscrtInputAmount && (
          <div className="liquidity-rate-value-wrapper">
            <div className="liquidity-rate">
              1 {denomConst.secTokenDenom} ≈ {secretPoolRatio}{" "}
              {denomConst.derTokenDenom}
              {/* ≈ ${secretPriceUsd} */}
            </div>
            {/* <div className="liquidity-value">
            $
            {sscrtInputAmount
              ? (Number(sscrtInputAmount) * secretPriceUsd * 2).toLocaleString()
              : "0.00"}
          </div> */}
          </div>
        )}
      </LiquidityInterface>
    </>
  );
};

export default SecSwapLiquidity;
