import { useContext, useEffect, useState } from "react";
// import Convert from '../../stake/convert'
import { toast } from "react-toastify";
import sscrtLogo from "../../../../assets/img/scrt.png";
import sescrtLogo from "../../../../assets/img/secrt.png";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../../utils/walletState";
import { UserContext } from "../../../../context/user-context";
import DefiInput from "../../defi-input/DefiInput";
import "../SwapInterface.css";
import "../../liquidity-interface/Liquidity.css";
import { stakingToken } from "../../../../contracts/staking_token";
import { siennaPairToken } from "../../../../contracts/siennaPairAddress";
import { sscrtToken } from "../../../../contracts/sscrt_token";
import { SiennaSwapContext } from "../../../../context/swap-context/sienna-swap-context";
import { denomConst } from "../../../../utils/constants";
import SwapInterface from "../SwapInterface";

const SiennaSwap = (props: any) => {
  const { client, address } = useRecoilValue(walletState);
  const { sescrtBalance, sscrtBalance, refreshBalances } =
    useContext(UserContext);
  const {
    getSiennaSwapAmount0,
    getSiennaSwapAmount1,
    siennaAmount0,
    siennaAmount1,
  } = useContext(SiennaSwapContext);

  const [swapToken, setSwapToken] = useState("sSCRT");
  const [sscrtInputAmount, setSscrtInputAmount] = useState("");
  const [sescrtInputAmount, setseScrtInputAmount] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [swapLoading, setSwapLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [fetchingAmount, setFetchingAmount] = useState(false);

  useEffect(() => {
    setSscrtInputAmount(siennaAmount1);
    setFetchingAmount(false);
  }, [siennaAmount1]);
  useEffect(() => {
    setseScrtInputAmount(siennaAmount0);
    setFetchingAmount(false);
  }, [siennaAmount0]);

  const handleSwitch = () => {
    if (swapToken === "sSCRT") {
      setSwapToken("seSCRT");
    } else {
      setSwapToken("sSCRT");
    }
    setseScrtInputAmount("");
    setSscrtInputAmount("");
    setInputValue("");
  };

  const handleInputChange = async (e: any) => {
    console.log(e.target.name);

    const value = e.target.value;
    setInputValue(value);
    if (swapToken === "sSCRT") {
      setSscrtInputAmount(value);
      setFetchingAmount(true);
      getSiennaSwapAmount0(value);
    } else {
      // getToken2ForToken1(value);
      getSiennaSwapAmount1(value);
      setFetchingAmount(true);
      setseScrtInputAmount(value);
      // setSscrtInputAmount(siennaAmount0);
    }
  };

  const executeSwapToken = async () => {
    try {
      setSwapLoading(true);

      if (swapToken === "sSCRT") {
        setModalMessage(
          `Swapping ${inputValue} ${denomConst.secTokenDenom} for ${sescrtInputAmount} ${denomConst.derTokenDenom}`
        );
        const transactionAmount = (parseFloat(inputValue) * 1_000_000).toFixed(
          0
        );
        // setEqAmountMessage(`(~${getReceiveValue()} ${denomConst.tokenDenom})`);
        await client?.tx.compute.executeContract({
          sender: address ?? "",
          contract_address: sscrtToken.at,
          msg: {
            send: {
              recipient: siennaPairToken.at,
              amount: transactionAmount,
              msg: btoa(
                JSON.stringify({
                  swap: {
                    to: null,
                    expected_return: (
                      Number(sescrtInputAmount) *
                      1000000 *
                      0.99
                    ).toFixed(0),
                  },
                })
              ),
            },
          },
        }, { gasLimit: 100_000 });
        // await client?.execute(
        //   sscrtToken.at,
        //   {
        //     send: {
        //       recipient: siennaPairToken.at,
        //       amount: transactionAmount,
        //       msg: btoa(
        //         JSON.stringify({
        //           swap: {
        //             to: null,
        //             expected_return: (
        //               Number(sescrtInputAmount) *
        //               1000000 *
        //               0.99
        //             ).toFixed(0),
        //           },
        //         })
        //       ),
        //     },
        //   },
        //   "",
        //   [],
        //   { amount: [], gas: "1000000" }
        // );

        toast.success(
          `${inputValue} amount of ${denomConst.secTokenDenom} successfully swapped for ${sescrtInputAmount} ${denomConst.derTokenDenom}`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        setModalMessage(
          `Swapping ${inputValue} ${denomConst.derTokenDenom} for ${sscrtInputAmount} ${denomConst.secTokenDenom}`
        );
        const transactionAmount = (parseFloat(inputValue) * 1_000_000).toFixed(
          0
        );
        await client?.tx.compute.executeContract({
          sender: address ?? "",
          contract_address: stakingToken.at,
          msg: {
            send: {
              recipient: siennaPairToken.at,
              amount: transactionAmount,
              msg: btoa(
                JSON.stringify({
                  swap: {
                    to: null,
                    expected_return: (
                      Number(sscrtInputAmount) *
                      1000000 *
                      0.99
                    ).toFixed(0),
                  },
                })
              ),
            },
          },
        }, { gasLimit: 100_000 });
        // await client?.execute(
        //   stakingToken.at,
        //   {
        //     send: {
        //       recipient: siennaPairToken.at,
        //       amount: transactionAmount,
        //       msg: btoa(
        //         JSON.stringify({
        //           swap: {
        //             to: null,
        //             expected_return: (
        //               Number(sscrtInputAmount) *
        //               1000000 *
        //               0.99
        //             ).toFixed(0),
        //           },
        //         })
        //       ),
        //     },
        //   },
        //   "",
        //   [],
        //   { amount: [], gas: "1000000" }
        // );
        toast.success(
          `${inputValue} amount of ${denomConst.secTokenDenom} successfully swapped for ${sscrtInputAmount} ${denomConst.derTokenDenom}`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      refreshBalances();

      // setSwapLoading(false);

      //min_token1:(parseFloat(getReceiveValue())*1_000_000).toFixed(0)||'0',
    } catch (error) {
      let message = "Something went wrong!";
      if (error instanceof Error) {
        message = error.message;
      }
      console.log(error);
      toast.error(message);
    } finally {
      setSwapLoading(false);
    }
  };
  // const getReceiveValue = (): any => {
  //   let value: any = "";
  //   if (!props.inputAmount) return value;
  //   if (swapToken === "sescrt") {
  //     value = (Number(token2For1Amount) * 0.99).toLocaleString();
  //   } else {
  //     value = (Number(props.inputAmount) / rate).toLocaleString();
  //   }
  //   return value;
  // };

  return (
    <SwapInterface
      isOpen={props.isOpen}
      close={props.close}
      swapToken={swapToken}
      token1Name={"sSCRT"}
      token2Name={"seSCRT"}
      token1InputAmount={sscrtInputAmount}
      token2InputAmount={sescrtInputAmount}
      token1Balance={sscrtBalance}
      token2Balance={sescrtBalance}
      swapLoading={swapLoading}
      modalMessage={modalMessage}
      fetchingAmount={fetchingAmount}
      executeSwapToken={executeSwapToken}
    >
      <DefiInput
        disabled={swapToken !== "sSCRT"}
        onInputChange={handleInputChange}
        value={inputValue ? sscrtInputAmount : ""}
        tokenName="sSCRT"
        tokenBalance={sscrtBalance}
        logoSrc={sscrtLogo}
        name="li-1"
        section="upper"
        showError={
          swapToken === "sSCRT" &&
          inputValue &&
          Number(sscrtInputAmount) > Number(sscrtBalance)
        }
      />
      <div className="swap-token-toggle">
        <span
          onClick={handleSwitch}
          className={`material-symbols-outlined switch-icon ${
            swapToken === "sSCRT" ? "switch-icon__rotate" : ""
          }`}
        >
          swap_vert
        </span>
      </div>
      <DefiInput
        disabled={swapToken !== "seSCRT"}
        onInputChange={handleInputChange}
        value={inputValue ? sescrtInputAmount : ""}
        tokenName="seSCRT"
        tokenBalance={sescrtBalance}
        logoSrc={sescrtLogo}
        section="upper"
        name="li-2"
        showError={
          swapToken === "seSCRT" &&
          inputValue &&
          Number(sescrtInputAmount) > Number(sescrtBalance)
        }
      />
    </SwapInterface>
  );
};

export default SiennaSwap;
