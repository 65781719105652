import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import "./InfoBubble.css";
const InfoBubble = (props: any) => {
  return (
    <div className="info-bubble-container">
      <div style={props.style} className="info-bubble-wrapper">
        <div className="info-icon-wrapper">
          <span className="material-symbols-outlined info-material-icon">
            info
          </span>
        </div>
        <div className="info-content-wrapper">{props.content}</div>
      </div>
    </div>
  );
};

export default InfoBubble;
