import { sleep } from "../../utils/constants";
import { useEffect, useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";

import { stakingToken } from "../../contracts/staking_token";
import { stakingContract } from "../../contracts/staking_contract";
import { walletState } from "../../utils/walletState";
import { useRecoilValue } from "recoil";

import { UserContext } from "../../context/user-context";
import "./account.css";
import LoadingModal from "../../components/LoadingModal/LoadingModal";

import axios from "axios";
import ViewingKeyComp from "./ViewingKeyComp";
import Rewards from "./sections/rewards/Rewards";
import Unstaking from "./sections/unstaking/Unstaking";
import Claimable from "./sections/claimable/Claimable";
import TransactionHistory from "./sections/transaction-history/TransactionHistory";
import { AnyARecord } from "dns";
const DUMMY_DATA = [
  {
    claim_time: 500,
    scrt_amount: "1.876",
  },
  {
    claim_time: 600,
    scrt_amount: "1.3976",
  },
  {
    claim_time: 100,
    scrt_amount: "0.3876",
  },
  {
    claim_time: 200,
    scrt_amount: "6.3876",
  },
];

function AccountInfo() {
  const {
    claimAmount,
    activeWindow,
    activeWindowAmount,
    pendingClaims,
    getPendingClaims,
    getClaimAmount,
    secretUnit,
    toggleSecretUnit,
    viewingKey,
    sescrtBalance,
    rate,
    sscrtViewingKey,
    createViewingKey,
    isMessageLoading,
  } = useContext(UserContext);

  // const [isConnected, setIsConnected] = useState(false);
  // const [isMessageLoading, setMessageLoading] = useState(false);
  // const [isQueryLoading, setQueryLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { client, address } = useRecoilValue(walletState);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [isCheckingAddress, setIsCheckingAddress] = useState(false);
  const [sescrtLoading, setSescrtLoading] = useState(false);

  useEffect(() => {
    getStats();
  }, []);

  const getStats = async () => {
    try {
      while (address === undefined) await sleep(1);
      const historyRes: any = await client?.query.compute.queryContract({contract_address: stakingToken.at, query: {
        transfer_history: {
          address: address,
          key: viewingKey,
          page: { curPage }, // page no
          page_size: 20, // number of entries
        },
      }});
      console.log(historyRes);
      if (historyRes.transfer_history) {
        setTransactionHistory(historyRes.transfer_history.txs);
      }
    } catch (error) {
      console.log(error);
      return;
    }
  };

  //   useEffect(() => {
  //     onAccountAvailable(() => {
  //         setIsConnected(true);
  //         hasViewingKey();
  //     });

  //     onViewingKeyCreated(() => {
  //         hasViewingKey();
  //     });
  // }, []);

  //   async function createViewingKey() {
  //     setMessageLoading(true);

  //     try {
  //       const result = await stakingToken.createViewingKey();
  //       if (result.isEmpty()) return;
  //       const { create_viewing_key: { key } } = result.parse();
  //       viewingKeyManager.add(stakingToken, key);
  //     } finally {
  //       setMessageLoading(false);
  //     }
  //   }

  //   function hasViewingKey() {
  //     const key = viewingKeyManager.get(stakingToken.at);
  //     return typeof key !== "undefined";
  //   }

  //   async function getBalance() {
  //       if (!hasViewingKey()) return;

  //       setQueryLoading(true);
  //       try {
  //         const { balance: { amount: result } } =
  //           await stakingToken.getBalance();
  //         const amount = coinConvert(result, 6, "human");
  //         setBalance(amount);
  //       } finally {
  //         setQueryLoading(false);
  //       }
  //   }

  const doClaim = async () => {
    setLoading(true);
    try {
      if (secretUnit === "SCRT") {
        await client?.tx.compute.executeContract(
          {
            sender: address ?? "",
            contract_address: stakingContract.at,
            code_hash: stakingContract.hash,
            msg: {
              claim: {
                secret: false,
              },
            },
            sent_funds: []
          },
          {
            gasLimit: 250_000
          }
        );
      } else {
        // sSCRT
        await client?.tx.compute.executeContract(
          {
            sender: address ?? "",
            contract_address: stakingContract.at,
            code_hash: stakingContract.hash,
            msg: {
              claim: {
                secret: true,
              },
            },
            sent_funds: []
          },
          {
            gasLimit: 250_000
          }
        );
      }
      setLoading(false);
      getPendingClaims();
      getClaimAmount();
      toast.success(`Successfully claimed ${claimAmount} ${secretUnit}.`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      setLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleErrorMessage = (error: any) => {
    let errorMessage = "Something went wrong!";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    let displayMessage = errorMessage.lastIndexOf("Raw log:");
    if (displayMessage !== -1) {
      errorMessage = errorMessage.slice(displayMessage);
    }
    displayMessage = errorMessage.search("insufficient");
    if (displayMessage !== -1) {
      errorMessage = errorMessage
        .slice(displayMessage, displayMessage + 17)
        .toUpperCase();
    }
    displayMessage = errorMessage.search("whitelisted");
    if (displayMessage !== -1) {
      errorMessage = "Address is not whitelisted.";
    }
    displayMessage = errorMessage.search("Network Error");
    if (displayMessage !== -1) {
      errorMessage = "Network Error.";
    }
    displayMessage = errorMessage.lastIndexOf("Raw log:");
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const getRequiredTime = (claimTime: any) => {
    const time = claimTime - Math.floor(Date.now() / 1000);
    const timeAsReq = new Date();
    timeAsReq.setSeconds(timeAsReq.getSeconds() + time);
    return timeAsReq;
  };

  const handlePageChange = (event: any) => {
    const id = event.target.id;
    if (id === "prev" && curPage > 1) {
      setCurPage(curPage - 1);
    } else if (id === "next" && transactionHistory.length === 20) {
      setCurPage(curPage + 1);
    }
  };

  const getActiveWindowTime = () => {
    let sec = activeWindow.time_to_close - Math.floor(Date.now() / 1000);
    console.log(sec);

    if (sec < 0) {
      sec = 0;
    }
    return sec;
  };

  const handleSescrtClick = () => {
    setSescrtLoading(true);
    createViewingKey();
  };

  return (
    <>
      {address === undefined ? (
        <div className="account-wrapper">
          <div className="contract-info-wrapper">
            <div className="info-card card">
              <div style={{ margin: "0 auto" }}>
                Connect to Keplr Wallet to see account details.
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="account-wrapper">
          <Rewards
            viewingKey={viewingKey}
            derTokenUnit={"seSCRT"}
            derTokenBalance={sescrtBalance}
            rate={rate}
            tokenUnit={"SCRT"}
          />

          <Unstaking
            activeWindow={activeWindow}
            claimAmount={claimAmount}
            pendingClaims={pendingClaims}
            activeWindowAmount={activeWindowAmount}
            tokenUnit={"SCRT"}
            onExpire={getClaimAmount}
            rate={rate}
          />

          <Claimable
            claimAmount={claimAmount}
            secretUnit={secretUnit}
            viewingKey={viewingKey}
            toggleSecretUnit={toggleSecretUnit}
            isCheckingAddress={isCheckingAddress}
            loading={loading}
            sscrtViewingKey={sscrtViewingKey}
            doClaim={doClaim}
          />

          <TransactionHistory
            transactionHistory={transactionHistory}
            viewingKey={viewingKey}
          />
        </div>
      )}
      <ToastContainer style={{ textAlign: "left" }} />
      <LoadingModal open={loading}>
        {"Claiming " + claimAmount + " " + secretUnit}
      </LoadingModal>
    </>
  );
}

export default AccountInfo;
