import React from 'react'
import secrt from "../../assets/img/secrt.png";
import { coinConvert } from "../../utils/coin";
import './txn.css'
const TransactionElement = (props:any) => {
  return (
    <div className='txn-element-wrapper'>
        <div className='txn-element-group txn-element-group-1'>
        <p className='txn-element txn-element-id'>Transaction id: <span>{props.id}</span></p>
        <div className='txn-element txn-element-addrs'>
        <div className='txn-element txn-element-sender'>From: <span>{props.sender}</span></div>
        <div className='txn-element txn-element-sender'>To: <span>{props.receiver}</span></div>
        </div>
        </div>
        <div className='txn-element-group txn-element-group-2'>
        <div className='txn-element txn-element-amount'><span >{coinConvert(props.amount, 6, "human")}</span> seSCRT </div>
        </div>
        
{/* <img src={secrt} alt="secrt" /> */}
    </div>
  )
}

export default TransactionElement