import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { useRecoilValue } from "recoil";
import { walletState } from "../../utils/walletState";
import { stakingToken } from "../../contracts/staking_token";
import { slseScrtToken } from "../../contracts/sl-seSCRT";
import { SecretNetworkClient } from "secretjs";
import { API_URL, sleep } from "../../utils/constants";
import { siennaPairToken } from "../../contracts/siennaPairAddress";
import { sscrtToken } from "../../contracts/sscrt_token";
import { lpTokenAddress } from "../../contracts/lp_token";
import { stakedlpToken } from "../../contracts/staked_lptoken";
import { useContext } from 'react';
import { UserContext } from '../../context/user-context';
const CoinGecko = require('coingecko-api');

export const useSiennaSwap = () => {
  const { resetBalances, stakedslsescrtbalance, slsescrtBalance } = useContext(UserContext);
  const { address, client, balance } = useRecoilValue(walletState);
  const [siennaPairInfo, setSiennaPairInfo] = useState<any>()
  const [siennaAmount0, setSiennaAmount0] = useState("");
  const [siennaAmount1, setSiennaAmount1] = useState("");
  const [lendAmount, setLendAmount] = useState("");
  const [siennaTvl, setSiennaTvl] = useState("");
  const [siennaLendingTvl, setSiennaLendingTvl] = useState("");
  const [siennaPoolRatio, setSiennaPoolRatio] = useState(1);
  const [secretPriceUsd, setSecretPriceUsd] = useState(0);
  const [sesecretPriceUsd, setSesecretPriceUsd] = useState(0);
  const [siennaPriceUsd, setSiennaPriceUsd] = useState(0);
  const [swapTimer, setSwapTimer] = useState(0);
  const [lpTokenSupplyRate, setlpTokenSupplyRate] = useState('');
  const [stakedlpTokenSupplyRate, setStakedlpTokenSupplyRate] = useState('');
  const [siennaLpTokenViewingKey, setSiennaLpTokenViewingKey] = useState('');
  const [siennaStakedLpTokenViewingKey, setStakedSiennaLpTokenViewingKey] = useState('');
  const [siennaLpBalance, setLpBalance] = useState('');
  const [siennaStakedLpBalance, setStakedLpBalance] = useState('');
  const [siennaTotalLiquidity, setSiennaTotalLiquidity] = useState('');
  const [siennaUserLiqRatio, setSiennaUserLiqRatio] = useState('');
  const [scrtRewardsLiq, setScrtRewardsLiq] = useState('');
  const [sescrtRewardsLiq, setSescrtRewardsLiq] = useState('');
  const [siennaUserSscrtLiq, setSiennaUserSscrtLiq] = useState('');
  const [siennaUserSescrtLiq, setSiennaUserSescrtLiq] = useState('');
  const [lpPendingRewards, setLpPendingRewards] = useState('');
  const [siennaRewardsTvl, setSiennaRewardsTvl] = useState('');
  const [liqRatio, setLiqRatio] = useState(0);

  const [siennaUserTotalDeposit, setSiennaUserTotalDeposit] = useState('');
  const [siennaPoolTvl, setSiennaPoolTvl] = useState(0);
  //const queryClient = new SecretNetworkClient(API_URL);
  //const queryClient = await SecretNetworkClient.create({grpcWebUrl: API_URL, chainId: "secret-4"});


  useEffect(() => {
    const fetchInfo = async () => {
      await fetchSesecretPrice();
      await fetchSecretPrice();
      await fetchSiennaPrice();
      // await fetchStakedLpBalance();

      getSiennaLendingInfo();
      getPendingRewards();
      getLendRate();
      getStakedLPSupplyRate();
      getSiennaLiquidityInfo();
      // getSecretLiquidityInfo();
    }
    fetchInfo();

  }, [secretPriceUsd, sesecretPriceUsd, slsescrtBalance, siennaPoolTvl]);

  useEffect(() => {
    const fetchInfo = async () => {
      getSiennaUserBalances();

    }
    fetchInfo();

  }, [secretPriceUsd, sesecretPriceUsd, slsescrtBalance, siennaPoolTvl, siennaLpTokenViewingKey, siennaStakedLpTokenViewingKey, siennaPairInfo, siennaStakedLpBalance, siennaLpBalance]);

  useEffect(() => {
    const checkLpKey = async () => {
      try {
        const lpKey = await (window as any).keplr?.getSecret20ViewingKey("secret-4", lpTokenAddress.at);
        console.log(lpKey);
        if (lpKey) {
          setSiennaLpTokenViewingKey(lpKey);
        }

      } catch (error) {
        console.log(error);

      }
    }
    checkLpKey();
    const checkStakedLpKey = async () => {
      try {
        const lpKey = await (window as any).keplr?.getSecret20ViewingKey("secret-4", stakedlpToken.at);
        console.log(lpKey);
        if (lpKey) {
          setStakedSiennaLpTokenViewingKey(lpKey);
        }

      } catch (error) {
        console.log(error);

      }
    }
    checkStakedLpKey();
  }, [address])

  useEffect(() => {
    fetchLpBalance();
    fetchStakedLpBalance();
  }, [siennaLpTokenViewingKey, siennaStakedLpTokenViewingKey])

  //   const getSecretLiquidityInfo = async()=>{
  //     const queryClient = new SecretNetworkClient(API_URL);
  //     try {
  //         const tokenInfo = await queryClient?.queryContractSmart(
  //             secretPairToken.at,

  //           'pair_info' as unknown as object

  //         );
  //         console.log(tokenInfo);

  //         // if(tokenInfo){
  //         //   setSecretPairInfo(tokenInfo.pair_info);
  //         //   let poolRatio = (Number(tokenInfo.pair_info.amount_0)/Number(tokenInfo.pair_info.amount_1)).toString();
  //         //   setSecretPoolRatio(Number(poolRatio));
  //         //   console.log(poolRatio);
  //         //   console.log(secretPriceUsd);
  //         //   let poolTvl = Number(tokenInfo.pair_info.amount_1)/1000000 * secretPriceUsd*2;
  //         //   setSecretTvl(poolTvl.toLocaleString());
  //         //   setSecretTotalLiquidity((Number(tokenInfo.pair_info.total_liquidity)/1000000).toString());
  //         // }

  // }catch(err){
  //     console.log(err);

  // }
  // }
  const getLendRate = async () => {
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
    //const queryClient = new SecretNetworkClient(API_URL);
    let response: any;
    try {
      sleep(1);
      //response = await queryClient.queryContractSmart(slseScrtToken.at, { exchange_rate: {} });
      response = await queryClient.query.compute.queryContract({
        contract_address: slseScrtToken.at, query: { exchange_rate: {} }
      });
      console.log("attention here");
      console.log(response);
      let exch_rate = response;
      console.log("exchange rate", exch_rate);
      console.log("sl" + slsescrtBalance);
      console.log("sesecusd" + sesecretPriceUsd);
      // setLendAmount(((Number(exch_rate))*(Number(sesecretPriceUsd))).toFixed(10));
      setLendAmount(exch_rate);
      console.log("lended amount" + lendAmount);
    } catch (error) {

      console.log(error);
      return;
    }
  }

  const createLpViewingKey = async () => {
    try {
      const result = await (window as any).keplr?.suggestToken("secret-4", lpTokenAddress.at);
      if (result) {
        setSiennaLpTokenViewingKey(result)
      }
      console.log(result);
    } catch (error) {
      let errorMessage = "Something went wrong!";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      toast.error(`${errorMessage}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    finally {
      // setMessageLoading(false);
    }


  }
  const createStakedLpViewingKey = async () => {
    try {
      const result = await (window as any).keplr?.suggestToken("secret-4", lpTokenAddress.at);
      if (result) {
        setStakedSiennaLpTokenViewingKey(result)
      }
      console.log(result);
    } catch (error) {
      let errorMessage = "Something went wrong!";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      toast.error(`${errorMessage}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    finally {
      // setMessageLoading(false);
    }


  }

  const getLPSupplyRate = async () => {
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });

    //const queryClient = new SecretNetworkClient(API_URL);
    let response: any;
    try {
      sleep(1);
      //response = await queryClient.queryContractSmart(lpTokenAddress.at, { token_info: {} });
      response = await queryClient.query.compute.queryContract({
        contract_address: lpTokenAddress.at, query: { token_info: {} }
      });
      console.log("lp token info");
      console.log(response.token_info.total_supply);
      const amount = (Number(response.token_info.total_supply) / 1000000).toString();
      setlpTokenSupplyRate(amount);

    } catch (error) {
      console.log(error);
      //handleErrorMessage(error);
      return;
    }


  }
  const getStakedLPSupplyRate = async () => {
    //const queryClient = new SecretNetworkClient(API_URL);
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });

    let response;
    try {
      sleep(1);
      const time = Math.floor(+new Date() / 1000);
      // const balanceRes = await client?.queryContractSmart(
      //   stakedlpToken.at,
      //   {
      //     rewards: {
      //       pool_info: {
      //         at: time
      //       }
      //     }
      //   }
      // );
      const balanceRes: any = {};
      //  const amount = (Number(balanceRes.rewards.pool_info.staked)/1000000).toString();
      //   setStakedlpTokenSupplyRate(amount);
      //const unstakedBalanceRes = await queryClient.queryContractSmart(lpTokenAddress.at, { token_info: {} });
      const unstakedBalanceRes: any = await queryClient.query.compute.queryContract({
        contract_address: lpTokenAddress.at, query: { token_info: {} }
      });

      //  const tokenInfo = await queryClient.queryContractSmart(
      //     siennaPairToken.at,

      //     'pair_info' as unknown as object

      //   );


      // let poolTvl = Number(tokenInfo.pair_info.amount_1)/1000000;
      const numValue = Number(balanceRes.rewards.pool_info.staked) / 1000000;
      const denomValue = Number(unstakedBalanceRes.token_info.total_supply) / 1000000;
      console.log("executed")
      const Ratio = (numValue / denomValue);
      console.log("numerrator:" + numValue);
      console.log("Denominator:" + denomValue);

      console.log("shobhit ratio:" + (numValue / denomValue));

      setLiqRatio(Number(Ratio) * Number(siennaPoolTvl));
      console.log("denomn " + Number(Ratio) * Number(siennaPoolTvl));

    } catch (error) {
      console.log(error);
      console.log(" not working");
      //handleErrorMessage(error);
      return;
    }


  }

  const fetchLpBalance = async () => {
    if (siennaLpTokenViewingKey === "") return;
    console.log(siennaLpTokenViewingKey);

    // setMessageLoading(true);
    try {
      sleep(1);
      // const balanceRes = await client?.queryContractSmart(
      //   lpTokenAddress.at,
      //   {
      //     balance: {
      //       address: address,
      //       key: siennaLpTokenViewingKey,
      //     }
      //   }
      // );
      const balanceRes: any = {};

      const amount = (Number(balanceRes.balance.amount) / 1000000).toString();
      console.log(Number(amount));
      setLpBalance(amount);
      // setMessageLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  const getPendingRewards = async () => {
    while (address === undefined) await sleep(1);
    try {
      sleep(1);
      const time = Math.floor(+new Date() / 1000);
      // const response = await queryClient.queryContractSmart(
      //   stakedlpToken.at,
      //   {
      //     rewards: {
      //       user_info: {
      //         address: address,
      //         key: siennaStakedLpTokenViewingKey,
      //         at: time
      //       }
      //     }
      //   }
      // );
      const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
      const response: any = await queryClient.query.compute.queryContract({
        contract_address: stakedlpToken.at, query: {
          rewards: {
            user_info: {
              address: address,
              key: siennaStakedLpTokenViewingKey,
              at: time
            }
          }
        }
      });
      console.log("pending rewards");
      console.log(response);
      console.log(response.rewards.user_info.earned);
      console.log("Liquid timer");
      console.log(Number(response.rewards.user_info.bonding));
      setSwapTimer(Number(response.rewards.user_info.bonding));
      setLpPendingRewards((Number(response.rewards.user_info.earned) / 1000000).toString());
      // setPendingRewards(undelegations);
    } catch (error) {
      return;
    }

  }
  const fetchStakedLpBalance = async () => {
    if (siennaStakedLpTokenViewingKey === "") return;
    console.log(siennaStakedLpTokenViewingKey);

    // setMessageLoading(true);
    try {
      sleep(1);
      // const balanceRes = await client?.queryContractSmart(
      //   stakedlpToken.at,
      //   {
      //     balance: {
      //       address: address,
      //       key: siennaStakedLpTokenViewingKey,
      //     }
      //   }
      // );
      const balanceRes: any = {};

      const amount = (Number(balanceRes.balance.amount) / 1000000).toString();
      console.log(Number(amount));
      setStakedLpBalance(amount);
      // setMessageLoading(false);
    } catch (err) {
      console.log(err);
    }
  }



  const getSiennaSwapAmount0 = async (amount: string) => {
    //const queryClient = new SecretNetworkClient(API_URL);
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
    try {
      // const returnAmount = await queryClient.queryContractSmart(
      //   siennaPairToken.at,
      //   {
      //     "swap_simulation": {
      //       "offer": {
      //         "amount": `${(Number(amount) * 1000000).toFixed(0) || 0}`,
      //         "token": {
      //           "custom_token": {
      //             "contract_addr": sscrtToken.at,
      //             "token_code_hash": "91809b72cc6a7b4a62170698630b0b0848334f0403dba1aba7aec94396af7f95"
      //           }
      //         }
      //       }
      //     }
      //   }
      // )
      const returnAmount: any = await queryClient.query.compute.queryContract({
        contract_address: siennaPairToken.at, query: {
          "swap_simulation": {
            "offer": {
              "amount": `${(Number(amount) * 1000000).toFixed(0) || 0}`,
              "token": {
                "custom_token": {
                  "contract_addr": sscrtToken.at,
                  "token_code_hash": "91809b72cc6a7b4a62170698630b0b0848334f0403dba1aba7aec94396af7f95"
                }
              }
            }
          }
        }
      });
      console.log(returnAmount);
      const tokenAmount = Number(returnAmount.return_amount) / 1000000;
      setSiennaAmount0(tokenAmount.toLocaleString() || '');
    } catch (error) {
      console.log(error);

    }
  }

  const getSiennaSwapAmount1 = async (amount: string) => {
    //const queryClient = new SecretNetworkClient(API_URL);
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });

    try {
      // const returnAmount = await queryClient.queryContractSmart(
      //   siennaPairToken.at,
      //   {
      //     "swap_simulation": {
      //       "offer": {
      //         "amount": `${(Number(amount) * 1000000).toFixed(0) || 0}`,
      //         "token": {
      //           "custom_token": {
      //             "contract_addr": stakingToken.at,
      //             "token_code_hash": "91809b72cc6a7b4a62170698630b0b0848334f0403dba1aba7aec94396af7f95"
      //           }
      //         }
      //       }
      //     }
      //   }
      // )
      const returnAmount: any = await queryClient.query.compute.queryContract({
        contract_address: siennaPairToken.at, query: {
          "swap_simulation": {
            "offer": {
              "amount": `${(Number(amount) * 1000000).toFixed(0) || 0}`,
              "token": {
                "custom_token": {
                  "contract_addr": stakingToken.at,
                  "token_code_hash": "91809b72cc6a7b4a62170698630b0b0848334f0403dba1aba7aec94396af7f95"
                }
              }
            }
          }
        }
      });
      console.log(returnAmount);
      const tokenAmount = Number(returnAmount.return_amount) / 1000000;
      setSiennaAmount1(tokenAmount.toLocaleString() || '');
    } catch (error) {
      console.log(error);

    }
  }

  const getSiennaUserBalances = () => {
    console.log("in");

    if (!siennaLpTokenViewingKey) {
      // createLpViewingKey();
      return;
    }

    console.log("in2");
    console.log(siennaPairInfo, siennaLpBalance);

    if (siennaPairInfo === undefined || siennaLpBalance === '') {
      fetchLpBalance();
      return;
    }
    if (!siennaStakedLpTokenViewingKey) {
      // createStakedLpViewingKey();
      return;
    }

    console.log("in2");
    console.log(siennaPairInfo, siennaLpBalance);

    if (siennaPairInfo === undefined || siennaStakedLpBalance === '') {
      fetchStakedLpBalance();
      return;
    }
    console.log(siennaLpBalance, siennaTotalLiquidity);
    console.log(Number(siennaLpBalance), parseFloat(siennaTotalLiquidity));

    const ratio = Number(siennaLpBalance) / Number(siennaTotalLiquidity);
    console.log(ratio);

    setSiennaUserLiqRatio(ratio.toString());
    console.log((Number(siennaPairInfo.amount_0) / 1000000));
    console.log((ratio * (Number(siennaPairInfo.amount_0) / 1000000)).toString());

    setSiennaUserSescrtLiq((ratio * (Number(siennaPairInfo.amount_0) / 1000000)).toString())
    setSiennaUserSscrtLiq((ratio * (Number(siennaPairInfo.amount_1) / 1000000)).toString())


    console.log(siennaStakedLpBalance, siennaTotalLiquidity);
    console.log(Number(siennaStakedLpBalance), parseFloat(siennaTotalLiquidity));

    const rat = Number(siennaStakedLpBalance) / Number(siennaTotalLiquidity);
    console.log(rat);
    console.log((Number(siennaPairInfo.amount_0) / 1000000));
    console.log((rat * (Number(siennaPairInfo.amount_0) / 1000000)).toString());

    setSescrtRewardsLiq((rat * (Number(siennaPairInfo.amount_0) / 1000000)).toString())
    setScrtRewardsLiq((rat * (Number(siennaPairInfo.amount_1) / 1000000)).toString())
    setSiennaUserTotalDeposit((((ratio * (Number(siennaPairInfo.amount_1) / 1000000)) + (rat * (Number(siennaPairInfo.amount_1) / 1000000))) * (secretPriceUsd) + ((ratio * (Number(siennaPairInfo.amount_0) / 1000000)) + (rat * (Number(siennaPairInfo.amount_0) / 1000000))) * (sesecretPriceUsd)).toFixed(5))

  }


  const ViewTotalDeposit = async () => {

    if (!siennaLpTokenViewingKey) {
      createLpViewingKey();
    }
    if (!siennaStakedLpTokenViewingKey) {
      createStakedLpViewingKey();

    }

  }

  // const getSiennaRewardBalances = ()=>{
  //   console.log("in");


  // }

  const getSiennaLendingInfo = async () => {
    //const queryClient = new SecretNetworkClient(API_URL);
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });

    try {
      sleep(1);
      //const token_state = await queryClient.queryContractSmart(slseScrtToken.at, { state: {} });
      const token_state: any = await queryClient.query.compute.queryContract({
        contract_address: slseScrtToken.at, query: { state: {} }
      });
      console.log("attention here");
      console.log(token_state);

      if (token_state) {

        let Ratio = (Number(token_state.total_borrows) + Number(token_state.underlying_balance));
        let lendingTvl = (Number(Ratio) / 1000000) * sesecretPriceUsd;
        console.log(sesecretPriceUsd);
        console.log("lending" + lendingTvl);
        setSiennaLendingTvl(lendingTvl.toLocaleString());

      }

    } catch (err) {
      console.log(err);

    }
  }


  const getSiennaLiquidityInfo = async () => {
    //const queryClient = new SecretNetworkClient(API_URL);
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });

    try {
      // const tokenInfo = await queryClient?.queryContractSmart(
      //   siennaPairToken.at,

      //   'pair_info' as unknown as object

      // );
      const tokenInfo: any = await queryClient.query.compute.queryContract({
        contract_address: siennaPairToken.at, query: { pair_info: {} }
      });
      console.log(tokenInfo);

      if (tokenInfo) {
        setSiennaPairInfo(tokenInfo.pair_info);
        let poolRatio = (Number(tokenInfo.pair_info.amount_0) / Number(tokenInfo.pair_info.amount_1)).toString();
        setSiennaPoolRatio(Number(poolRatio));
        console.log(poolRatio);
        console.log(secretPriceUsd);
        let poolTvl = Number(tokenInfo.pair_info.amount_1) / 1000000 * secretPriceUsd * 2;
        // console.log("shobhit tvl"+poolTvl);

        setSiennaPoolTvl(poolTvl);
        setSiennaTvl(poolTvl.toLocaleString());
        setSiennaTotalLiquidity((Number(tokenInfo.pair_info.total_liquidity) / 1000000).toString());
      }

    } catch (err) {
      console.log(err);

    }

    //     try {
    //       const tokenInfo = await queryClient?.queryContractSmart(
    //         secretPairToken.at,

    //         {
    //           pair:{}
    //         }

    //       );
    //       console.log(tokenInfo);

    //       // if(tokenInfo){
    //       //   setSiennaPairInfo(tokenInfo.pair_info);
    //       //   let poolRatio = (Number(tokenInfo.pair_info.amount_0)/Number(tokenInfo.pair_info.amount_1)).toString();
    //       //   setSiennaPoolRatio(Number(poolRatio));
    //       //   console.log(poolRatio);
    //       //   console.log(secretPriceUsd);
    //       //   let poolTvl = Number(tokenInfo.pair_info.amount_1)/1000000 * secretPriceUsd*2;
    //       //   setSiennaTvl(poolTvl.toLocaleString());
    //       //   setSiennaTotalLiquidity((Number(tokenInfo.pair_info.total_liquidity)/1000000).toString());
    //       // }

    // }catch(err){
    //   console.log(err);

    // }

  }


  const fetchSecretPrice = async () => {
    const CoinGeckoClient = new CoinGecko();
    let data = await CoinGeckoClient.simple.price({
      ids: ['secret'],
      vs_currencies: ['usd'],
    });
    if (data) {
      const price = data.data["secret"].usd;
      console.log(price);
      setSecretPriceUsd(price);
    }
  }

  const fetchSesecretPrice = async () => {
    const CoinGeckoClient = new CoinGecko();
    let data = await CoinGeckoClient.simple.price({
      ids: ['sescrt'],
      vs_currencies: ['usd'],

    });
    if (data) {
      const price = data.data["sescrt"].usd;
      console.log("sescrt price");
      console.log(price);
      setSesecretPriceUsd(price);
      console.log("reward dollar val" + Number(30 * 365) * (Number(sesecretPriceUsd)));


    }
  }
  const fetchSiennaPrice = async () => {
    const CoinGeckoClient = new CoinGecko();
    let data = await CoinGeckoClient.simple.price({
      ids: ['sienna'],
      vs_currencies: ['usd'],

    });
    if (data) {
      const price = data.data["sienna"].usd;
      console.log("sienna price" + price);
      setSiennaPriceUsd(price);
    }
  }

  return {
    getSiennaSwapAmount0, getSiennaSwapAmount1, siennaAmount0, siennaPriceUsd, ViewTotalDeposit,
    siennaAmount1, siennaTvl, siennaLendingTvl, secretPriceUsd, sesecretPriceUsd, siennaPoolRatio, lpTokenSupplyRate, stakedlpTokenSupplyRate,
    createLpViewingKey, siennaLpTokenViewingKey, siennaLpBalance, getLendRate, lendAmount, lpPendingRewards, siennaStakedLpBalance, swapTimer,
    fetchLpBalance, siennaUserSscrtLiq, siennaUserSescrtLiq, getSiennaUserBalances, siennaUserTotalDeposit, siennaRewardsTvl, liqRatio, scrtRewardsLiq, sescrtRewardsLiq,
  }
}