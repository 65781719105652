import React, { useContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "../Liquidity.css";
import scrtLogo from "../../../../assets/img/scrt.png";
import sescrtLogo from "../../../../assets/img/secrt.png";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../../utils/walletState";
import { UserContext } from "../../../../context/user-context";
import DefiInput from "../../defi-input/DefiInput";
import { stakingToken } from "../../../../contracts/staking_token";
import { siennaPairToken } from "../../../../contracts/siennaPairAddress";
import LoadingModal from "../../../../components/LoadingModal/LoadingModal";
import { SiennaSwapContext } from "../../../../context/swap-context/sienna-swap-context";
import { sscrtToken } from "../../../../contracts/sscrt_token";
import { denomConst } from "../../../../utils/constants";
import { lpTokenAddress } from "../../../../contracts/lp_token";
import { stakedlpToken} from "../../../../contracts/staked_lptoken";
import LiquidityInterface from "../LiquidityInterface";

const SiennaLiquidity = (props: any) => {
  const [liqHeight, setLiqHeight] = useState("0");
  const [sscrtInputAmount, setSscrtInputAmount] = useState("");
  const [sescrtInputAmount, setseScrtInputAmount] = useState("");
  const [liqLoading, setLiqLoading] = useState(false);
  const [liqLoadingMessage, setLiqLoadingMessage] = useState("");
  const [liqMode, setLiqMode] = useState("add");
  const { address, balance, client } = useRecoilValue(walletState);
  const { sescrtBalance, sscrtBalance, refreshBalances } =
    useContext(UserContext);
  const {
    // secretPriceUsd,
    siennaPoolRatio,
    // createLpViewingKey,
    // siennaLpTokenViewingKey,
    // siennaLpBalance,
    // fetchLpBalance,
    siennaUserSescrtLiq,
    siennaUserSscrtLiq,
    getSiennaUserBalances,
    lpPendingRewards,
    siennaStakedLpBalance,
    siennaLpBalance,
    scrtRewardsLiq,
    sescrtRewardsLiq,
    ViewTotalDeposit,
  } = useContext(SiennaSwapContext);

  const handleInputChange = (e: any) => {
    console.log(e.target.name);

    const value = e.target.value;
    if (e.target.name === "sscrt") {
      setSscrtInputAmount(value);
      setseScrtInputAmount(
        (Number(value) * siennaPoolRatio).toLocaleString().replaceAll(",", "")
      );
    } else {
      setseScrtInputAmount(value);
      setSscrtInputAmount(
        (Number(value) / siennaPoolRatio).toLocaleString().replaceAll(",", "")
      );
    }
  };

  const addLiquidity = async () => {
    setLiqLoadingMessage(
      `Adding ${sscrtInputAmount} ${denomConst.secTokenDenom} of liquidity.`
    );
    setLiqLoading(true);
    try {
      // await client?.execute(
      //   stakingToken.at,
      //   {
      //     increase_allowance: {
      //       spender: "secret13cr8sph2rhtdn99fwaqp9y3ygjdlxfn7r2cw2z",
      //       amount: (parseFloat(sescrtInputAmount) * 1000000).toFixed(0),
      //       expiration: null,
      //       padding: null,
      //     },
      //   },
      //   "",
      //   [],
      //   { amount: [], gas: "1000000" }
      // );
      // await client?.execute(
      //   sscrtToken.at,
      //   {
      //     increase_allowance: {
      //       spender: "secret13cr8sph2rhtdn99fwaqp9y3ygjdlxfn7r2cw2z",
      //       amount: (parseFloat(sscrtInputAmount) * 1000000).toFixed(0),
      //       expiration: null,
      //       padding: null,
      //     },
      //   },
      //   "",
      //   [],
      //   { amount: [], gas: "1000000" }
      // );
      // await client?.execute(
      //   siennaPairToken.at,
      //   {
      //     add_liquidity: {
      //       deposit: {
      //         pair: {
      //           token_0: {
      //             custom_token: {
      //               contract_addr:
      //                 "secret16zfat8th6hvzhesj8f6rz3vzd7ll69ys580p2t",
      //               token_code_hash:
      //                 "91809b72cc6a7b4a62170698630b0b0848334f0403dba1aba7aec94396af7f95",
      //             },
      //           },
      //           token_1: {
      //             custom_token: {
      //               contract_addr:
      //                 "secret1k0jntykt7e4g3y88ltc60czgjuqdy4c9e8fzek",
      //               token_code_hash:
      //                 "af74387e276be8874f07bec3a87023ee49b0e7ebe08178c49d0a49c3c98ed60e",
      //             },
      //           },
      //         },
      //         amount_0: (parseFloat(sescrtInputAmount) * 1000000).toFixed(0),
      //         amount_1: (parseFloat(sscrtInputAmount) * 1000000).toFixed(0),
      //       },
      //     },
      //   },
      //   "",
      //   [],
      //   { amount: [], gas: "1000000" }
      // );
      toast.success(
        `${sscrtInputAmount} amount of ${denomConst.secTokenDenom} liquidity added.`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      refreshBalances();
      getSiennaUserBalances();
    } catch (error) {
      let message = "Something went wrong!";
      if (error instanceof Error) {
        message = error.message;
      }
      console.log(error);
      toast.error(message);
    } finally {
      setLiqLoading(false);
    }
  };

  const withdrawLiquidity = async () => {
    setLiqLoadingMessage(
      `Withdrawing ${sscrtInputAmount} ${denomConst.secTokenDenom} of liquidity.`
    );
    setLiqLoading(true);
    try {
      // await client?.execute(
      //   lpTokenAddress.at,
      //   {
      //     send: {
      //       recipient: "secret13cr8sph2rhtdn99fwaqp9y3ygjdlxfn7r2cw2z",
      //       amount: (parseFloat(sscrtInputAmount) * 1000000).toFixed(0),
      //       msg: btoa(
      //         JSON.stringify({ remove_liquidity: { recipient: address } })
      //       ),
      //     },
      //   },
      //   "",
      //   [],
      //   { amount: [], gas: "1000000" }
      // );
      toast.success(
        `${sscrtInputAmount} amount of ${denomConst.secTokenDenom} liquidity added.`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      refreshBalances();
      getSiennaUserBalances();
    } catch (error) {
      console.log(error);
      let message = "Something went wrong!";
      if (error instanceof Error) {
        message = error.message;
      }
      console.log(error);
      toast.error(message);
    } finally {
      setLiqLoading(false);
    }
  };
  const ActivateRewards = async () => {
    setLiqLoadingMessage(
      `Activating ${Number(siennaLpBalance)}  rewards.`
      
    );
    setLiqLoading(true);
    try {
      // await client?.execute(
      //   lpTokenAddress.at,
      //                 {
      //       send: {
      //         recipient: "secret192qwv3eh0f6txe6gk5zycxmkrj879jlz976nue",
      //         amount: (parseFloat(siennaLpBalance) * 1000000).toFixed(0)
  
      //       } 
      //     },
      //   "",
      //   [],
      //   { amount: [], gas: "1000000" }
      // );
      toast.success(
        "Rewards Activated",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      refreshBalances();
      getSiennaUserBalances();
      window.location.reload();
    } catch (error) {
      let message = "Something went wrong!";
      if (error instanceof Error) {
        message = error.message;
      }
      console.log(error);
      toast.error(message);
    } finally {
      setLiqLoading(false);
      
    }
  };

  const ClaimRewards = async () => {
    setLiqLoadingMessage(
      `Claiming ${Number(lpPendingRewards)} seSCRT rewards.`
      
    );
    setLiqLoading(true);
    try {
      // await client?.execute(
      //   stakedlpToken.at,
      //                 {
      //    rewards: {
      //       claim :{}
      //     }
      //     },
      //   "",
      //   [],
      //   { amount: [], gas: "1000000" }
      // );
      toast.success(
        "Rewards Claimed",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      refreshBalances();
      getSiennaUserBalances();
      window.location.reload();
    } catch (error) {
      let message = "Something went wrong!";
      if (error instanceof Error) {
        message = error.message;
      }
      console.log(error);
      toast.error(message);
    } finally {
      setLiqLoading(false);
      
    }
  };

  const DeactivateRewards= async () => {
    setLiqLoadingMessage(
      `Deactivating ${Number(siennaStakedLpBalance)} amount of  rewards.`
      
    );

    
    
    setLiqLoading(true);
    try {
      // await client?.execute(
      //   stakedlpToken.at,
      //                 {
      //    rewards: {
      //       withdraw: {
      //         amount: (parseFloat(siennaStakedLpBalance) * 1000000).toFixed(0)
      //       }
      //     }
      //     },
      //   "",
      //   [],
      //   { amount: [], gas: "1000000" }
      // );
      toast.success(
        "Succesfully Deactivated  Rewards",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      refreshBalances();
      getSiennaUserBalances();
      window.location.reload();
    } catch (error) {
      let message = "Something went wrong!";
      if (error instanceof Error) {
        message = error.message;
      }
      console.log(error);
      toast.error(message);
    } finally {
      setLiqLoading(false);
      
    }
  };

  return (
    <>
      <LiquidityInterface
        isOpen={props.isOpen}
        liqLoading={liqLoading}
        tokenInputAmount={sscrtInputAmount}
        secTokenInputAmount={sescrtInputAmount}
        tokenBalance={sscrtBalance}
        secTokenBalance={sescrtBalance}
        addLiquidity={addLiquidity}
        withdrawLiquidity={withdrawLiquidity}
        liqLoadingMessage={liqLoadingMessage}
        close={props.close}
        claim={ClaimRewards}
        stakedlp={siennaStakedLpBalance}
        nonstakedlp={siennaLpBalance}
        activaterewards={ActivateRewards}
        deactivaterewards={DeactivateRewards}
        liqmode={liqMode}
        provider={"sienna"}
        pendingrewards={lpPendingRewards}
        setMode={(m: string) => setLiqMode(m)}
      >
        <DefiInput
          mode={liqMode}
          showError={Number(sscrtInputAmount) > Number(sscrtBalance)}
          name="sscrt"
          onInputChange={handleInputChange}
          pendingrewards={lpPendingRewards}
          value={sscrtInputAmount}
          balType={"Pooled with Rewards:"}
          lpbalance={scrtRewardsLiq}
          tokenName="sSCRT"
          provider={"sienna"}
          section="upper"
          tokenBalance={liqMode === "claim" ? (Number(lpPendingRewards)).toFixed(5):(liqMode === "add"?sscrtBalance: siennaUserSscrtLiq)}
          logoSrc={liqMode==="claim"?sescrtLogo:scrtLogo}
          getBalance={ViewTotalDeposit}
        />
        {/* {!siennaLpTokenViewingKey && (
          <button onClick={createLpViewingKey} className="viewing-key-button">
            Create LP Key
          </button>
        )}
        {siennaLpTokenViewingKey && (
          <button onClick={fetchLpBalance} className="viewing-key-button">
            View Balance {siennaLpBalance}
          </button>
        )} */}
       { !(liqMode==="claim") && <DefiInput
          mode={liqMode}
          showError={Number(sescrtInputAmount) > Number(sescrtBalance)}
          name="sescrt"
          claimTabActive={true}
          provider={"sienna"}
          balType={"Pooled with Rewards:"}
          lpbalance={sescrtRewardsLiq}
          onInputChange={handleInputChange}
          value={sescrtInputAmount}
          tokenName="seSCRT"
          section="upper"
          tokenBalance={liqMode === "add" ? sescrtBalance :siennaUserSscrtLiq}
          logoSrc={sescrtLogo}
        />}
        {sscrtInputAmount && (
          <div className="liquidity-rate-value-wrapper">
            <div className="liquidity-rate">
              1 {denomConst.secTokenDenom} ≈ {siennaPoolRatio}{" "}
              {denomConst.derTokenDenom}
              {/* ≈ ${secretPriceUsd} */}
            </div>
            {/* <div className="liquidity-value">
            $
            {sscrtInputAmount
              ? (Number(sscrtInputAmount) * secretPriceUsd * 2).toLocaleString()
              : "0.00"}
          </div> */}
          </div>
        )}
      </LiquidityInterface>
    </>
  );
};

export default SiennaLiquidity;
