import { chainInfo } from '../utils/chainInfo';
import { SecretNetworkClient } from 'secretjs';
import { useSetRecoilState } from 'recoil';
import { walletState } from '../utils/walletState';
import { coinConvert } from '../utils/coin';

import { API_URL, sleep } from "../utils/constants";
import { useContext } from 'react';
import { UserContext } from '../context/user-context';

export const useDisconnetWallet = () => {

  const { resetBalances } = useContext(UserContext);

  const setWalletState = useSetRecoilState(walletState);
  return () => {
    sessionStorage.setItem('isLoggedIn', 'false');
    resetBalances();
    setWalletState({
      client: undefined,
      address: undefined,
      shortAddress: undefined,
      balance: undefined,
    })
  }
}

export const useConnectWallet = () => {
  const setWalletState = useSetRecoilState(walletState);
  return async () => {
    await sleep(1);
    while (
      !(window as any).keplr ||
      !(window as any).getEnigmaUtils ||
      !(window as any).getOfflineSignerOnlyAmino
    ) {
      await sleep(50);
    }
    //await (window as any).keplr?.experimentalSuggestChain(chainInfo);
    const chainId = 'secret-4';
    await (window as any).keplr?.enable(chainId);
    if ((window as any).keplr === undefined) return;

    const keplrOfflineSigner = (window as any).keplr.getOfflineSignerOnlyAmino(chainId);
    const [{ address: myAddress }] = await keplrOfflineSigner.getAccounts();

    const wasmChainClient = new SecretNetworkClient({
      url: API_URL,
      chainId: chainId,
      wallet: keplrOfflineSigner,
      walletAddress: myAddress,
      encryptionUtils: (window as any).keplr.getEnigmaUtils(chainId),
    });
    const balance = (await wasmChainClient.query.bank.balance({address: myAddress, denom: 'uscrt'})).balance?.amount;

    setWalletState({
      address: myAddress,
      shortAddress: (myAddress).substr(0, 8)
        + '...'
        + (myAddress).substr((myAddress).length - 3, 3)
      ,
      balance: coinConvert(balance as string, 6, 'human'),
      client: wasmChainClient,
    })
  }
}
