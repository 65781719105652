import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { useContext } from 'react';
import { useRecoilValue } from "recoil";
import axios from 'axios';
import { walletState } from "../utils/walletState";
import { stakingToken } from "../contracts/staking_token";
import { slseScrtToken } from "../contracts/sl-seSCRT";
import { stakedSlsescrtToken } from "../contracts/stakedslsescrt";
import { stakingContract } from "../contracts/staking_contract";
import { communityFarming } from "../contracts/community_farming";
import { coinConvert } from "../utils/coin";
import { SecretNetworkClient } from "secretjs";
import { API_URL } from "../utils/constants";
import { sleep } from "../utils/constants";
import { sscrtToken } from "../contracts/sscrt_token";
import { SiennaSwapContext } from "../context/swap-context/sienna-swap-context";

export const useData = () => {
  //const queryClient = new CosmWasmClient(API_URL);
  const { sesecretPriceUsd, siennaPriceUsd, siennaLendingTvl } = useContext(SiennaSwapContext);
  const { address, client, balance } = useRecoilValue(walletState);
  const [sescrtBalance, setSescrtBalance] = useState("");
  const [totalAmountLended, setTotalAmountLended] = useState("");
  const [slsescrtBalance, setSlsescrtBalance] = useState("");
  const [stakedslsescrtbalance, setStakedslsescrtBalance] = useState("");
  const [stakedslsescrtViewingKey, setStakedSlsescrtViewingKey] = useState("");
  const [stakedslsupplyrate, setStakedslsupplyrate] = useState('');
  const [nonstakedslsupplyrate, setNonStakedslsupplyrate] = useState('');
  const [viewingKey, setViewingKey] = useState("");
  const [sscrtBalance, setSscrtBalance] = useState("");
  const [pendingRewards, setPendingRewards] = useState('');
  const [sscrtViewingKey, setSscrtViewingKey] = useState("");
  const [slsescrtViewingKey, setSlsescrtViewingKey] = useState("");
  const [isMessageLoading, setMessageLoading] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [isMobileUser, setIsMobileUser] = useState<boolean>(false);
  const [claimAmount, setClaimAmount] = useState<any>();
  const [pendingClaims, setPendingClaims] = useState([]);
  const [totalPending, setTotalPending] = useState<any>();
  const [rate, setRate] = useState('');
  const [lendingrate, setLendingRate] = useState('');
  const [info, setInfo] = useState<any>({});
  const [apy, setApy] = useState<any>("");
  const [activeWindow, setActiveWindow] = useState<any>({});
  const [isLoading, setIsLoading] = useState<any>(true);
  const [secretUnit, setSecretUnit] = useState('SCRT');
  const [isWindowFetching, setIsWindowFetching] = useState(false);
  const [activeWindowAmount, setActiveWindowAmount] = useState<any>(0);
  const [commFarmPhaseNumber, setcommFarmPhaseNumber] = useState<Number>(0);
  const [isWalkingThrough, setIsWalkingThrough] = useState<Boolean>(false);
  const [currentWTPage, setCurrentWTPage] = useState<number>(1);
  const [token2For1Amount, setToken2For1Amount] = useState('');
  const [token1For2Amount, setToken1For2Amount] = useState('');
  const [token2For1Fees, setToken2For1Fees] = useState('');
  const [token1For2Fees, setToken1For2Fees] = useState('');
  const [num, setNum] = useState(0);
  const [dnum, setDnum] = useState(0);
  const [lendTimer, setLendTimer] = useState(0);
  const [lendRatio, setLendRatio] = useState(0);
  const [lendingAPY, setlendingAPY] = useState('');
  const [validatorMin, setValidatorMin] = useState(0);
  const [myRewards, setMyRewards] = useState();
  const [myDeposits, setMyDeposits] = useState();
  const [farmingInfo, setFarmingInfo] = useState();
  const [phaseNumber, setPhaseNumber] = useState<any>();
  const [phaseEndsIn, setPhaseEndsIn] = useState<any>();
  useEffect(() => {
    getMyRewards();
    getMyDeposits();
    fetchFarmingInfo();
  }, [address])


  useEffect(() => {
    getRate();
  }, [rate, sescrtBalance]);

  useEffect(() => {
    getSescrtBalance();
    getStakedSlsescrtBalance();
    getSlStakedSupplyRate();
    getSlNonStakedSupplyRate();
    getSscrtBalance();
    getSlsescrtBalance();
    getClaimAmount();
    getPendingRewards();
    getActiveWindowAmount();
    getPendingClaims();
  }, [balance, address, viewingKey, sscrtViewingKey, sscrtViewingKey, slsescrtViewingKey, stakedslsescrtViewingKey])

  useEffect(() => {
    const checkseScrtKey = async () => {
      try {
        if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent))) {
          setIsMobileUser(true);
        }
        const seScrtKey = await (window as any).keplr?.getSecret20ViewingKey("secret-4", stakingToken.at);
        console.log(seScrtKey);

        setViewingKey(seScrtKey);
      } catch (error) {
        console.log(error);

      }
    }
    const checksScrtKey = async () => {
      try {
        if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent))) {
          setIsMobileUser(true);
        }
        const sScrtKey = await (window as any).keplr?.getSecret20ViewingKey("secret-4", sscrtToken.at);
        console.log(sScrtKey);

        setSscrtViewingKey(sScrtKey);
      } catch (error) {
        console.log(error);

      }
    }
    const checkslseScrtKey = async () => {
      try {

        const slseScrtKey = await (window as any).keplr?.getSecret20ViewingKey("secret-4", slseScrtToken.at);
        console.log("slse key" + slseScrtKey);
        getSlsescrtBalance();
        setSlsescrtViewingKey(slseScrtKey);
      } catch (error) {
        console.log(error);

      }
    }
    const checkstakedslseScrtKey = async () => {
      try {
        const slseScrtKey = await (window as any).keplr?.getSecret20ViewingKey("secret-4", stakedSlsescrtToken.at);
        console.log("slse staked key" + slseScrtKey);
        setStakedSlsescrtViewingKey(slseScrtKey);
      } catch (error) {
        console.log("no stake key");
        console.log(error);

      }
    }
    checkseScrtKey();
    checksScrtKey();
    checkslseScrtKey();
    checkstakedslseScrtKey();

  }, [address])


  useEffect(() => {
    const fetchData = async () => {
      await fetchInfo();
      await fetchApy();
      await fetchWindow();
    }
    fetchData();
  }, []);

  const toggleSecretUnit = (unit: string) => {
    setSecretUnit(unit);
  }

  const resetBalances = () => {
    setSescrtBalance("");
    setSlsescrtBalance("");
    setStakedslsescrtBalance("");
    setClaimAmount(undefined);
    setActiveWindowAmount(0);
    setTotalPending(undefined)
  }

  const refreshBalances = () => {
    getSescrtBalance();
    getSscrtBalance();
    getSlsescrtBalance();
    getStakedSlsescrtBalance()

  }

  const getRate = async () => {
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
    //const queryClient = new CosmWasmClient(API_URL);
    let response: any;
    try {
      sleep(1);
      //response = await queryClient.queryContractSmart(stakingContract.at, { exchange_rate: {} });
      response = await queryClient.query.compute.queryContract({
        contract_address: stakingContract.at,
        code_hash: stakingContract.hash,
        query: {
          exchange_rate: {}
        }
      });
    } catch (error) {
      console.log(error);
      //handleErrorMessage(error);
      return;
    }

    let exch_rate = response.exchange_rate.rate;
    if (exch_rate.length > 10) {
      exch_rate = exch_rate.slice(0, 10);
    }
    setRate(exch_rate);

  }
  const getSlNonStakedSupplyRate = async () => {
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
    //const queryClient = new CosmWasmClient(API_URL);
    let response: any;
    try {
      sleep(1);
      //response = await queryClient.queryContractSmart(slseScrtToken.at, { token_info: {} });
      response = await queryClient.query.compute.queryContract({
        contract_address: slseScrtToken.at,
        code_hash: slseScrtToken.hash,
        query: {
          token_info: {}
        }
      });
      const amount = (Number(response.token_info.total_supply) / 1000000).toString();
      setDnum(Number(response.token_info.total_supply) / 1000000);
      setNonStakedslsupplyrate(amount);
      // console.log("unstaked staked supply sl");
      // console.log(amount);
    } catch (error) {
      console.log(error);
      //handleErrorMessage(error);
      return;
    }


  }
  const getSlStakedSupplyRate = async () => {
    //const queryClient = new CosmWasmClient(API_URL);
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
    try {
      sleep(1);
      const time = Math.floor(+new Date() / 1000);
      const balanceRes: any = await client?.query.compute.queryContract({
        contract_address: stakedSlsescrtToken.at,
        code_hash: stakedSlsescrtToken.hash,
        query: {
          rewards: {
            pool_info: {
              at: time
            }
          }
        }
      });
      const amount = (Number(balanceRes.rewards.pool_info.staked) / 1000000).toString();

      setStakedslsupplyrate(amount);
      // console.log("staked supply sl");
      // console.log( balanceRes.rewards.pool_info.staked);

      setNum(Number(balanceRes.rewards.pool_info.staked) / 1000000);
      //const token_state = await queryClient.queryContractSmart(slseScrtToken.at, { state: {} });
      const token_state: any = await queryClient.query.compute.queryContract({
        contract_address: slseScrtToken.at, query: {
          state: {}
        }
      });
      let tvlRatio = (Number(token_state.total_borrows) + Number(token_state.underlying_balance));
      let lendingTvl = (Number(tvlRatio) / 1000000);
      //const unstakedBalanceRes = await queryClient.queryContractSmart(slseScrtToken.at, { token_info: {} });
      const unstakedBalanceRes: any = await queryClient.query.compute.queryContract({
        contract_address: slseScrtToken.at,
        code_hash: slseScrtToken.hash,
        query: {
          token_info: {}
        }
      });
      setDnum(Number(unstakedBalanceRes.token_info.total_supply) / 1000000);

      const numValue = Number(balanceRes.rewards.pool_info.staked) / 1000000;
      const denomValue = Number(unstakedBalanceRes.token_info.total_supply) / 1000000

      const Ratio = (numValue / denomValue);
      setLendRatio(Ratio * lendingTvl);
      // console.log("udit_ratio "+Ratio*lendingTvl);

    } catch (error) {
      console.log("supll error");
      console.log(error);
      //handleErrorMessage(error);
      return;
    }


  }
  // const getLendingApy =()=>{
  //     let totalInterest=100*30*365*(Number(siennaPriceUsd));
  //     let principleAmt=((Number(stakedslsupplyrate)/1000000)*Number(nonstakedslsupplyrate))*(Number(siennaLendingTvl));
  //     console.log(totalInterest);
  //     console.log(principleAmt);
  //     console.log(siennaLendingTvl);
  //     let apy=totalInterest/principleAmt;
  //     console.log("apyyyyyyyyyy:"+apy);
  // }


  const getLendingRate = async () => {
    //const queryClient = new CosmWasmClient(API_URL);
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });

    let response: any;
    try {
      sleep(1);
      //response = await queryClient.queryContractSmart(slseScrtToken.at, { exchange_rate: {} });
      response = await queryClient.query.compute.queryContract({
        contract_address: slseScrtToken.at,
        code_hash: slseScrtToken.hash,
        query: { exchange_rate: {} }
      });
      // console.log("attention here");
      //console.log(response);
      let exch_rate = response;
      // console.log("exchange rate",exch_rate);
      // console.log("staked"+stakedslsescrtbalance);
      // console.log("sesecusd"+sesecretPriceUsd);
      setLendingRate(exch_rate);
      setTotalAmountLended(((Number(exch_rate)) * ((Number(slsescrtBalance) + Number(stakedslsescrtbalance)) * (Number(sesecretPriceUsd)))).toFixed(10));
      console.log("lended amount" + totalAmountLended);
    } catch (error) {

      console.log(error);
      return;
    }
  }


  async function fetchInfo() {
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
    const infoRes: any = await queryClient.query.compute.queryContract({
      contract_address: stakingContract.at,
      code_hash: stakingContract.hash,
      query: {
        info: {}
      }
    });
    // console.log("validator info check");
    console.log(infoRes);
    setInfo(infoRes.info);
    var min = Number.MAX_SAFE_INTEGER;

    for (var i = 0; i < infoRes.info.validators.length; i++) {
      if (Number(infoRes.info.validators[i].staked) < min) min = Number(infoRes.info.validators[i].staked);
    }
    console.log("validator minium" + min / 1000000);
    setValidatorMin(Number(min / 1000000));

  }

  async function fetchApy() {
    try {
      setApy(23.781)
    } catch (error) {
      console.log(error);
      return;
    }

    setIsLoading(false);
  }

  async function fetchWindow() {
    setIsWindowFetching(true);
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
    const windowRes: any = await queryClient.query.compute.queryContract({
      contract_address: stakingContract.at,
      code_hash: stakingContract.hash,
      query: {
        window: {}
      }
    });
    console.log(windowRes);
    setActiveWindow(windowRes.window);
    setIsWindowFetching(false);
  }

  async function createViewingKey() {
    setMessageLoading(true);

    try {

      const result = await (window as any).keplr?.suggestToken("secret-4", stakingToken.at);
      console.log(result);
    } catch (error) {
      let errorMessage = "Something went wrong!";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      toast.error(`${errorMessage}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    finally {
      setMessageLoading(false);
    }

    window.location.reload();
  }

  async function createSscrtViewingKey() {
    setMessageLoading(true);

    try {
      const result = await (window as any).keplr?.suggestToken("secret-4", sscrtToken.at);
      console.log(result);
    } catch (error) {
      let errorMessage = "Something went wrong!";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      toast.error(`${errorMessage}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    finally {
      setMessageLoading(false);
    }

    window.location.reload();
  }
  async function createSlsescrtViewingKey() {
    setMessageLoading(true);

    try {
      const result = await (window as any).keplr?.suggestToken("secret-4", slseScrtToken.at);
      // console.log("trying to make jey");
      setSlsescrtViewingKey(result);
      console.log("slse key" + result);

    } catch (error) {
      let errorMessage = "Something went wrong!";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      toast.error(`${errorMessage}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    finally {
      setMessageLoading(false);
    }

    window.location.reload();
  }
  async function createStakedSlsescrtViewingKey() {
    setMessageLoading(true);

    try {
      const result = await (window as any).keplr?.suggestToken("secret-4", stakedSlsescrtToken.at);
      console.log("trying to make jey");
      console.log("slse staked key" + result);

    } catch (error) {
      let errorMessage = "Something went wrong!";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      toast.error(`${errorMessage}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    finally {
      setMessageLoading(false);
    }

    window.location.reload();
  }

  async function getSescrtBalance() {
    if (viewingKey === "") return;

    setMessageLoading(true);
    try {
      sleep(1);
      const balanceRes: any = await client?.query.compute.queryContract({
        contract_address: stakingToken.at,
        code_hash: stakingToken.hash,
        query: {
          balance: {
            address: address,
            key: viewingKey,
          }
        }
      });

      const amount = coinConvert(balanceRes.balance.amount, 6, "human");
      console.log(amount);

      setSescrtBalance(amount);
      setMessageLoading(false);

    } catch (error) {
      setMessageLoading(false);
      return;
    }
  }

  async function getSscrtBalance() {
    if (sscrtViewingKey === "") return;

    setMessageLoading(true);
    try {
      sleep(1);
      const balanceRes: any = await client?.query.compute.queryContract({
        contract_address: sscrtToken.at,
        code_hash: sscrtToken.hash,
        query: {
          balance: {
            address: address,
            key: sscrtViewingKey,
          }
        }
      });

      const amount = coinConvert(balanceRes.balance.amount, 6, "human");
      console.log("sscrt" + amount);

      setSscrtBalance(amount);
      setMessageLoading(false);

    } catch (error) {
      setMessageLoading(false);
      return;
    }
  }

  async function createAllViewingKeys() {
    createSlsescrtViewingKey();
    createStakedSlsescrtViewingKey();
    // createSscrtViewingKey();
    // createViewingKey();
  }

  async function getSlsescrtBalance() {
    if (slsescrtViewingKey === "") return;

    setMessageLoading(true);
    try {
      sleep(1);
      const balanceRes: any = await client?.query.compute.queryContract({
        contract_address: slseScrtToken.at,
        code_hash: slseScrtToken.hash,
        query: {
          balance: {
            address: address,
            key: slsescrtViewingKey,
          }
        }
      });


      const amount = coinConvert(balanceRes, 6, "human");
      console.log("slse balance" + amount);

      setSlsescrtBalance(amount);
      setMessageLoading(false);

    } catch (error) {
      console.log("error slse");
      console.log(error);
      setMessageLoading(false);
      return;
    }
  }
  async function getStakedSlsescrtBalance() {
    if (stakedslsescrtViewingKey === "") return;

    setMessageLoading(true);
    try {
      sleep(1);
      const balanceRes: any = await client?.query.compute.queryContract({
        contract_address: stakedSlsescrtToken.at,
        code_hash: stakedSlsescrtToken.hash,
        query: {
          balance: {
            address: address,
            key: stakedslsescrtViewingKey,
          }
        }
      });

      const amount = coinConvert(balanceRes.balance.amount, 6, "human");
      console.log("slse  staked balance" + amount);
      setStakedslsescrtBalance(amount);
      setMessageLoading(false);

    } catch (error) {
      console.log("error slse staked");
      console.log(error);
      setMessageLoading(false);
      return;
    }
  }

  const getClaimAmount = async () => {
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
    while (address === undefined) await sleep(1);
    try {
      sleep(1);
      const response: any = await queryClient.query.compute.queryContract({
        contract_address: stakingContract.at,
        code_hash: stakingContract.hash,
        query: { user_claimable: { address: address } }
      });
      console.log(response);
      let amount = response.claimable.claimable_amount;
      const convertedAmount = coinConvert(amount, 6, "human");
      setClaimAmount(parseFloat(convertedAmount));
    } catch (error) {
      return;
    }

  }

  const getActiveWindowAmount = async () => {
    while (address === undefined) await sleep(1);
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
    try {
      sleep(1);
      const response: any = await queryClient.query.compute.queryContract({
        contract_address: stakingContract.at,
        code_hash: stakingContract.hash,
        query: { active_unbonding: { address: address } }
      });
      console.log("active unbonding", response);
      let amount = response.active_undelegation.undelegation;
      const convertedAmount = coinConvert(amount, 6, "human");
      setActiveWindowAmount(parseFloat(convertedAmount));
    } catch (error) {
      return;
    }

  }

  const getPendingClaims = async () => {
    while (address === undefined) await sleep(1);
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
    try {
      sleep(1);
      const response: any = await queryClient.query.compute.queryContract({
        contract_address: stakingContract.at,
        code_hash: stakingContract.hash,
        query: { undelegations: { address: address } }
      });
      console.log(response);
      let undelegations = response.pending_claims.pending;
      let totalUn = 0;
      for (const undel of undelegations) {
        totalUn += parseFloat(coinConvert(undel.scrt_amount, 6, "human"));
      }
      setPendingClaims(undelegations);
      setTotalPending(totalUn);
    } catch (error) {
      return;
    }

  }
  const getPendingRewards = async () => {
    while (address === undefined) await sleep(1);
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
    try {
      sleep(1);
      const time = Math.floor(+new Date() / 1000);
      const response: any = await queryClient.query.compute.queryContract({
        contract_address: stakedSlsescrtToken.at,
        code_hash: stakedSlsescrtToken.hash,
        query: {
          rewards: {
            user_info: {
              address: address,
              key: stakedslsescrtViewingKey,
              at: time
            }
          }
        }
      });
      console.log("pending rewards slscrt token");
      console.log(response);
      console.log(response.rewards.user_info.earned);
      console.log("timer value below");

      setLendTimer(Number(response.rewards.user_info.bonding));
      setPendingRewards((Number(response.rewards.user_info.earned) / 1000000000000000000).toString());

    } catch (error) {
      return;
    }

  }

  //Lockdrop Functions

  const CURRENT_TIME = parseInt((Date.now() / 1000).toString());
  async function fetchFarmingInfo() {
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
    try {
      const myInfo: any = await queryClient.query.compute.queryContract({
        contract_address: communityFarming.at,
        code_hash: communityFarming.hash,
        query: {
          info: {}
        }
      });

      console.log("farming info: ", myInfo);
      console.log("curr time: ", CURRENT_TIME);
      setFarmingInfo(myInfo.info);

      if (CURRENT_TIME < myInfo.info.time_2) {
        setPhaseNumber(1);
        setPhaseEndsIn((myInfo.info.time_2 - CURRENT_TIME));
      } else if (CURRENT_TIME < myInfo.info.time_3) {
        setPhaseNumber(2);
        setPhaseEndsIn((myInfo.info.time_3 - CURRENT_TIME));
      } else {
        setPhaseNumber(3);
        setPhaseEndsIn((myInfo.info.time_3 - CURRENT_TIME + 3600 * 24 * 21));
      }
      console.log("diff: ", (myInfo.info.time_2 - CURRENT_TIME));
    } catch (error) {
      return;
    }
  }

  async function getMyRewards() {

    try {
      const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
      const myRewards: any = await queryClient.query.compute.queryContract({
        contract_address: communityFarming.at,
        code_hash: communityFarming.hash,
        query: {
          reward: {
            address: address,
            blocktime: CURRENT_TIME,
          }
        }
      });
      setMyRewards(myRewards);

    } catch (error) {
      return;
    }
  }

  async function getMyDeposits() {
    const queryClient = new SecretNetworkClient({ url: API_URL, chainId: "secret-4" });
    try {
      const myDeposits: any = await queryClient.query.compute.queryContract({
        contract_address: communityFarming.at,
        code_hash: communityFarming.hash,
        query: {
          user_deposit: {
            address: address
          }
        }
      });

      setMyDeposits(myDeposits);

    } catch (error) {
      return;
    }
  }

  return {
    sescrtBalance, getSescrtBalance, slsescrtBalance, getSlsescrtBalance, stakedslsescrtbalance,
    viewingKey, createViewingKey, sscrtViewingKey, createSscrtViewingKey, slsescrtViewingKey, createSlsescrtViewingKey, createStakedSlsescrtViewingKey, stakedslsescrtViewingKey,
    isMessageLoading, getClaimAmount, claimAmount, activeWindowAmount, getStakedSlsescrtBalance,
    getPendingClaims, pendingClaims, totalPending, totalAmountLended,
    info, activeWindow, fetchWindow, isLoading, apy, rate, lendTimer,
    secretUnit, toggleSecretUnit, commFarmPhaseNumber, setcommFarmPhaseNumber,
    getSscrtBalance, sscrtBalance, isWindowFetching, pendingRewards, validatorMin,
    fetchFarmingInfo, getMyRewards, getMyDeposits, stakedslsupplyrate, nonstakedslsupplyrate,
    myRewards, myDeposits, farmingInfo, phaseEndsIn, phaseNumber, lendingrate,
    isWalkingThrough, setIsWalkingThrough, currentWTPage, setCurrentWTPage, createAllViewingKeys,
    resetBalances, isLoggingIn, setIsLoggingIn, refreshBalances, getLendingRate, lendRatio, isMobileUser
  };
}
