import React, { useContext, useEffect, useState } from "react";
import { Encoding } from "@iov/encoding";
import axios from "axios";
import { API_URL } from "../../../utils/constants";
import Timer from "../../../utils/timer/Timer";
import { FaTimesCircle } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../utils/walletState";
import { communityFarming } from "../../../contracts/community_farming";
import { stakingToken } from "../../../contracts/staking_token";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import "./Lockdrop.css";
import { UserContext } from "../../../context/user-context";
import InfoBubble from "../../../utils/information/InfoBubble";
import InputComponent from "./InputComponent";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const DUMMY_TIMESTAMP = {
	phase1: Date.now() / 1000 + new Date().setSeconds(604800),
	phase2: Date.now() / 1000 + new Date().setSeconds(604800 * 4),
	phase3: Date.now() / 1000 + new Date().setSeconds(604800 * 7),
};

const Lockdrop = (props: any) => {
	const [openInput, setOpenInput] = useState<boolean>(false);
	const [lockingAmount, setLockingAmount] = useState("");
	const [rewards, setRewards] = useState<any>(0);
	const [unlockLoading, setUnlockLoading] = useState<boolean>(false);
	const [rewardLoading, setRewardLoading] = useState<boolean>(false);
	const [lockLoading, setLockLoading] = useState<boolean>(false);
	const [apyFactor, setApyFactor] = useState(14);
	const [activeTab, setActiveTab] = useState("Unlock");
	const { address, client, balance } = useRecoilValue(walletState);

	const {
		rate,
		sescrtBalance,
		viewingKey,
		createViewingKey,
		getSescrtBalance,
		apy,
	} = useContext(UserContext);

	//const queryClient = new CosmWasmClient(API_URL);

	useEffect(() => {
		if (props.phaseNumber === 1) {
			setApyFactor(14);
		} else if (props.phaseNumber === 2) {
			setApyFactor(12);
		} else {
			setApyFactor(10);
		}
		getSeasyRewards();
	}, [props.phaseNumber]);

	const getSeasyRewards = async () => {
		setRewardLoading(true);
		setRewardLoading(false);
		setRewards(0);
	};

	const lockAmount = async (lockingAmount: any) => {
		console.log(lockingAmount);
		setLockLoading(true);
		try {
			await client?.tx.compute.executeContract(
				{
					sender: address ?? "",
					contract_address: stakingToken.at,
					msg: {
						send: {
							amount: (parseFloat(lockingAmount) * 1_000_000).toFixed(0),
							recipient: communityFarming.at,
						},
					},
					sent_funds: [],
				},
				{
					gasLimit: 250_000,
				}
			);

			setLockLoading(false);
			setLockingAmount("");
			getSescrtBalance();
			toast.success(`${lockingAmount} amount of seSCRT successfully locked.`, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			props.onTransaction();
		} catch (error) {
			console.log(error);
			let errorMessage = "Something went wrong!";
			if (error instanceof Error) {
				errorMessage = error.message;
			}
			toast.error(errorMessage, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			setLockLoading(false);
			return;
		}
	};

	const unlockAmount = async (lockingAmount: any) => {
		console.log(lockingAmount);
		setUnlockLoading(true);
		try {
			await client?.tx.compute.executeContract(
				{
					sender: address ?? "",
					contract_address: communityFarming.at,
					code_hash: communityFarming.hash,
					msg: {
						withdraw: {
							amount: (parseFloat(lockingAmount) * 1_000_000).toFixed(0),
						},
					},
					sent_funds: [],
				},
				{
					gasLimit: 400_000,
				}
			);

			setUnlockLoading(false);
			setLockingAmount("");
			getSescrtBalance();
			toast.success(
				`${lockingAmount} amount of seSCRT successfully unlocked.`,
				{
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				}
			);
			props.onTransaction();
		} catch (error) {
			console.log(error);
			let errorMessage = "Something went wrong!";
			if (error instanceof Error) {
				errorMessage = error.message;
			}
			toast.error(errorMessage, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			setUnlockLoading(false);
			return;
		}
	};

	return (
		<div className='contract-info-wrapper'>
			<div className='info-card card lockdrop-card'>
				<div className='lockdrop-header'>COMMUNITY FARMING</div>
				<hr></hr>
				<div className='lockdrop-body'>
					<div className='lockdrop-info total-rewards'>
						<div className='lockdrop-info-data'>
							{"0"}
							<span> %</span>
						</div>
						<div className='lockdrop-info-subtitle'>
							SEASY APY
							<InfoBubble
								content={`${apyFactor} SEASY for 1 SCRT staking reward`}
							/>
						</div>
					</div>
					<div className='lockdrop-info lockdrop-graph'>
						<div className='phase-tag'>
							<p>PHASE {props.phaseNumber}</p>
							<div>has now ended</div>
						</div>
					</div>
					<div className='lockdrop-info my-rewards'>
						{/* <button onClick={makeWithdraw} className='stake-btn'>Withdraw Rewards</button> */}
						<div className='lockdrop-info-data'>
							{rewards}
							<span> SEASY</span>
						</div>
						<div className='lockdrop-info-subtitle'>MY EST. $SEASY REWARDS</div>
					</div>

					<div className='lockdrop-info total-deposits'>
						<div className='lockdrop-info-data'>
							{props.info ? Number(props.info.total) / 1000000 : "-"}
							<span> SCRT</span>
						</div>
						<div className='lockdrop-info-subtitle'>TOTAL DEPOSITS</div>
					</div>
					<div className='lockdrop-info my-deposits'>
						<div className='lockdrop-info-data'>
							{props.myDeposits
								? Number(props.myDeposits.user_deposit?.amount) / 1000000
								: "-"}
							<span> SCRT</span>
						</div>
						<div className='lockdrop-info-subtitle'>MY DEPOSITS</div>
					</div>
				</div>
				<div className='lock-sescrt-wrapper'>
					{/* {openInput&&<InputComponent head='Lock' maxValue={sescrtBalance} buttonName='Lock seSCRT'
           loading={lockLoading} execute={(lockingAmount:any)=>lockAmount(lockingAmount)}/>} */}
					{!openInput ? (
						<button
							disabled={props.myDeposits && sescrtBalance ? false : true}
							onClick={() => setOpenInput(true)}
							className='stake-btn'
						>
							{"Unlock Amount"}{" "}
							{!openInput && <FontAwesomeIcon icon={faAngleDown as IconProp} />}
						</button>
					) : (
						<div
							style={{ width: "300px", textAlign: "center" }}
							className='stake-menu-btn-wrapper'
						>
							<div className={`lockdrop-btn stake-menu-btn active-stake-btn`}>
								Unlock
							</div>
						</div>
					)}
				</div>
				{openInput ? (
					<InputComponent
						head='Unlock'
						showMax={props.myDeposits ? true : false}
						maxValue={Number(props.myDeposits.user_deposit.amount) / 1000000}
						buttonName='Unlock SCRT'
						loading={unlockLoading}
						execute={(lockingAmount: any) => unlockAmount(lockingAmount)}
					/>
				) : null}
				{openInput && (
					<div className='lockdrop-footer'>
						{/* {<FontAwesomeIcon onClick={()=>setOpenInput(false)} icon={faAngleDown} /> */}

						{
							<FaTimesCircle
								onClick={() => setOpenInput(false)}
								cursor='pointer'
								size='38'
								className='close-icon'
							/>
						}
					</div>
				)}
			</div>

			<ToastContainer />
		</div>
	);
};

export default Lockdrop;
