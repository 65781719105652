import React, { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faDiscord, faTelegram } from '@fortawesome/free-brands-svg-icons'
import WalkthroughBubble from "../../walkthrough-comp/WalkthroughBubble";
import './Header-Socials.css'
import { UserContext } from "../../../context/user-context";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const HeaderSocials = (props:any)=>{

    const {currentWTPage} = useContext(UserContext)

    return <div className="social-header-wrapper">
        <WalkthroughBubble isOpen={currentWTPage===6} content='You can connect with us on social media using the links here.'
       placement='right'>
       <div className={`social-header ${currentWTPage===6?'remove-fade-out':''}`}>

        <div className='social-header-icon '>
            <a target="_blank" href='https://discord.gg/gKjzApDstD'>
        <FontAwesomeIcon icon={faDiscord as IconProp} size="lg" />
        </a>
        </div>

        <div className='social-header-icon '>
            <a target="_blank" href='https://twitter.com/StakeEasy'>
        <FontAwesomeIcon icon={faTwitter as IconProp} size="lg" />
        </a>
        </div>
        <div className='social-header-icon '>
            <a target="_blank" href='https://t.me/StakeEasyProtocol'>
        <FontAwesomeIcon icon={faTelegram as IconProp} size="lg" />
        </a>
        </div>
        
    </div>
    </WalkthroughBubble>
    </div>
}
export default HeaderSocials;