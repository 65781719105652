import React from "react";

const DefiInput = (props: any) => {
  return (
    <div className="liquidity-input-wrapper input-1-wrapper">
      <div className="liquidity-input-label">
        <img src={(props.mode==="claim" && props.section==="lower")?"https://app.sienna.network/static/sienna-token.svg":props.logoSrc} />
        {(props.mode==="claim")?(props.section==="lower"?"Sienna":"seSCRT"):props.tokenName}
      </div>
      {props.section==="upper"&& 
      <div>
        <div className="liquidity-input-balance available-balance-prompt">
        <p>{props.mode === "withdraw" ? "Pooled: " :(props.mode==="claim"?"Rewards Earned :":"Available: ") }</p>
        {props.mode === "withdraw"? (
          props.tokenBalance ? (
            props.provider==="sienna"?
            ((Number(props.tokenBalance)<(1/0))?<span>{(Number(props.tokenBalance)+Number(props.lpbalance)).toFixed(6)}</span>:<span>-</span>):
            ((Number(props.tokenBalance)<(1/0))?<span>{(Number(props.tokenBalance)).toFixed(6)}</span>:<span>-</span>)
            
          ) : (
            <span
              onClick={props.getBalance}
              style={{
                cursor: "pointer",
              }}
            >
              View Balance
            </span>
          )
        ) : (
          props.mode==="claim"?(Number(props.tokenBalance)===0?<span>-</span>:<span>{(Number(props.tokenBalance)).toFixed(6)+" "} seSCRT</span>) :<span>{(Number(props.tokenBalance)).toFixed(6)}</span>
          
        )}
        
      </div>
      {(!(props.mode==="claim") && props.provider==="sienna" && props.mode === "withdraw") && <div className="liquidity-input-balance available-balance-prompt">
        <p>{props.balType }</p>
        {props.lpbalance ? (
            ((Number(props.lpbalance)<(1/0))?<span>{(Number(props.lpbalance)).toFixed(6)}</span>:<span>-</span>)
           
          ) : (
            <span
              onClick={props.getBalance}
              style={{
                cursor: "pointer",
              }}
            >
              View Balance
            </span>
          )}
        
      </div>}
      </div>
      
      

      }
      {/* {(props.section==="upper" && props.provider==="sienna"  )&&
      <div>
        
        
          
            <div className="bal-wrapper">
                {props.mode==="claim"?(<div className="liquidity-input-balance available-balance-prompt bal">Rewards Earned :
                {props.pendingrewards?
                <span className="balances">  {props.pendingrewards}
                </span>:<span
              onClick={props.getBalance}
              className="balances-btn"
            >
              View Balance
            </span>}   
            </div>): (<div className="liquidity-input-balance available-balance-prompt bal">Wallet balance: 

                {props.sescrtBalance?
                <span className="balances">  {(Number(props.sescrtBalance)).toFixed(5)}
                </span>:<span
              onClick={props.getBalance}
              className="balances-btn"
            >
              View Balance
            </span>} 
                </div>)}
              
                <div className="liquidity-input-balance available-balance-prompt bal">Deposited :
                {props.depositedslsescrtbal && props.stakedslsescrtbal?
                <span className="balances">  {(Number(props.lendFactor)*(Number((props.depositedslsescrtbal))+Number((props.stakedslsescrtbal)))).toFixed(5)}
                </span>:<span
              onClick={props.getBalance}
              className="balances-btn"
            >
              View Balance
            </span>}

                </div>
                <div className="liquidity-input-balance available-balance-prompt bal">Deposited with rewards :
                {props.stakedslsescrtbal?
                <span className="balances">  {(Number(props.lendFactor)*Number((props.stakedslsescrtbal))).toFixed(5)}
                </span>:<span
              onClick={props.getBalance}
              className="balances-btn"
            >
              View Balance
            </span>}   
            </div>
            
            
            </div>

       
      </div>

      } */}
      {props.section==="lower" && 
      <div>
        
        
          
            <div className="bal-wrapper">
                {props.mode==="claim"?(<div className="liquidity-input-balance available-balance-prompt bal">Rewards Earned :
                {props.pendingrewards?
                <span className="balances">  {(Number(props.pendingrewards).toFixed(12))+" "}
                Sienna</span>:<span
              // onClick={props.getBalance}
              // className="balances-btn"
            >
             -
            </span>}   
            </div>): (<div className="liquidity-input-balance available-balance-prompt bal">Wallet balance: 

                {props.sescrtBalance?
                <span className="balances">  {(Number(props.sescrtBalance)).toFixed(5)}
                </span>:<span
              onClick={props.viewsescrtBalance}
              className="balances-btn"
            >
              View Balance
            </span>} 
                </div>)}
              
                {!(props.mode==="claim") && <div className="liquidity-input-balance available-balance-prompt bal">Deposited :
                {(props.depositedslsescrtbal && props.stakedslsescrtbal && props.lendFactor)?
                <span className="balances">  {(Number(props.lendFactor)*(Number((props.depositedslsescrtbal))+Number((props.stakedslsescrtbal)))).toFixed(5)}
                </span>:<span
              onClick={props.getBalance}
              className="balances-btn"
            >
              View Balance
            </span>}

                </div>}

                {!(props.mode==="claim") &&<div className="liquidity-input-balance available-balance-prompt bal">Deposited with rewards :
                {props.stakedslsescrtbal?
                <span className="balances">  {(Number(props.lendFactor)*Number((props.stakedslsescrtbal))).toFixed(5)}
                </span>:<span
              onClick={props.getBalance}
              className="balances-btn"
            >
              View Balance
            </span>}   
            </div>}
            
            
            </div>

       
      </div>

      }
      {!(props.mode==="claim") &&
          <input
        autoComplete="off"
        disabled={props.disabled}
        name={props.name}
        onChange={props.onInputChange}
        className="stake-input liquidity-input"
        placeholder="0.00"
        value={props.value}
        id={props.id}
      />}

      {props.showError && (
        <div className="error-message-prompt">Insufficient Balance</div>
      )}
      
      
    </div>
  );
};

export default DefiInput;
