export const secretPairToken = {
    id:'secretPairToken',
    at:'secret1a7phxread4rjnyzw0hs53cflwha0y2h8v8klyt',
    hash: '0DFD06C7C3C482C14D36BA9826B83D164003F2B0BB302F222DB72361E0927490'
}
//provideliquidity
// {
//     "provide_liquidity": {
//       "assets": [
//         {
//           "info": {
//             "token": {
//               "contract_addr": "secret1k0jntykt7e4g3y88ltc60czgjuqdy4c9e8fzek",
//               "token_code_hash": "af74387e276be8874f07bec3a87023ee49b0e7ebe08178c49d0a49c3c98ed60e",
//               "viewing_key": ""
//             }
//           },
//           "amount": "100000"
//         },
//         {
//           "info": {
//             "token": {
//               "contract_addr": "secret16zfat8th6hvzhesj8f6rz3vzd7ll69ys580p2t",
//               "token_code_hash": "91809b72cc6a7b4a62170698630b0b0848334f0403dba1aba7aec94396af7f95",
//               "viewing_key": ""
//             }
//           },
//           "amount": "98804"
//         }
//       ]
//     }
//   }