import React, { useContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "../Lending.css";
import scrtLogo from "../../../../assets/img/scrt.png";
import sescrtLogo from "../../../../assets/img/secrt.png";

import { useRecoilValue } from "recoil";
import { walletState } from "../../../../utils/walletState";
import { UserContext } from "../../../../context/user-context";
import DefiInput from "../../defi-input/DefiInput";
import { stakingToken } from "../../../../contracts/staking_token";
import { siennaPairToken } from "../../../../contracts/siennaPairAddress";
import LoadingModal from "../../../../components/LoadingModal/LoadingModal";
import { SiennaSwapContext } from "../../../../context/swap-context/sienna-swap-context";
import { sscrtToken } from "../../../../contracts/sscrt_token";
import { slseScrtToken } from "../../../../contracts/sl-seSCRT";
import { stakedSlsescrtToken } from "../../../../contracts/stakedslsescrt";

import { denomConst } from "../../../../utils/constants";
import { lpTokenAddress } from "../../../../contracts/lp_token";
import LendingInterface from "../LendingInterface";

const SiennaLending = (props: any) => {
  const [liqHeight, setLiqHeight] = useState("0");
  const [sscrtInputAmount, setSscrtInputAmount] = useState("");
  const [sescrtInputAmount, setseScrtInputAmount] = useState("");
  const [liqLoading, setLiqLoading] = useState(false);
  const [liqLoadingMessage, setLiqLoadingMessage] = useState("");
  const [liqMode, setLiqMode] = useState("add");
  const { address, balance, client } = useRecoilValue(walletState);
  const { sescrtBalance, sscrtBalance, stakedslsescrtbalance, totalAmountLended, slsescrtBalance, refreshBalances, createAllViewingKeys, createViewingKey, pendingRewards } =
    useContext(UserContext);
  const {
    // secretPriceUsd,
    siennaPoolRatio,
    // createLpViewingKey,
    // siennaLpTokenViewingKey,
    // siennaLpBalance,
    // fetchLpBalance,
    siennaUserSescrtLiq,
    siennaUserSscrtLiq,
    lendAmount,
    getSiennaUserBalances,
  } = useContext(SiennaSwapContext);

  const handleInputChange = (e: any) => {
    console.log(e.target.name);

    const value = e.target.value;
    if (e.target.name === "sscrt") {
      setSscrtInputAmount(value);
      setseScrtInputAmount(
        (Number(value) * siennaPoolRatio).toLocaleString().replaceAll(",", "")
      );
    } else {
      setseScrtInputAmount(value);
      setSscrtInputAmount(
        (Number(value) / siennaPoolRatio).toLocaleString().replaceAll(",", "")
      );
    }
  };

  const Deposit = async () => {
    setLiqLoadingMessage(
      `Depositing ${sescrtInputAmount} seSCRT.`
    );
    setLiqLoading(true);
    try {
      // await client?.execute(
      //   stakingToken.at,
      //             {
      //       send: {
      //         recipient: "secret1nw9uyce8lu88sv38tqh7tm22ykkycywx3u4qh3",
      //         amount: (parseFloat(sescrtInputAmount) * 1000000).toFixed(0),
      //         msg: "ImRlcG9zaXQi"
      //       }
      //     },
      //   "",
      //   [],
      //   { amount: [], gas: "1000000" }
      // );
      toast.success(
        `${sescrtInputAmount} amount of ${denomConst.secTokenDenom} Lended.`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      refreshBalances();
      getSiennaUserBalances();
      window.location.reload();
    } catch (error) {
      let message = "Something went wrong!";
      if (error instanceof Error) {
        message = error.message;
      }
      console.log(error);
      toast.error(message);
    } finally {
      setLiqLoading(false);

    }
  };

  const ActivateRewards = async () => {
    setLiqLoadingMessage(
      `Activating ${Number(lendAmount) * Number(slsescrtBalance)} seSCRT amount of rewards.`

    );
    setLiqLoading(true);
    try {
      // await client?.execute(
      //   slseScrtToken.at,
      //                 {
      //       send: {
      //         recipient: "secret1wcekfgwq2r7h9wxfafedfrj29xy8wzegw6yrqv",
      //         amount: (parseFloat(slsescrtBalance) * 1000000).toFixed(0)
      //       }
      //     },
      //   "",
      //   [],
      //   { amount: [], gas: "1000000" }
      // );
      toast.success(
        "Rewards Activated",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      refreshBalances();
      getSiennaUserBalances();
      window.location.reload();
    } catch (error) {
      let message = "Something went wrong!";
      if (error instanceof Error) {
        message = error.message;
      }
      console.log(error);
      toast.error(message);
    } finally {
      setLiqLoading(false);

    }
  };

  const ClaimRewards = async () => {
    setLiqLoadingMessage(
      `Claiming ${Number(pendingRewards)}  Sienna  rewards.`

    );
    setLiqLoading(true);
    try {
      // await client?.execute(
      //   stakedSlsescrtToken.at,
      //                 {
      //    rewards: {
      //       claim :{}
      //     }
      //     },
      //   "",
      //   [],
      //   { amount: [], gas: "1000000" }
      // );
      toast.success(
        "Rewards Claimed",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      refreshBalances();
      getSiennaUserBalances();
      window.location.reload();
    } catch (error) {
      let message = "Something went wrong!";
      if (error instanceof Error) {
        message = error.message;
      }
      console.log(error);
      toast.error(message);
    } finally {
      setLiqLoading(false);

    }
  };

  const Widthraw = async () => {
    setLiqLoadingMessage(

      `Widthrawing  ${sescrtInputAmount} sescrt.`
    );



    setLiqLoading(true);
    try {
      //     await client?.execute(
      //       slseScrtToken.at,
      //                     {
      //                        redeem_underlying: {
      //   receive_amount: (parseFloat(sescrtInputAmount) * 1000000).toFixed(0)
      // }
      //         },
      //       "",
      //       [],
      //       { amount: [], gas: "1000000" }
      //     );
      toast.success(
        "Succesfully Widthrawn",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      refreshBalances();
      getSiennaUserBalances();
      window.location.reload();
    } catch (error) {
      let message = "Something went wrong!";
      if (error instanceof Error) {
        message = error.message;
      }
      console.log(error);
      toast.error(message);
    } finally {
      setLiqLoading(false);
    }
  };

  const DeactivateRewards = async () => {
    setLiqLoadingMessage(
      `Deactivating ${Number(lendAmount) * Number(stakedslsescrtbalance)} seSCRT amount of rewards.`

    );



    setLiqLoading(true);
    try {
      // await client?.execute(
      //   stakedSlsescrtToken.at,
      //   {
      //     rewards: {
      //       withdraw: {
      //         amount: (parseFloat(stakedslsescrtbalance) * 1000000).toFixed(0)
      //       }
      //     }
      //   },
      //   "",
      //   [],
      //   { amount: [], gas: "1000000" }
      // );
      toast.success(
        "Succesfully Deactivated  Rewards",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      refreshBalances();
      getSiennaUserBalances();
      window.location.reload();
    } catch (error) {
      let message = "Something went wrong!";
      if (error instanceof Error) {
        message = error.message;
      }
      console.log(error);
      toast.error(message);
    } finally {
      setLiqLoading(false);

    }
  };




  return (
    <>
      <LendingInterface
        isOpen={props.isOpen}
        liqLoading={liqLoading}
        mode={liqMode}
        tokenInputAmount={sscrtInputAmount}
        secTokenInputAmount={sescrtInputAmount}
        tokenBalance={sscrtBalance}
        secTokenBalance={slsescrtBalance}
        deposit={Deposit}
        claim={ClaimRewards}
        pendingrewards={pendingRewards}
        activateRewards={ActivateRewards}
        sescrtBalance={sescrtBalance}
        stakedslsescrtbal={Number(lendAmount) * Number(stakedslsescrtbalance)}
        depositedslsescrtbal={Number(lendAmount) * Number(slsescrtBalance)}
        widthraw={Widthraw}
        totalDeposit={totalAmountLended}
        liqLoadingMessage={liqLoadingMessage}
        deactivateRewards={DeactivateRewards}
        close={props.close}

        setMode={(m: string) => setLiqMode(m)}
      >
        <DefiInput
          mode={liqMode}
          // showError={Number(sescrtInputAmount) > Number(sescrtBalance)}
          name="sescrt"
          onInputChange={handleInputChange}
          value={sescrtInputAmount}
          tokenName="seSCRT"
          viewsescrtBalance={createViewingKey}
          section="lower"
          pendingrewards={pendingRewards}
          stakedslsescrtbal={stakedslsescrtbalance}
          depositedslsescrtbal={slsescrtBalance}
          sescrtBalance={sescrtBalance}
          lendFactor={lendAmount}
          logoSrc={sescrtLogo}
          getBalance={createAllViewingKeys}

        />

      </LendingInterface>
    </>
  );
};

export default SiennaLending;
