import React, { useContext, useState } from "react";
import DexElement from "../DexElement";
import secretSwap from "../../../../assets/img/sswap-logo-full.png";
import sscrtLogo from "../../../../assets/img/scrt.png";
import seScrtLogo from "../../../../assets/img/secrt.png";
import SiennaLiquidity from "../../liquidity-interface/liquidities/SiennaLiquidity";
import SiennaSwap from "../../swap-interface/swaps/SiennaSwap";
import { SiennaSwapContext } from "../../../../context/swap-context/sienna-swap-context";
import { SecretSwapContext } from "../../../../context/swap-context/secret-swap-context";
import SecSwapLiquidity from "../../liquidity-interface/liquidities/SecSwapLiquidity";
import SecretSwap from "../../swap-interface/swaps/SecretSwap";

const SecSwapPool = () => {
    
  const { secretTvl,secretUserTotalDeposit,getSecretUserBalances, sectionControler,siennaPoolOpen,siennaLendOpen,seclpTokenSupplyRate,stakedseclpTokenSupplyRate,ViewScrtTotalDeposit } = useContext(SecretSwapContext);
  const {siennaPriceUsd} = useContext(SiennaSwapContext);
  const [isScrtLiqOpen, setIsScrtLiqOpen] = useState(false);
  const [isScrtSwapOpen, setIsScrtSwapOpen] = useState(false);

  const handleButtonClicks = (e: any) => {
    if (e.target.id === "swap-secret") {
      setIsScrtLiqOpen(false);
      setIsScrtSwapOpen(true);
    } else {
      setIsScrtLiqOpen(true);
      setIsScrtSwapOpen(false);
    }
    sectionControler("scrt");
    // controlSiennaPool(); 
  };
  return (
    <DexElement
      token1Logo={sscrtLogo}
      token2Logo={seScrtLogo}
      token1Name="sSCRT"
      token2Name="seSCRT"
      poolTvl={secretTvl}
      providerLogo={secretSwap}
      handleButtonClicks={handleButtonClicks}
      providerName={"secret"}
      providerlink={" https://app.secretswap.net/pool#Provide"}
      isLiqOpen={siennaPoolOpen===1 || siennaLendOpen===1?false:isScrtLiqOpen}
      isSwapOpen={siennaPoolOpen===1 || siennaLendOpen===1?false:isScrtSwapOpen}
      buttonName1={"Pool"}
      buttonName2={"Swap"}
      totalDeposit={secretUserTotalDeposit}
      getBalance={ViewScrtTotalDeposit}
    >
      <SecSwapLiquidity isOpen={ siennaPoolOpen===1 || siennaLendOpen===1?false:isScrtLiqOpen} close={() => setIsScrtLiqOpen(false)} />
      <SecretSwap isOpen={siennaPoolOpen===1 ||siennaLendOpen===1?false:isScrtSwapOpen} close={() => setIsScrtSwapOpen(false)} />
    </DexElement>
  );
};

export default SecSwapPool;
