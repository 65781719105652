import {  createTheme } from '@mui/material'
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import React, { useContext } from 'react'
import { UserContext } from '../../context/user-context';
import './WalkthroughBubble.css'

const WalkthroughBubble = (props:any) => {

    const {isWalkingThrough,setIsWalkingThrough, setCurrentWTPage, currentWTPage} = useContext(UserContext);

    
    
    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 220,
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid #dadde9',
        },
      }));

  return (
    //   <div className='wt-bubble-wrapper'>
    //       <div className='wt-bubble'>
    //           This is where you can see your address and all
    //       </div>
    //   </div>
    <Tooltip  open={isWalkingThrough && props.isOpen} arrow title={
        <React.Fragment >
        <div className='tooltip-container'>
            <div className='tooltip-content'>
                {props.content}
            </div>
            <div className='tooltip-buttons-wrapper'>
            <button onClick={()=>setCurrentWTPage(currentWTPage+1)} className="next-tooltip-btn stake-btn">Got it</button>
            <button onClick={()=>setIsWalkingThrough(false) } className='skip-tooltip-btn stake-btn secondary-btn'>Skip tour</button>
            </div>
            
        </div>

        </React.Fragment>
    } placement={props.placement} >

        {props.children}
        {/* </div> */}
    </Tooltip>
  )
}

export default WalkthroughBubble