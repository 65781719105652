import {createContext} from "react";

export const SecretSwapContext = createContext({
    secretTvl:'',
    secretPriceUsd:0,
    secretPoolRatio:1,
    secretAmount0:<string>'',
    secretAmount1:<string>'',
    getSecretSwapAmount1:(amount:string)=>{},
    getSecretSwapAmount0:(amount:string)=>{},
    createSecretLpViewingKey:()=>{},
    sectionControler:(name:string)=>{},
    secretUserTotalDeposit:'',   
    secretLpTokenViewingKey:'', 
    seclpTokenSupplyRate:'',
    stakedseclpTokenSupplyRate:'',
    siennaPoolOpen:0,
    siennaLendOpen:0,
    scrtPoolOpen:0,
    secretLpBalance:'', 
    fetchSecretLpBalance:()=>{}, 
    secretUserSscrtLiq:'', 
    secretUserSescrtLiq:'',
    ViewScrtTotalDeposit:()=>{},
   getSecretUserBalances:()=>{}
})