export const secSwapLpToken = {
    id:'secSwapLpToken',
    at:'secret19hsclhmgf3pr9luaey50uh00v4r5wu6f6075vf',
    hash: 'EA3DF9D5E17246E4EF2F2E8071C91299852A07A84C4EB85007476338B7547CE8'
}
// {
//     "asset_infos": [
//         {
//             "token": {
//                 "contract_addr": "secret1k0jntykt7e4g3y88ltc60czgjuqdy4c9e8fzek",
//                 "token_code_hash": "af74387e276be8874f07bec3a87023ee49b0e7ebe08178c49d0a49c3c98ed60e",
//                 "viewing_key": "SecretSwap"
//             }
//         },
//         {
//             "token": {
//                 "contract_addr": "secret16zfat8th6hvzhesj8f6rz3vzd7ll69ys580p2t",
//                 "token_code_hash": "91809b72cc6a7b4a62170698630b0b0848334f0403dba1aba7aec94396af7f95",
//                 "viewing_key": "SecretSwap"
//             }
//         }
//     ],
//     "contract_addr": "secret1a7phxread4rjnyzw0hs53cflwha0y2h8v8klyt",
//     "liquidity_token": "secret19hsclhmgf3pr9luaey50uh00v4r5wu6f6075vf",
//     "token_code_hash": "EA3DF9D5E17246E4EF2F2E8071C91299852A07A84C4EB85007476338B7547CE8",
//     "asset0_volume": "1330386081",
//     "asset1_volume": "1412958786",
//     "factory": {
//         "address": "secret1fjqlk09wp7yflxx7y433mkeskqdtw3yqerkcgp",
//         "code_hash": "16ea6dca596d2e5e6eef41df6dc26a1368adaa238aa93f07959841e7968c51bd"
//     }
// }