import React, { useContext, useState,useEffect } from "react";
import { FaTimesCircle } from "react-icons/fa";
import "./Liquidity.css";
import Timer from "../../../utils/timer/Timer";
import LoadingModal from "../../../components/LoadingModal/LoadingModal";
import { UserContext } from "../../../context/user-context";
import { SiennaSwapContext } from "../../../context/swap-context/sienna-swap-context";
const LiquidityInterface = (props: any) => {
  const [liqMode, setLiqMode] = useState("add");
  const [progress, setProgress] = useState<any>(0);
  const [timeLeft, setTimeLeft] = useState<Date>(new Date());
  const [timerExpired, setTimerExpired] = useState(false);
  const time = new Date();
  
  time.setSeconds(time.getSeconds() + 120);
  const { info, rate, activeWindow, isLoading, fetchWindow, isWindowFetching, } =
    useContext(UserContext);
    const { swapTimer } =
    useContext(SiennaSwapContext);

  useEffect(() => {
    let sec = swapTimer;
    console.log(sec);

    if (sec < 0) {
      sec = 0;
    }
    if(sec>0){
      setTimerExpired(false);
    }
    const time = new Date();
    time.setSeconds(time.getSeconds() + sec);
    setTimeLeft(time);
    setProgress(sec);
    console.log(timeLeft, progress);
  }, [swapTimer, progress]);

  const handleTimerExpire = () => {
    
    console.log(" liquid timer expired");
    setTimerExpired(true);
    setProgress(0);
    // let reloadTime = 15000;
    // const expTimer = () => {
    //   if (oldWindowID !== activeWindow.id) {
    //     fetchWindow();
    //   }
    // };
    // setInterval(() => {
    //   expTimer();
    //   reloadTime = reloadTime * 1.1;
    // }, reloadTime);
  };
    

  return (
    <div
      className={`liquidity-outer-wrapper ${props.isOpen ? "active-tab" : ""}`}
    >
      <div
        style={{
          width: "90%",
          margin: "10px auto",
        }}
        className="stake-menu-btn-wrapper"
      >
        <div
          onClick={() => {
            setLiqMode("add");
            props.setMode("add");
          }}
          className={
           liqMode === "add"
              ? `stake-menu-btn active-stake-btn`
              : `stake-menu-btn`
          }
        >
          DEPOSIT
        </div>
        <div
          onClick={() => {
            setLiqMode("withdraw");
            props.setMode("withdraw");
          }}
          className={
            liqMode=== "withdraw"
             ? `stake-menu-btn active-stake-btn`
              : `stake-menu-btn`
          }
        >
          WITHDRAW
        </div>
         {props.provider==="sienna" && <div
          onClick={() => {
            setLiqMode("claim");
            props.setMode("claim");
          }}
          className={
           liqMode === "claim"
             ? `stake-menu-btn active-stake-btn`
              : `stake-menu-btn`
          }
        >
         CLAIM
        </div>}
      </div>
      <div className="liquidity-inputs-wrapper">{props.children}</div>
      <div className="liquidity-footer">
       
        
        <div className="liquidity-buttons-wrapper">
          {liqMode==="claim" && <div className="liq-timer">
            <Timer
                            page="defi"
                            expiryTimestamp={timeLeft}
                            totalTime={86400}
                            wrapperClassName="progress-bar-wrapper"
                            barClassName="progress-bar"
                            onExpire={handleTimerExpire}
                            inlineTag={true}
              />
        </div>}
          <button
  
            disabled={
              !(liqMode==="claim")?(props.liqLoading ||
              !props.tokenInputAmount ||
              !Number(props.tokenInputAmount) ||
              Number(props.tokenInputAmount) > Number(props.tokenBalance) ||
              Number(props.secTokenInputAmount) > Number(props.secTokenBalance)):(
              props.liqLoading ||
                (Number(props.pendingrewards)===0) || !(timerExpired)


              )
              
              
            }
            onClick={
              liqMode === "add" ? props.addLiquidity :(liqMode==="claim"?props.claim:props.withdrawLiquidity)
              
               
            }
            className="stake-btn liquidity-btn"
          >
            {liqMode === "add" ? "Add Liquidity" : (liqMode==="claim"?"Claim Rewards": "Remove Liquidity")}
          </button>
          {props.provider==="sienna" && !(props.liqmode==="claim") &&<button
            
            disabled={

              liqMode==="add"?(props.liqLoading ||
                (Number(props.nonstakedlp)===0)):(
                props.liqLoading ||
                (Number(props.stakedlp)===0)

              )
    
            }
            onClick={
              liqMode === "add" ? props.activaterewards : props.deactivaterewards
            }
            className="stake-btn liquidity-btn"
          >
            {liqMode === "add" ? "Activate Rewards" : "Deactivate Rewards"}
          </button>}
        </div>
        <span
          onClick={() => props.close()}
        >
          <FaTimesCircle cursor="pointer" size= '34' className="close-icon"/>
        </span>
      </div>
      <LoadingModal open={props.liqLoading}>
        {props.liqLoadingMessage}
      </LoadingModal>
    </div>
  );
};

export default LiquidityInterface;
