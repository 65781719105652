import {createContext} from "react";

export const SiennaSwapContext = createContext({
    siennaAmount0:<any>'',
    siennaAmount1:<any>'',
    getSiennaSwapAmount1:(amount:string)=>{},
    getSiennaSwapAmount0:(amount:string)=>{},
    lendAmount:'',
    siennaTvl:'',
    liqRatio:0,
    siennaRewardsTvl:'',
    lpTokenSupplyRate:'',
    stakedlpTokenSupplyRate:'',
    siennaLendingTvl:'',
    lpPendingRewards:'',
    secretPriceUsd:0,
    siennaPriceUsd:0,
    sesecretPriceUsd:0,
    siennaPoolRatio:1,
    scrtRewardsLiq:'',
    sescrtRewardsLiq:'',
    swapTimer:0,
    siennaUserTotalDeposit:'',
    createLpViewingKey:()=>{},
    siennaLpTokenViewingKey:'',
    siennaLpBalance:'',
    siennaStakedLpBalance:'',
    fetchLpBalance:()=>{},
    getLendRate:()=>{},
    siennaUserSscrtLiq:'', 
    siennaUserSescrtLiq:'',
    getSiennaUserBalances:()=>{},
    ViewTotalDeposit:()=>{},
})