import { useContext, useEffect, useState } from "react";
import LoadingSpinner from "../../components/loading_spinner/LoadingSpinner";
import { BalanceToggleContext } from "../../context/balanceToggleContext";
import { UserContext } from "../../context/user-context";
import { stakingContract } from "../../contracts/staking_contract";
import { VALIDATOR_MAP } from "../../utils/constants";
import Timer from "../../utils/timer/Timer";
import "./index.css";

function ContractInfo() {
	const [progress, setProgress] = useState<any>(0);
	const [timeLeft, setTimeLeft] = useState<Date>(new Date());
	const time = new Date();
	time.setSeconds(time.getSeconds() + 120);

	const { info, rate, activeWindow, isLoading, fetchWindow, isWindowFetching } =
		useContext(UserContext);

	const { balanceButtonToggle } = useContext(BalanceToggleContext);
	let toggleClass = "";

	if (
		(window.innerWidth ||
			document.documentElement.clientWidth ||
			document.body.clientWidth <= 768) &&
		balanceButtonToggle
	) {
		toggleClass = " wrapperToggle";
	} else {
		toggleClass = " ";
	}

	useEffect(() => {
		let sec = activeWindow.time_to_close - Math.floor(Date.now() / 1000);
		console.log(sec);

		if (sec < 0) {
			sec = 0;
		}
		const time = new Date();
		time.setSeconds(time.getSeconds() + sec);
		setTimeLeft(time);
		setProgress(sec);
		console.log(timeLeft, progress);
	}, [activeWindow.time_to_close, progress]);

	const handleTimerExpire = () => {
		const oldWindowID = activeWindow.id;
		console.log("expired");
		setProgress(0);
		let reloadTime = 15000;
		const expTimer = () => {
			if (oldWindowID !== activeWindow.id) {
				fetchWindow();
			}
		};
		setInterval(() => {
			expTimer();
			reloadTime = reloadTime * 1.1;
		}, reloadTime);
	};

	function shortenAddress(address: string) {
		return address.substr(0, 8) + "..." + address.substr(address.length - 3, 3);
	}

	return (
		<>
			<div className={`wrapper ${toggleClass}`}>
				<div className='contract-info-wrapper'>
					<h2 className='info-heading'>Contract info</h2>
					<div className='info-card card'>
						<div className='info-card-content'>
							<div>Total SCRT staked</div>
							{isLoading ? (
								<LoadingSpinner />
							) : (
								<div className='amount-with-unit'>
									<span>
										{(
											parseFloat(info.total_staked) / 1000000
										).toLocaleString() ?? `-`}
									</span>
									<span> SCRT</span>
								</div>
							)}
						</div>

						<div className='info-card-content'>
							<div>Current SCRT under withdraw</div>
							{isLoading ? (
								<LoadingSpinner />
							) : (
								<div className='amount-with-unit'>
									<span>
										{(
											parseFloat(info.scrt_under_withdraw) / 1000000 +
											(parseFloat(activeWindow.sescrt_amount) / 1000000) * rate
										).toLocaleString() ?? `-`}
									</span>
									<span> SCRT</span>
								</div>
							)}
						</div>
						{/* <div className="info-card-content">
              <div>Current seSCRT unbonding</div>
              {isLoading?<LoadingSpinner/>:<div>{(parseFloat(info.sescrt_in_contract)/1000000).toLocaleString() ?? `-`} seSCRT</div>}
            </div> */}
						<div className='info-card-content'>
							<div>Contract manager</div>
							{isLoading ? (
								<LoadingSpinner />
							) : (
								<div>
									<a
										className='val-address-link'
										target='_blank'
										href={`https://secretnodes.com/secret/chains/secret-4/accounts/${info?.admin}`}
									>
										{info.admin === null || info.admin === undefined
											? `-`
											: shortenAddress(info.admin)}
									</a>
								</div>
							)}
						</div>
						<div className='info-card-content'>
							<div>seSCRT token address</div>
							{isLoading ? (
								<LoadingSpinner />
							) : (
								<a
									className='val-address-link'
									target='_blank'
									href={`https://secretnodes.com/secret/chains/secret-4/accounts/${info?.token_address}`}
								>
									{info.token_address === null ||
									info.token_address === undefined
										? `-`
										: shortenAddress(info.token_address)}
								</a>
							)}
						</div>
						<div className='info-card-content'>
							<div>seSCRT staking contract address</div>
							{isLoading ? (
								<LoadingSpinner />
							) : (
								<a
									className='val-address-link'
									target='_blank'
									href={`https://secretnodes.com/secret/chains/secret-4/accounts/${stakingContract.at}`}
								>
									{shortenAddress(stakingContract.at)}
								</a>
							)}
						</div>
					</div>

					<h2 className='info-heading'>Validators</h2>
					<div className='info-card card validators-card'>
						<div className='validators-heading'>
							<div>Validator name/address</div>
							<div>Amount</div>
						</div>
						<div className='validators-list'>
							{isLoading ? (
								<LoadingSpinner style={{ margin: "10px auto" }} />
							) : (
								info.validators?.map((item: any, index: any) => {
									const validator: any = VALIDATOR_MAP.find(
										(v) => v.address === item.address
									);
									return (
										<div className='info-card-content jcs'>
											<div className='list-numberings'> {`(${index})`} </div>
											<div>
												<a
													className='val-address-link'
													target='_blank'
													href={`https://secretnodes.com/secret/chains/secret-4/validators/${item?.address}`}
												>
													{validator ? validator.name : validator.address}
												</a>
											</div>
											<div className='jcs-child__value amount-with-unit'>
												<span>
													{(parseFloat(item.staked) / 1000000).toLocaleString()}
												</span>
												<span> SCRT</span>
											</div>
										</div>
									);
								})
							)}
						</div>
					</div>

					<h2 className='info-heading'>Active Window</h2>
					<div className='info-card card'>
						<div className='info-card-content'>
							<div>Window ID</div>
							{isLoading ? (
								<LoadingSpinner />
							) : (
								<div>{activeWindow.id ?? `-`}</div>
							)}
						</div>

						<div className='info-card-content'>
							<div>Time to trigger window</div>
							{!progress || isLoading ? (
								`${isWindowFetching ? "Fetching window..." : "-"}`
							) : (
								<Timer
									page='stake'
									expiryTimestamp={timeLeft}
									totalTime={259200}
									wrapperClassName='progress-bar-wrapper'
									barClassName='progress-bar'
									onExpire={handleTimerExpire}
									inlineTag={true}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ContractInfo;
