import { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import scrt from "../../assets/img/scrt.png";
import secrt from "../../assets/img/secrt.png";
import { walletState } from "../../utils/walletState";
import { UserContext } from "../../context/user-context";
import InfoBubble from "../../utils/information/InfoBubble";

function Balance() {
  const {
    sscrtBalance,
    sescrtBalance,
    viewingKey,
    createViewingKey,
    createSscrtViewingKey,
    sscrtViewingKey,
    isMessageLoading,
    claimAmount,
    totalPending,
    secretUnit,
    activeWindowAmount,
    rate,
  } = useContext(UserContext);
  const { address, balance } = useRecoilValue(walletState);
  const [sescrtLoading, setSescrtLoading] = useState(false);
  const [sscrtLoading, setSscrtLoading] = useState(false);

  useEffect(() => {
    console.log(viewingKey);
    console.log(sscrtViewingKey);
  }, [viewingKey, sscrtViewingKey]);

  const handleSescrtClick = () => {
    setSescrtLoading(true);
    createViewingKey();
  };
  const handleSscrtClick = () => {
    setSscrtLoading(true);
    createSscrtViewingKey();
  };

  return (
    <div className="balance-container card">
      <div className="balance-heading">
        Balances
        <InfoBubble content="Wallet Balances" />
      </div>
      <div>
        <img src={secrt} alt="secrt" />
        <img src={scrt} alt="scrt" />
      </div>
      <div className="balance-wrapper">
        <div className="logo-balance">
          <div className="stk-balance-wrapper dull">
            <div className="stk-balance">
              {sescrtBalance ? (
                sescrtBalance
              ) : (
                <InfoBubble
                  style={{ right: "0px", top: "-10px" }}
                  content={
                    !address
                      ? "Connect Keplr wallet to see seSCRT balance"
                      : viewingKey === undefined
                      ? "Create viewing key to see seSCRT balance"
                      : "-"
                  }
                />
              )}
            </div>
            <div className="token-name">seSCRT</div>
          </div>
        </div>
        <div className="logo-balance">
          <div className="stk-balance-wrapper dull">
            <div className="stk-balance">
              {sscrtBalance ? (
                sscrtBalance
              ) : (
                <InfoBubble
                  style={{ right: "0px", top: "-10px" }}
                  content={
                    address && viewingKey === undefined
                      ? "Create sSCRT viewing key to see sSCRT balance"
                      : "Connect Keplr wallet to see sSCRT balance"
                  }
                />
              )}
            </div>
            <div className="token-name">sSCRT</div>
          </div>
        </div>
        <div className="logo-balance">
          <div className="stk-balance-wrapper dull">
            <div className="stk-balance">
              {balance ? (
                balance
              ) : (
                <InfoBubble
                  style={{ right: "0px", top: "-10px" }}
                  content="Connect Keplr wallet to see SCRT balance"
                />
              )}
            </div>
            <div className="token-name">SCRT</div>
          </div>
        </div>
        <div className="logo-balance logo-balance__ready">
          <div className="stk-balance-wrapper dull">
            <div className="stk-balance">
              {totalPending !== undefined ? (
                (
                  totalPending +
                  parseFloat(activeWindowAmount) * parseFloat(rate)
                ).toFixed(4)
              ) : !address ? (
                <InfoBubble
                  style={{ right: "0px", top: "-10px" }}
                  content="Connect Keplr wallet to see sSCRT balance"
                />
              ) : (
                "-"
              )}
            </div>
            <div className="token-name">Unstaking SCRT</div>
          </div>
        </div>
        <div className="logo-balance logo-balance__ready">
          <div className="stk-balance-wrapper dull">
            <div className="stk-balance">
              {claimAmount ??
                (!address ? (
                  <InfoBubble
                    style={{ right: "0px", top: "-10px" }}
                    content="Connect Keplr wallet to see sSCRT balance"
                  />
                ) : (
                  "-"
                ))}
            </div>
            <div className="token-name">Claimable SCRT</div>
          </div>
        </div>
      </div>
      <div className="viewing-key-button-wrap">
        <button
          className={
            viewingKey || !address
              ? `viewing-key-button hide-vk-button`
              : `viewing-key-button`
          }
          onClick={handleSescrtClick}
          disabled={sescrtLoading && isMessageLoading}
        >
          {sescrtLoading && isMessageLoading
            ? "Loading..."
            : "Create seSCRT Viewing Key"}
        </button>
        <button
          className={
            sscrtViewingKey || !address
              ? `viewing-key-button hide-vk-button`
              : `viewing-key-button`
          }
          onClick={handleSscrtClick}
          disabled={sscrtLoading && isMessageLoading}
        >
          {sscrtLoading && isMessageLoading
            ? "Loading..."
            : "Create sSCRT Viewing Key"}
        </button>
      </div>
      <ToastContainer style={{ textAlign: "left" }} />
    </div>
  );
}

export default Balance;
