import React, { useContext, useState } from 'react'
import { UserContext } from '../../context/user-context';
import { walletState } from "../../utils/walletState";
import { useRecoilValue } from "recoil";

const ViewingKeyComp = ({keyName}:any) => {

    const { createViewingKey,
       isMessageLoading,createSscrtViewingKey} = useContext(UserContext);
      const { client,address } = useRecoilValue(walletState);
      const [sescrtLoading, setSescrtLoading] = useState(false);
      const [sscrtLoading, setSscrtLoading] = useState(false);

    const handleSescrtClick = ()=>{
        setSescrtLoading(true);
        createViewingKey();
      }

      const handleSscrtClick = ()=>{
        setSscrtLoading(true);
        createSscrtViewingKey();
      }
  return (
    <div style={{
      'display':'flex',
      'justifyContent':'space-between',
      'alignItems':'center'
    }}>
        <div  >No viewing key found for {keyName}.</div><br></br>
            <button  
        className={(!address) ? `viewing-key-button hide-vk-button`:`viewing-key-button`}
        onClick={keyName==='sescrt'?handleSescrtClick:handleSscrtClick} 
        disabled={keyName==='sescrt'?sescrtLoading&&isMessageLoading:sscrtLoading&&isMessageLoading}
      >
        {keyName==='sescrt'?sescrtLoading&&isMessageLoading?"Loading...":"Create seSCRT Viewing Key":
          sscrtLoading&&isMessageLoading?"Loading...":"Create sSCRT Viewing Key"
        }
      </button>
    
    </div>
  )
}

export default ViewingKeyComp;