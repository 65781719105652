import React,{useEffect,useContext, useState} from 'react'
import Modal from "react-modal"

import { useRecoilValue } from "recoil";
import { walletState } from "../../utils/walletState";
import { UserContext } from '../../context/user-context'
import './DisclaimerModal.css'

const DisclaimerModal = (props:any) => {
    const {isMobileUser} = useContext(UserContext);
    const [isAccepted, setIsAccepted] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { client,address } = useRecoilValue(walletState);

    useEffect(()=>{
        const status = localStorage.getItem(`disclaimer_status`);
        if(!status){
            setIsOpen(true);
        }
        if(isMobileUser){
             setIsOpen(true);
        }
    },[])

    const handleInput = (event:any) =>{
        if(isAccepted){
            setIsAccepted(false);
        }else{
            setIsAccepted(true);
        }
        
        console.log(!isAccepted);   
    }

    const handleAccept = ()=>{
        localStorage.setItem(`disclaimer_status`,'accepted');
        setIsOpen(false);
    }

  return (
    <Modal isOpen={isOpen}
    contentLabel="My dialog"
    className="loading-modal"
    overlayClassName="myoverlay"
    closeTimeoutMS={300}>
      <div className='disclaimer-modal-wrapper'  >
          <div className='disclaimer-modal-heading'>
            {isMobileUser?<h2>Mobile Not Supported</h2>:<h2>Disclaimer</h2>}
              
          </div>
      <div className="disclaimer-modal-content-wrapper"  >
            {isMobileUser?<h3 className='headline'>StakeEasy is not Available on Mobile right now please access the site using Dekstop</h3>:<h3>Please read the following terms carefully</h3>}
            {!(isMobileUser) && <p>
                <ul>
                    <li>Stake Easy Protocol is a decentralized liquid staking protocol with privacy.</li>
                    <li>If you encounter bugs, please report in the 'bug-report' channel on our Discord.</li>
                    <li>This app includes financial risk, use at your own risk.</li>
                    <li>Proceed only if you understand the do hows.</li>
                </ul>
            </p>}
        </div>
        {!(isMobileUser) && 
        <div className='disclaimer-modal-inputs'>
            <div>
            <input onChange={handleInput} name='checkbox' type={'checkbox'}/>
            <label htmlFor='checkbox'>I understand the risk and agree to proceed.</label>
            </div>
            <button onClick={handleAccept} disabled={!isAccepted} className='stake-btn'>Proceed</button>
        </div> }
        
      
    </div>
       
    </Modal>
  )
}

export default DisclaimerModal