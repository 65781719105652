import "./App.css";
import { useContext } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Header from "./components/header/header";
import Account from "./pages/account/account";
import Background from "./components/background/background";
import Stake from "./pages/stake/stake";
import ContractInfo from "./pages/contract_info/contract_info";

import { UserContext } from "./context/user-context";
import { SiennaSwapContext } from "./context/swap-context/sienna-swap-context";
import { useData } from "./hooks/useData";
import { useSiennaSwap } from "./hooks/swap/useSiennaSwap";
import "react-toastify/dist/ReactToastify.css";
import Socials from "./components/socials/socials";
import FaqBubble from "./components/faqBubble/FaqBubble";
import Statistics from "./pages/statistics/statistics";
import DisclaimerModal from "./components/DisclaimerModal/DisclaimerModal";
import CommunityFarming from "./pages/community-farming/CommunityFarming";
import RightPane from "./components/right-pane/RightPane";
import Defi from "./pages/defi/Defi";
import { useSecretSwap } from "./hooks/swap/useSecretSwap";
import { SecretSwapContext } from "./context/swap-context/secret-swap-context";
import { BalanceToggleContextProvider } from "./context/balanceToggleContext";

function App() {
	const siennaData: any = useSiennaSwap();
	const secretData: any = useSecretSwap();
	const data = useData();
	if (
		window.matchMedia &&
		window.matchMedia("(prefers-color-scheme: light)").matches
	) {
		document.getElementById("light-icon")?.remove();
	} else {
		document.getElementById("dark-icon")?.remove();
	}
	return (
		<div className='App'>
			<BalanceToggleContextProvider>
				<UserContext.Provider value={data}>
					<SiennaSwapContext.Provider value={siennaData}>
						<SecretSwapContext.Provider value={secretData}>
							<BrowserRouter>
								<DisclaimerModal />
								<Header />
								<Switch>
									<Route path='/info'>
										{" "}
										<ContractInfo />{" "}
									</Route>
									<Route path='/account'>
										{" "}
										<Account />{" "}
									</Route>
									<Route path='/community-farming'>
										{" "}
										<CommunityFarming />{" "}
									</Route>
									<Route path='/statistics'>
										{" "}
										<Statistics />{" "}
									</Route>
									<Route path='/defi'>
										<Defi />
									</Route>
									<Route path='/'>
										{" "}
										<Stake />{" "}
									</Route>
								</Switch>
								<RightPane />
								{/* <FaqBubble/>
        <Socials/> */}
							</BrowserRouter>
						</SecretSwapContext.Provider>
					</SiennaSwapContext.Provider>
				</UserContext.Provider>
			</BalanceToggleContextProvider>
		</div>
	);
}

export default App;
