import React, { useContext, useEffect, useState } from "react";
import { communityFarming } from "../../contracts/community_farming";
import Lockdrop from "./components/Lockdrop";
import MyLockdrop from "./components/MyLockdrop";
import { useRecoilValue } from "recoil";
import { walletState } from "../../utils/walletState";
import { API_URL } from "../../utils/constants";
import { SecretNetworkClient } from "secretjs";
import { UserContext } from "../../context/user-context";

const CURRENT_TIME = parseInt((Date.now() / 1000).toString());

const CommunityFarming = () => {
	//const queryClient = new CosmWasmClient(API_URL);
	const { address, client, balance } = useRecoilValue(walletState);

	const {
		setcommFarmPhaseNumber,
		farmingInfo,
		phaseNumber,
		phaseEndsIn,
		myDeposits,
		myRewards,
		getMyDeposits,
		getMyRewards,
		fetchFarmingInfo,
	} = useContext(UserContext);

	console.log("My Deposits: ", myDeposits);

	const handleTransactionSuccess = () => {
		getMyRewards();
		getMyDeposits();
		fetchFarmingInfo();
	};

	return (
		<div className='wrapper '>
			<Lockdrop
				onTransaction={handleTransactionSuccess}
				info={farmingInfo}
				phaseNumber={phaseNumber}
				phaseEndsIn={phaseEndsIn}
				myRewards={myRewards}
				myDeposits={myDeposits}
			/>
			{/* <MyLockdrop/> */}
		</div>
	);
};

export default CommunityFarming;
